@font-face {
  font-family: 'Causten';
  src: local('Causten'), url(../../../fonts/Causten/Causten-Regular.otf) format('opentype');
}

.react-calendar {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    background: white;
    border: 1px solid #5090F0;
    font-family: 'Causten';
    color: #5090F0;
    line-height: 0vw;
    border-radius: 5px;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
  .react-calendar--doubleView {
    width: 500px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
  }
  .react-calendar__navigation {
    display: flex;
    height: 15%;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 15%;
    background: none;
    color: #5090F0;
  }
  .react-calendar__navigation button:disabled {
    background-color: #ffffff;
    color: #5090F0;
  }
  .react-calendar__navigation button:enabled:hover {
    background-color: #A7C7F8;
    color: #5090F0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size:calc(5px + 0.4vw);
    color: #5090F0;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
  }
  .react-calendar__month-view__days__day--weekday {
    color: #5090F0;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #5090F0;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #5090F0;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    color: #5090F0;
  }
  .react-calendar__tile:disabled {
    background-color: #ffffff;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #A7C7F8;
  }
  
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #5090F0;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #5090F0;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #A7C7F8;
  }
  