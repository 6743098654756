/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_sidebar_open {
        position: absolute;
        width: 100%;
        height: 100vh;
        display: grid;
        grid-template-columns: 15% 85%;
    }
    
    .professor_sidebar_closed {
        position: absolute;
        width: 100%;
        height: 100vh;
    }
    
    .classes_area_component{
        grid-column: 2;
        position:relative;
        height:100%;
        width:100%;
    }
    
    .classes_main_container {
        position: relative;
        background-color: white;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
        grid-template-areas: 
        "navbar_area"
        "classes_main"
        ;
    }
    
    .navbar_classes_container{
        position: relative;
        grid-area: "navbar_area";
        width: 90%;
        height: 100%;
        left: 5%;
        border-bottom: 2px solid #5090F0;
    }
    
    .navbar_title{
        position: relative;
        width: 100%;
        height: 90%;
        top: 30%;
        font-size: 1.5vw;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_sidebar_open {
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 18.26% 81.74%;
    }
    
    .professor_sidebar_closed {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    
    .classes_area_component{
        grid-column: 2;
        position:relative;
        height:100%;
        width:100%;
    }
    
    .classes_main_container {
        position: relative;
        background-color: white;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
        grid-template-areas: 
        "navbar_area"
        "classes_main"
        ;
    }
    
    .navbar_classes_container{
        position: relative;
        grid-area: "navbar_area";
        width: 90%;
        height: 100%;
        left: 5%;
        border-bottom: 2px solid #50f08d;
    }
    
    .navbar_title{
        position: relative;
        width: 100%;
        height: 90%;
        top: 30%;
        font-size: 1.5vw;
    }
}