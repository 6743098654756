.whatsapp_button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 10px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.whatsapp_icon {
    max-width: 45px;
}