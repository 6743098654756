/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_credentials_menu {
        position: relative;
        height: 94%;
        width: 94%;
        top: 3%;
        left: 3%;
        /* display: inline-block; */
        display: flex;
        gap: 0.5vw;
    }

    .professor_credentials_menu_student {
        position: relative;
        /* height: 10%; */
        height: 10vh;
        width: 100%;
        border-bottom: 1px solid #B8B8B8;
        display: grid;
        grid-template-columns: 10% 30% 25% 30% 5%;
    }
    .professor_credentials_menu_teacher {
        position: relative;
        height: 8vh;
        width: 95%;
        left: 2.5%;
        box-sizing: border-box;
        padding: 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;
        border-radius: 5px;
        gap: 0.5vw;
        border: 2px solid #EEEEEE;
        


    }

    .professor_credentials_menu_teacher_icon{
        position: relative;
        height: 4vh;
        width: 4vh;
        border-radius: 50%;
        background-color: #FBC412;
    }

    .professor_credentials_menu_student_icon {
        position: relative;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #E6C9FF;
    }

    .professor_credentials_menu_student_credentials {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 6fr);
    }

    .professor_credentials_menu_show_credentials {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 6fr;
    }

    .professor_credentials_menu_show_credentials_text {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .professor_credentials_menu_show_credentials_text_button {
        position: relative;
        border-radius: 5px;
        display: flex;
        box-sizing: border-box;
        width: 80%;
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .professor_credentials_menu_show_credentials_button {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 6fr);
    }


    .professor_credentials_menu_delete_menu_background {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 99;
    }

    .professor_credentials_menu_delete_menu_container {
        position: relative;
        height: 50%;
        width: 60%;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8DBCFF;
    }

    .professor_credentials_menu_delete_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

    }

    .professor_credentials_menu_delete_menu_text {
        position: relative;
        height: 70%;
        width: 100%;
        box-sizing: border-box;
        padding: 5%;
    }

    .professor_credentials_menu_delete_menu_close {
        position: absolute;
        width: fit-content;
        height: fit-content;
        top: 2%;
        right: 1%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_credentials_menu_delete_menu_buttons {
        position: absolute;
        width: 100%;
        height: 20%;
        bottom: 5%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .professor_credentials_menu_delete_menu_button_yes {
        position: relative;
        height: 100%;
        width: 70%;
        left: 20%;
        background-color: #6EE6A7;
        border-radius: 10px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

    }

    .professor_credentials_menu_delete_menu_button_no {
        position: relative;
        height: 100%;
        width: 70%;
        left: 10%;
        background-color: #F06D50;
        border-radius: 10px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

    }

    .professor_credentials_menu_teachers_container {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        overflow-y: scroll;
    }

    .professor_credentials_menu_teachers_top {
        position: relative;
        height: 100%;
        width: 100%;
        border-bottom: 1px solid #5090F0;
        display: grid;
        grid-template-columns: 70% 30%;
        /* background-color: red; */
    }

    .professor_credentials_menu_teachers_add_button {
        position: absolute;
        height: fit-content;
        width: fit-content;
        top: 16%;
        right: 4%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_credentials_menu_teachers_bottom {
        margin-top: 1vh;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1vh;
    }

    .professor_credentials_menu_teachers_closed {
        position: relative;
        top: 2.5%;
        left: 5%;
        height: 95%;
        width: 90%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        display: grid;
        grid-template-rows: 8% 92%;
    }

    .professor_credentials_menu_students_container {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        overflow-y: scroll;
    }

    .professor_credentials_menu_students_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .professor_credentials_menu_add_group_button {
        position: absolute;
        top: 15%;
        right: 0.5%;
        /* Adjust as needed */
        height: 70%;
        width: fit-content;
        box-sizing: border-box;
        padding: 1%;
        white-space: nowrap;
        border: 1px solid #5090F0;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

    .professor_credentials_menu_add_groups_background {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 99;
    }

    .professor_credentials_menu_add_groups_container {
        position: relative;
        height: 78%;
        width: 35%;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8DBCFF;
    }

    .professor_credentials_menu_add_groups_menu {
        position: relative;
        height: 100%;
        width: 100%;
        /* display: flex;
        flex-direction: column; */

        display: grid;
        grid-template-rows: 15% 15% 55% 15%;

    }

    .professor_credentials_menu_add_groups_menu_close {
        position: relative;
        width: fit-content;
        height: fit-content;
        /* top: 25%;
        right: 2%; */
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_credentials_menu_add_groups_top {
        position: relative;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding-right: 3%;
    }

    .professor_credentials_menu_add_groups_students {
        position: relative;
        width: 90%;
        height: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_credentials_menu_add_groups_students_checkbox {
        position: relative;
        width: 100%;
        height: 90%;
        margin-top: 5%;
        overflow-y: scroll;
    }

    .professor_credentials_menu_add_groups_students_checkbox_row {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: start;
        align-items: center;
        padding: 1vh 1vw;
    }

    .professor_credentials_menu_add_groups_confirm {
        position: relative;
        width: 50%;
        height: 70%;
        border-radius: 98px;
        background-color: #6EE6A7;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    /* .professor_credentials_menu_teachers_add_button {
        position: relative;
        height: 30px;
        width: 30px;
        background-color: #6EE6A7;
        border-radius: 50%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    } */
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_credentials_menu {
        position: relative;
        height: 94%;
        width: 94%;
        top: 3%;
        left: 3%;
        overflow-y: scroll;
        display: inline-block;
    }

    .professor_credentials_menu_student {
        position: relative;
        height: 10%;
        width: 100%;
        border-bottom: 1px solid #B8B8B8;
        display: grid;
        grid-template-columns: 5% 65% 30%;
    }

    .professor_credentials_menu_student_icon {
        position: relative;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #E6C9FF;
    }

    .professor_credentials_menu_student_credentials {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 6fr);
    }

    .professor_credentials_menu_delete_menu_background {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 99;
    }

    .professor_credentials_menu_delete_menu_container {
        position: relative;
        height: 50%;
        width: 60%;
        top: 25%;
        left: 20%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #8DBCFF;
    }

    .professor_credentials_menu_delete_menu {
        position: relative;
        height: 100%;
        width: 100%;

    }

}