/* Ordenador */

@media only screen and (min-width: 992px) {
    .edit_media {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .edit_media_professor {
        position: absolute;
        max-height: 70%;
        max-width: 25%;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    .edit_media_text_box {
        position: absolute;
        height: 8%;
        width: 60%;
        top: 15%;
        left: 23%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 1;
    }

    .edit_media_container {
        position: absolute;
        height: 60%;
        width: 70%;
        top: 28%;
        left: 18%;
        z-index: 1;
    }
    .edit_media_bg {
        position: relative;
        height: 100%;
        background-color: #d9d9d9;
        border-radius: 10px;
        aspect-ratio: 16 / 9;
    }
    .edit_media_arrows {
        position: absolute;
        height: 10%;
        width: 10%;
        bottom: 1.5%;
        left: 40%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .edit_media {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index:5;
    }

    .edit_media_professor {
        position: absolute;
        height: 75%;
        width: 65%;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    .edit_media_text_box {
        position: absolute;
        height: 60%;
        width: 60%;
        top: 4%;
        right: 3%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 1;
    }

    .edit_media_container {
        position: absolute;
        height: 60%;
        width: 60%;
        top: 20%;
        right: 3%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 1;
    }

    .edit_theory_arrows {
        position: absolute;
        height: 12%;
        width: 20%;
        bottom: 0;
        left: 50%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }
}