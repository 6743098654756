.professor_evaluation_general_info_weird {
    display: flex;
}

/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_evaluation_general_info {
        position: relative;
        height: 96%;
        width: 100%;
        margin-top: 2%;
        display: grid;
        /* grid-template-columns: 50% 50%; */
    }

    .professor_evaluation_general_info_left {
        position: relative;
        height: 100%;
        width: 98%;
    }

    .professor_evaluation_general_info_global_competences {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 10px 1px rgb(80, 144, 240, 0.5);
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_evaluation_general_info_global_competences_hover {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .professor_evaluation_general_info_title {
        position: relative;
        height: 8%;
        width: 98%;
        left: 2%;

    }

    .professor_evaluation_general_info_left_octagon {
        position: relative;
        height: 92%;
        width: 100%;

    }

    .professor_evaluation_general_info_left_freemium_button {
        position: absolute;
        height: fit-content;
        width: 65%;
        display: flex;
        direction: column;
        box-sizing: border-box;
        padding: 1.5%;
        border-radius: 5px;
        background-color: #064187;
        z-index: 7;
        top: 45%;

    }

    .professor_evaluation_general_info_right {
        position: relative;
        height: 100%;
        width: 98%;
        left: 2%;

    }

    .professor_evaluation_general_info_right_content {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 10px 1px rgb(80, 144, 240, 0.5);
    }

    .professor_evaluation_general_info_right_content_list {
        position: relative;
        height: 92%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .professor_evaluation_general_info_right_content_subject {
        position: relative;
        height: 6vh;
        width: 96%;
        margin-top: 1vh;
        left: 2%;
        display: flex;
        border-radius: 5px;
    }

    .professor_evaluation_general_info_right_content_subject_hover {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }

    .professor_evaluation_general_info_right_content_subject_left {
        position: relative;
        height: 6vh;
        width: 6vh;
        border-radius: 5px;
        object-fit: scale-down;
    }

    .professor_evaluation_general_info_right_content_subject_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 1em;
        justify-content: space-between;
    }

    .professor_evaluation_general_info_right_content_subject_right_name {
        position: relative;
        height: 100%;
        width: auto;
        display: flex;
        align-items: center;
    }

    .professor_evaluation_general_info_right_content_subject_right_points {
        position: relative;
        height: 100%;
        width: auto;
        display: flex;
        align-items: center;
    }

    .professor_evaluation_general_info_right_content_subject_topic {
        position: relative;
        height: 100%;
        width: 80%;
        display: grid;
    }

    .professor_evaluation_general_info_right_content_subject_subtopic {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 50%;
    }

    .professor_evaluation_general_info_right_metrics {
        position: relative;
        height: 98%;
        width: 100%;
        top: 2%;
        display: grid;
        grid-template-rows: 20% 80%;
        box-shadow: 0px 0px 10px 1px rgb(80, 144, 240, 0.5);
    }

    .professor_evaluation_general_info_right_metrics_content {
        position: relative;
        height: 96%;
        width: 96%;
        left: 2%;
        display: grid;
    }

    .professor_evaluation_general_info_right_metrics_content_metric {
        position: relative;
        height: 90%;
        width: 100%;
        top: 5%;
        display: grid;
        grid-template-columns: 30% 70%;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_name {
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_line_container {
        position: relative;
        height: 100%;
        width: 96%;
        left: 4%;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_line {
        position: absolute;
        height: 30%;
        width: 100%;
        top: 35%;
        border-radius: 25px;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_student {
        position: absolute;
        height: 60%;
        width: 2%;
        top: 20%;
        border-radius: 25px;
        background-color: #5090F0;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_school {
        position: absolute;
        height: 60%;
        width: 2%;
        top: 20%;
        border-radius: 25px;
        background-color: #6EE6A7;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_evaluation_general_info {
        position: relative;
        height: 97%;
        width: 96%;
        top: 3%;
        left: 2%;
        display: grid;
        grid-template-columns: 70% 30%;
    }

    .professor_evaluation_general_info_left {
        position: relative;
        height: 100%;
        width: 98%;
        display: grid;
        grid-template-rows: 55% 45%;
    }

    .professor_evaluation_general_info_global_competences {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
        box-shadow: 0px 0px 10px 1px rgb(80, 144, 240, 0.5);
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_evaluation_general_info_title {
        position: relative;
        height: 100%;
        width: 98%;
        left: 2%;
    }

    .professor_evaluation_general_info_left_octagon {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .professor_evaluation_general_info_right {
        position: relative;
        height: 99%;
        width: 98%;
        left: 2%;
    }

    .professor_evaluation_general_info_right_content {
        position: relative;
        height: 96%;
        width: 100%;
        top: 2%;
        display: grid;
        grid-template-rows: 15% 85%;
        box-shadow: 0px 0px 10px 1px rgb(80, 144, 240, 0.5);
    }

    .professor_evaluation_general_info_right_content_list {
        position: relative;
        height: 96%;
        width: 96%;
        left: 2%;
        display: grid;
    }

    .professor_evaluation_general_info_right_content_subject {
        position: relative;
        height: 90%;
        width: 100%;
        top: 5%;
        display: grid;
        grid-template-columns: 30% 70%;
        border-radius: 5px;
    }

    .professor_evaluation_general_info_right_content_subject_left {
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    .professor_evaluation_general_info_right_content_subject_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .professor_evaluation_general_info_right_content_subject_topic {
        position: relative;
        height: 100%;
        width: 80%;
        display: grid;
    }

    .professor_evaluation_general_info_right_content_subject_subtopic {
        position: relative;
        height: 10px;
        width: 10px;
        border-radius: 50%;
    }

    .professor_evaluation_general_info_right_metrics {
        position: relative;
        height: 143%;
        width: 100%;
        display: grid;
        top: 0;
        grid-template-rows: 8% 92%;
        box-shadow: 0px 0px 10px 1px rgb(80, 144, 240, 0.5);
    }

    .professor_evaluation_general_info_right_metrics_content {
        position: relative;
        height: 96%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-rows: 70% 30%;
    }

    .professor_evaluation_general_info_right_metrics_content_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .professor_evaluation_general_info_right_metrics_content_metric {
        position: relative;
        height: 90%;
        width: 100%;
        top: 5%;
        display: grid;
        grid-template-columns: 30% 70%;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_name {
        position: relative;
        height: 90%;
        width: 90%;
        top: 5%;
        left: 5%;
        border-radius: 5px;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_line_container {
        position: relative;
        height: 100%;
        width: 96%;
        left: 4%;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_line {
        position: absolute;
        height: 96%;
        width: 20%;
        top: 2%;
        left: 40%;
        border-radius: 25px;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_student {
        position: absolute;
        height: 3%;
        width: 40%;
        left: 30%;
        border-radius: 25px;
        background-color: #5090F0;
    }

    .professor_evaluation_general_info_right_metrics_content_metric_school {
        position: absolute;
        height: 3%;
        width: 40%;
        left: 30%;
        border-radius: 25px;
        background-color: #6EE6A7;
    }
}