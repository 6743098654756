/* Ordenador */

@media only screen and (min-width: 992px) {
    .edit_write_answers {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .edit_write_answers_professor {
        position: absolute;
        height: 20%;
        width: 20%;
        bottom: 0;
        left: 5%;
    }

    .edit_write_answers_text_box {
        position: absolute;
        height: 48%;
        width: 28%;
        top: 15%;
        left: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 1;
    }

    .edit_write_answers_arrows {
        position: absolute;
        height: 10%;
        width: 10%;
        bottom: 1.5%;
        left: 40%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_write_answers_confirm {
        position: absolute;
        height: 9%;
        width: 12%;
        bottom: 2%;
        left: 55%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1;
    }

    .edit_write_answers_help_button {
        position: absolute;
        height: 5vh;
        width: 18vw;
        display: grid;
        grid-template-columns: 5fr 1fr;
        bottom: 1vh;
        right: 5vw;
        z-index: 6;
    }

    .write_help_text_button {
        position: relative;
        height: 90%;
        width: 90%;
        background-color: white;
        color: #575757;
        font-weight: bold;
        border-radius: 5px;
    }

    .edit_write_answers_help_menu {
        position: absolute;
        height: 50%;
        width: 30%;
        top: 20%;
        left: 35%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 7;
    }

    .edit_write_answers_image {
        position: absolute;
        height: 50%;
        width: 35%;
        top: 12%;
        right: 15%;
        z-index: 1;
    }

    .edit_write_answers_write_boxes {
        position: absolute;
        height: 15%;
        width: 65%;
        top: 70%;
        right: 2%;
        display: grid;
        z-index: 1;
    }

    .edit_write_answers_write_box {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 5px;
        display: grid;
    }

    .edit_write_answers_write_box_wrong {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #FF8787;
        border-radius: 5px;
        display: grid;
    }

    .edit_write_answers_write_box_input {
        position: relative;
        height: 90%;
        width: 93%;
        left: 1%;
        right: 5%;
        padding-left: 5%;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        border: 0;
        border-bottom: 2px dashed;
        resize: none;
        font-size: calc(5px + 1.5vw);
        font-family: 'Causten';
        color: #000000;

        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
    }

    .edit_write_answers_outer_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    .edit_write_answers_add_option {
        position: relative;
        height: 50%;
        width: 10vw;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 5px;
        cursor: pointer;
    }

    .edit_write_answers_delete {
        position: relative;
        height: 5vh;
        width: 5vh;
        margin-top: 2%;
        background-color: #F0684F;
        border-radius: 50%;
        cursor: pointer;
    }

    .edit_write_answers_random_container {
        position: absolute;
        height: 7vh;
        width: 40%;
        left: 30%;
        bottom: 3vh;
        background-color: #FBC412;
        box-shadow: 0px 5px 0px #DFAA00;
        border-radius: 5px;
        display: grid;
        grid-template-columns: 18fr 4fr 2fr 4fr;
        padding-left: 1%;
        padding-right: 1%;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .edit_write_answers {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .edit_write_answers_text_box {
        position: absolute;
        height: 20%;
        width: 90%;
        top: 7%;
        left: 5%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 1;
    }

    .edit_write_answers_arrows {
        position: absolute;
        height: 12%;
        width: 20%;
        bottom: 0;
        left: 50%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_write_answers_confirm {
        position: absolute;
        height: 7%;
        width: 20%;
        bottom: 2.5%;
        left: 5%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1;
    }

    .edit_write_answers_help_button {
        position: absolute;
        height: 12%;
        width: 10%;
        bottom: 0;
        right: 2%;
        z-index: 1;
    }

    .edit_write_answers_help_menu {
        position: absolute;
        height: 50vh;
        width: 60vw;
        top: 20vh;
        left: 20vw;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 2;
    }

    .edit_write_answers_image {
        position: absolute;
        height: 40%;
        max-width: 40%;
        top: 10%;
        right: 15%;
        z-index: 1;
    }

    .edit_write_answers_write_boxes {
        position: absolute;
        height: 6%;
        width: 90%;
        top: 80%;
        right: 5%;
        display: grid;
        z-index: 1;
    }

    .edit_write_answers_write_box {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 5px;
        display: grid;
    }

    .edit_write_answers_write_box_wrong {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #FF8787;
        border-radius: 5px;
        display: grid;
    }

    .edit_write_answers_write_box_input {
        position: relative;
        height: 90%;
        width: 93%;
        left: 1%;
        right: 5%;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        border: 0;
        border-bottom: 2px dashed;
        resize: none;
        font-size: calc(5px + 1.5vw);
        font-family: 'Causten';
        color: #000000;

        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
    }
}