/* Ordenador */

@media only screen and (min-width: 992px) {

    .professor_activity_progress {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
        box-sizing: border-box;
        padding-left: 1%;
        padding-right: 1%;
        padding-bottom: 1%;
        overflow-x: scroll;
    }

    .professor_activity_progress_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 25% 20% 55%;
        box-sizing: border-box;
        z-index: 5;
    }

    .professor_activity_progress_top_background_unit {
        position: relative;
        height: 80%;
        width: 80%;
        border-radius: 10px;
        background: #E2EEFF;
        box-sizing: border-box;
    }

    .professor_activity_progress_top_content {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .professor_activity_progress_top_background {
        position: relative;
        height: 80%;
        width: 60%;
        border-radius: 10px;
        background: #E2EEFF;
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .professor_activity_progress_top_image {
        position: absolute;
        max-height: 100%;
        max-width: 100%;
        left: 0%;
        object-fit: cover;
        border-radius: 10px;
    }

    .professor_activity_progress_body {
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .professor_activity_progress_body_student {
        position: relative;
        height: 15%;
        width: 100%;
        margin-bottom: 0.5%;
        display: grid;
        box-sizing: border-box;
        padding-left: 1%;
        grid-template-columns: 25% 20% 55%;
        border-radius: 10px;
    }

    .professor_activity_progress_body_student_content {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .professor_activity_progress_body_student_content_data {
        position: relative;
        height: 100%;
        width: 11vw;
        box-sizing: border-box;
        margin-left: 1vw;
    }

    .activity_progress_box_shadow {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        box-shadow: 0px 0px 10px 0px #5090F080;
        border-radius: 10px;
        z-index: -1;
    }

    .text_black_small_bold_activity_progress {
        font-size:calc(5px + 0.8vw);
        font-family: 'Causten';
        font-weight: bold;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10%;
        margin-right: 10%;

        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
      }
}

/* Tablet */

@media only screen and (max-width: 991px) {

    .professor_activity_progress {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 3%;
    }

}