.calendar_month {
  position: relative;
  height: 90%;
  width: 96%;
  top: 5%;
  left: 2%;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: repeat(7, 1fr);
  grid-template-areas: 'mon tue wed thu fri sat sun'
                       'day00 day01 day02 day03 day04 day05 day06'
                       'day10 day11 day12 day13 day14 day15 day16'
                       'day20 day21 day22 day23 day24 day25 day26'
                       'day30 day31 day32 day33 day34 day35 day36'
                       'day40 day41 day42 day43 day44 day45 day46'
                       'day50 day51 day52 day53 day54 day55 day56'
                       'day60 day61 day62 day63 day64 day65 day66';
}