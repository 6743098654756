/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_evaluation_curricular_content_background {
        position: relative;
        height: 96%;
        width: 100%;
        margin-top: 2%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-radius: 10px;
    }

    .professor_evaluation_curricular_content_left {
        position: relative;
        height: 97%;
        width: 100%;
        top: 3%;
        display: grid;
        grid-template-rows: 8% 92%;
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_left_title {
        position: relative;
        height: 100%;
        width: fit-content;
        left: 1%;
        display: flex;
        align-items: center;
    }

    .professor_evaluation_curricular_content_left_radar {
        position: relative;
        height: 100%;
        width: 100%;
        left: 10%;
        top: 2%;
    }

    .professor_evaluation_curricular_content_left_topics {
        position: relative;
        height: 98%;
        width: 100%;
        padding: 1% 1% 2% 1%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        

    }

    .professor_evaluation_curricular_content_left_topic {
        position: relative;
        height: fit-content;
        width: 96%;
        left: 2%;
        display: flex;
        flex-direction: column;
        padding: 2%;
        margin-top: 2vh;
        background-color: #FAFAFA;
        border-radius: 10px;
        box-sizing: border-box;
        white-space: nowrap;

    }

    .professor_evaluation_curricular_content_left_topic_header {
        position: relative;
        height: 6vh;
        width: 100%;
        display: flex;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        justify-content: space-between;
        align-items: center;
    }

    .professor_evaluation_curricular_content_left_topic_header_name {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .professor_evaluation_curricular_content_left_scenarios {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 1px solid #5090F0;
        top: 6px;
    }

    .professor_evaluation_curricular_content_left_scenario {
        height: fit-content;
        width: 100%;
        overflow-x: hidden;
        display: flex;
        margin-top: 1vh;
        background-color: #ffffff;
        border-radius: 10px;
    }

    .professor_evaluation_curricular_content_left_scenario_info {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_evaluation_curricular_content_left_scenario_info_bottom {
        position: relative;
        height: inherit;
        width: 100%;
        display: grid;
        box-sizing: border-box;
    }

    .professor_evaluation_curricular_content_left_scenario_info_points {
        position: absolute;
        top: 2%;
        right: 2%;
        height: 10%;
        width: auto;
    }

    .professor_evaluation_curricular_content_right {
        position: relative;
        height: 100%;
        width: 100%;
        top: 1%;
        margin: 2%;
    }

    .professor_evaluation_curricular_content_freemium_button_right {
        position: absolute;
        height: fit-content;
        width: fit-content;
        display: flex;
        direction: column;
        box-sizing: border-box;
        padding: 1.5%;
        border-radius: 5px;
        bottom: 50%;
        background-color: #E2EEFF;
        z-index: 7;

    }

    .professor_evaluation_curricular_content_freemium_button_left {
        position: absolute;
        height: fit-content;
        width: fit-content;
        display: flex;
        direction: column;
        box-sizing: border-box;
        padding: 1.5%;
        border-radius: 5px;
        bottom: 50%;
        background-color: #E2EEFF;
        z-index: 7;
        /* top: 38%; */

    }

    .professor_evaluation_curricular_content_right_carousel {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 0.5%;
        padding-left: 0.5%;
        cursor: auto;
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_right_topic_late {
        position: relative;
        height: 99.5%;
        width: 96%;
        display: grid;
        margin-top: 0.5%;
        grid-template-columns: 6% 94%;
        margin-left: 4%;
    }

    .professor_evaluation_curricular_content_right_topic_title {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .professor_evaluation_curricular_content_right_subtopics {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_right_subtopic {
        position: relative;
        height: 100%;
        width: 92%;
        display: grid;
        grid-template-rows: 1.5fr 1fr 1.5fr 1fr;
        margin-left: 4%;
        margin-right: 4%;
    }

    .professor_evaluation_curricular_content_right_subtopic_title {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .professor_evaluation_curricular_content_right_subtopic_key_competences {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 25% 75%;
    }

    .professor_evaluation_curricular_content_right_subtopic_specific_competences {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 33% 67%;
    }

    .professor_evaluation_curricular_content_right_subtopic_element_line_container {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;


    }

    .professor_evaluation_curricular_content_right_subtopic_element_line {
        position: absolute;
        height: 26%;
        width: 100%;
        top: 30%;
        border-radius: 25px;
        background-color: #5090F025;
    }

    .professor_evaluation_curricular_content_right_subtopic_element_student {
        position: absolute;
        height: 26%;
        top: 30%;
        border-radius: 25px;
        background-color: #5090F0;
    }

    .professor_evaluation_curricular_content_right_subtopic_element_school {
        position: absolute;
        height: 26%;
        width: 1%;
        top: 30%;
        background-color: #6EE6A7;
    }

    .professor_evaluation_curricular_content_subjects {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_subjects_row_1 {
        position: relative;
        height: 100%;
        width: 25%;
        left: 37.5%;
    }

    .professor_evaluation_curricular_content_subjects_row_2 {
        position: relative;
        height: 100%;
        width: 50%;
        left: 25%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .professor_evaluation_curricular_content_subjects_row_3 {
        position: relative;
        height: 100%;
        width: 75%;
        left: 12.5%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .professor_evaluation_curricular_content_subjects_row_4 {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .professor_evaluation_curricular_content_subject {
        position: relative;
        height: 94%;
        width: 90%;
        top: 3%;
        left: 5%;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .professor_evaluation_curricular_content_subject_title {
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    .professor_evaluation_curricular_content_subject_content {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_subject_topic {
        position: relative;
        height: 90%;
        width: 90%;
        top: 5%;
        left: 5%;
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_evaluation_curricular_content_subject_topic_points {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .professor_evaluation_curricular_content_subject_topic_point {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 50%;
    }

    .professor_evaluation_curricular_content_subtopics {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_subtopics_row_1 {
        position: relative;
        height: 100%;
        width: 33.333%;
        left: 33.333%;
    }

    .professor_evaluation_curricular_content_subtopics_row_2 {
        position: relative;
        height: 100%;
        width: 66.666%;
        left: 16.666%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .professor_evaluation_curricular_content_subtopics_row_3 {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .professor_evaluation_curricular_content_subtopic {
        position: relative;
        height: 94%;
        width: 90%;
        top: 3%;
        left: 5%;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .professor_evaluation_curricular_content_subtopic_content {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 75% 25%;
    }

    .professor_evaluation_curricular_content_subtopic_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .professor_evaluation_curricular_content_subtopic_top_box {
        position: relative;
        height: 92%;
        width: 90%;
        top: 4%;
        left: 5%;
        background-color: #ffffff;
        display: grid;
        grid-template-rows: 15% 10% 10% 25% 40%;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 2%;
    }

    .professor_evaluation_curricular_content_subtopic_top_box_title {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 85% 15%;
    }

    .professor_evaluation_curricular_content_subtopic_top_box_title_circle {
        position: relative;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }

    .professor_evaluation_curricular_content_subtopic_top_box_screens {
        position: relative;
        height: 50%;
        width: 100%;
        top: 25%;
        display: grid;
    }

    .professor_evaluation_curricular_content_subtopic_top_box_screen {
        position: relative;
        height: 100%;
        width: 94%;
        left: 3%;
    }

    .professor_evaluation_curricular_content_subtopic_top_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
    }

    .professor_evaluation_curricular_content_subtopic_top_bottom_text {
        position: relative;
        height: 40%;
        width: 95%;
        left: 5%;
        top: 60%;
    }

    .professor_evaluation_curricular_content_subtopic_bottom_box {
        position: relative;
        height: 80%;
        width: 95%;
        top: 10%;
        left: 2.5%;
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 5% 55% 40%;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 2%;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_evaluation_curricular_content {
        position: relative;
        height: 97%;
        width: 100%;
        top: 3%;
        display: grid;
        grid-template-rows: 8% 92%;
        box-shadow: 0px 0px 10px 1px rgb(80, 144, 240, 0.5);
    }

    .professor_evaluation_curricular_content_title {
        position: relative;
        height: 100%;
        width: 99%;
        left: 1%;
        display: grid;
        grid-template-columns: 2% 98%;
    }

    .professor_evaluation_curricular_content_subjects {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_subjects_row_1 {
        position: relative;
        height: 100%;
        width: 25%;
        left: 37.5%;
    }

    .professor_evaluation_curricular_content_subjects_row_2 {
        position: relative;
        height: 100%;
        width: 50%;
        left: 25%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .professor_evaluation_curricular_content_subjects_row_3 {
        position: relative;
        height: 100%;
        width: 75%;
        left: 12.5%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .professor_evaluation_curricular_content_subjects_row_4 {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .professor_evaluation_curricular_content_subject {
        position: relative;
        height: 94%;
        width: 90%;
        top: 3%;
        left: 5%;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .professor_evaluation_curricular_content_subject_title {
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    .professor_evaluation_curricular_content_subject_content {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_subject_topic {
        position: relative;
        height: 90%;
        width: 90%;
        top: 5%;
        left: 5%;
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        border-radius: 5px;
    }

    .professor_evaluation_curricular_content_subject_topic_points {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .professor_evaluation_curricular_content_subject_topic_point {
        position: relative;
        height: 10px;
        width: 10px;
        border-radius: 50%;
    }

    .professor_evaluation_curricular_content_subtopics {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        overflow-y: scroll;
    }

    .professor_evaluation_curricular_content_subtopics_row_1 {
        position: relative;
        height: 100%;
        width: 33.333%;
        left: 33.333%;
    }

    .professor_evaluation_curricular_content_subtopics_row_2 {
        position: relative;
        height: 100%;
        width: 66.666%;
        left: 16.666%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .professor_evaluation_curricular_content_subtopics_row_3 {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .professor_evaluation_curricular_content_subtopic {
        position: relative;
        height: 94%;
        width: 90%;
        top: 3%;
        left: 5%;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .professor_evaluation_curricular_content_subtopic_content {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 75% 25%;
    }

    .professor_evaluation_curricular_content_subtopic_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .professor_evaluation_curricular_content_subtopic_top_box {
        position: relative;
        height: 92%;
        width: 90%;
        top: 4%;
        left: 5%;
        background-color: #ffffff;
        display: grid;
        grid-template-rows: 15% 10% 10% 25% 40%;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 2%;
    }

    .professor_evaluation_curricular_content_subtopic_top_box_title {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 85% 15%;
    }

    .professor_evaluation_curricular_content_subtopic_top_box_title_circle {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 50%;
    }

    .professor_evaluation_curricular_content_subtopic_top_box_screens {
        position: relative;
        height: 50%;
        width: 100%;
        top: 25%;
        display: grid;
    }

    .professor_evaluation_curricular_content_subtopic_top_box_screen {
        position: relative;
        height: 100%;
        width: 94%;
        left: 3%;
    }

    .professor_evaluation_curricular_content_subtopic_top_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
    }

    .professor_evaluation_curricular_content_subtopic_top_bottom_text {
        position: relative;
        height: 40%;
        width: 95%;
        left: 5%;
        top: 60%;
    }

    .professor_evaluation_curricular_content_subtopic_bottom_box {
        position: relative;
        height: 80%;
        width: 95%;
        top: 10%;
        left: 2.5%;
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 5% 55% 40%;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 2%;
    }
}