.teacher_tutorial_1_welcome_background {
    background-color: #00000088;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.teacher_tutorial_1_welcome_container {
    position: relative;
    width: 50vw;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    padding: 3% 4%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
}

.teacher_tutorial_1_welcome_button {
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.teacher_tutorial_1_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.teacher_tutorial_1_1_background_black {
    background-color: #00000088;
    position: relative;
    width: 85%;
    height: 100%;
    left: 15%;
    top: 0;
}

.teacher_tutorial_1_1_container {
    background-color: white;
    border-radius: 10px;
    position: absolute;
    width: 45%;
    left: 2%;
    top: 5%;
    height: fit-content;
}

.teacher_tutorial_1_2_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 45%;
    height: fit-content;
}

.teacher_tutorial_1_container_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 3% 4% 1% 4%;
}

.teacher_tutorial_1_container_bottom {
    position: relative;
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.progress_bar {
    width: 100%;
    height: 20px;
    background-color: #E1EDFF;
    border-radius: 15px;
    position: relative;
}

.progress_bar_fill {
    height: 100%;
    background-color: #5090F0;
    border-radius: 10px;
}

.teacher_tutorial_1_2_background_black_1 {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 85%;
    top: 15%;
    left: 0;
}

.teacher_tutorial_1_2_background_black_2 {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 10%;
    top: 0;
    left: 0;
}

.teacher_tutorial_1_2_background_black_variable_1 {
    background-color: #00000088;
    position: absolute;
    height: 5%;
    top: 10%;
    left: 0;
}

.teacher_tutorial_1_2_background_black_variable_2 {
    background-color: #00000088;
    position: absolute;
    height: 5%;
    top: 10%;
    right: 0;
}