/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_evaluation_menu  {
        position: relative;
        height: 94%;
        width: 94%;
        top: 3%;
        left: 3%;
    }

    .professor_evaluation_menu_student_list {
        position: relative;
        height: 94%;
        width: 94%;
        top: 3%;
        left: 3%;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
    }

    .professor_evaluation_menu_student_card_container  {
        position: relative;
        height: 80%;
        width: 100%;
        top: 10%
    }

    .professor_evaluation_menu_student_card  {
        position: relative;
        height: 80%;
        width: 90%;
        left: 5%;
        top: 10%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        display: grid;
        grid-template-columns: 10% 90%;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_evaluation_menu  {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .professor_evaluation_menu_student_list {
        position: relative;
        height: 94%;
        width: 94%;
        top: 3%;
        left: 3%;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
    }

    .professor_evaluation_menu_student_card_container  {
        position: relative;
        height: 80%;
        width: 100%;
        top: 10%
    }

    .professor_evaluation_menu_student_card  {
        position: relative;
        height: 80%;
        width: 90%;
        left: 5%;
        top: 10%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        display: grid;
        grid-template-columns: 10% 90%;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }
}