.bv_users_main {
    display: grid;
    grid-template-rows: 10% 10% 80%;
    width: 100%;
    height: 100vh;
    padding: 3%;
    box-sizing: border-box;
}

.bv_users_header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.bv_users_create_school {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    height: 6vh;
    margin: 2% 2.5%;
    padding: 1% 2%;
    box-sizing: border-box;
    background-color: #E2EEFF;
    box-shadow: 0px 0px 5px 0px #8DBCFF;
    border-radius: 5px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.bv_users_create_school_background {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.bv_users_create_school_container {
    position: absolute;
    display: grid;
    grid-template-rows: 35% 35% 30%;
    width: 50%;
    left: 25%;
    top: 35%;
    height: 30%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #8DBCFF;
    border-radius: 5px;
    z-index: 2;
}

.bv_users_create_school_close {
    position: absolute;
    top: 0.5em;
    right: 0;
    width: 2.5em;
    height: 2.5em;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.bv_users_create_school_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 0.5em 1em;
    background-color: #E2EEFF;
    box-shadow: 0px 0px 5px 2px #8DBCFF;
    border-radius: 5px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.bv_users_legends {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    width: fit-content;
    height: 100%;
    gap: 0.2vw;
}

.bv_users_legend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1%;
    width: fit-content;
    white-space: nowrap;
}

.bv_users_buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #8DBCFF;
}

.bv_users_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 80%;
    background-color: #E2EEFF;
    box-shadow: 0px 0px 5px 0px #8DBCFF;
    border-radius: 5px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.bv_users_content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
}

.bv_users_teacher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 6vh;
    margin: 2% 2.5%;
    padding: 1% 5%;
    box-sizing: border-box;
    background-color: #E2EEFF;
    box-shadow: 0px 0px 5px 0px #8DBCFF;
    border-radius: 5px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.bv_users_teacher_icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    gap: 1vw;
    height: 100%;
}

.bv_users_teacher_selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 7vh;
    margin: 2% 2.5%;
    padding: 1% 5%;
    gap: 2%;
    box-sizing: border-box;
    border-bottom: 1px solid #8DBCFF;
}

.bv_users_login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 6vh;
    margin: 2% 2.5%;
    padding: 1% 5%;
    box-sizing: border-box;
    background-color: #E2EEFF;
    box-shadow: 0px 0px 5px 0px #8DBCFF;
    border-radius: 5px;
}

.bv_users_impersonate_button {
    padding: 0.5% 1%;
    border-radius: 5px;
    background-color: #f0684f;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}