@media only screen and (min-width: 992px) {
    .doughnut_chart  {
        position: relative;
        height: 90%;
        width: 90%;
        left: 5%;
        top: 5%;
    }
    .doughnut_chart_heart{
        position: absolute;
        /* justify-content: center;
        align-items: center; */
        top: 50%;
        left: 37.5%;

    }

}

/* Laptop */

@media only screen and (max-width: 1400px) {
    .doughnut_chart  {
        position: relative;
        height: 90%;
        width: 90%;
        left: 5%;
        top: 5%;
    }

}

/* Tablet */

@media only screen and (max-width: 991px) {
    .doughnut_chart  {
        position: relative;
        height: 90%;
        width: 90%;
        left: 5%;
        top: 5%;
    }

}