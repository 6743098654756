/* Ordenador */

@media only screen and (min-width: 992px) {
    .exercises_experiment_bar {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .exercises_experiment_bar_levels {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
    }

    .exercises_experiment_bar_point_completed {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #6EE6A7;
    }

    .exercises_experiment_bar_point_wrong {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #F0684F;
    }

    .exercises_experiment_bar_point_now {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #D9D9D9;
    }

    .exercises_experiment_bar_point {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #D9D9D9
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .exercises_experiment_bar {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .exercises_experiment_bar_levels {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
    }

    .exercises_experiment_bar_point_completed {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #6EE6A7;
    }

    .exercises_experiment_bar_point {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #D9D9D9
    }
}