/* Ordenador */

@media only screen and (min-width: 992px) {
    .student_radar_chart  {
        position: relative;
        height: 90%;
        width: 90%;
        left: 5%;
        top: 5%;
    }

    .student_radar_chart_full  {
        position: relative;
        height: 80%;
        width: 80%;
        left: 10%;
        top: 4%;
    }
}

/* Laptop */

@media only screen and (max-width: 1400px) {
    .student_radar_chart  {
        position: relative;
        height: 90%;
        width: 90%;
        left: 5%;
        top: 5%;
    }

    .student_radar_chart_full  {
        position: relative;
        height: 80%;
        width: 80%;
        left: 10%;
        top: 4%;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .student_radar_chart  {
        position: relative;
        height: 47%;
        width: 47%;
        left: 30%;
        top: -20%;
    }

    .student_radar_chart_full  {
        position: relative;
        height: 80%;
        width: 80%;
        left: 10%;
        top: 4%;
    }
}