/* Ordenador */

@media only screen and (min-width: 992px) {
    .workshop {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        z-index: 5;
    }

    .workshop_banner_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .workshop_banner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .workshop_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
    }

    .workshop_close_button {
        position: absolute;
        width: 3%;
        height: 6%;
        right: 4%;
        top: 12.5%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .workshop_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .workshop_menu {
        position: absolute;
        width: 100%;
        height: 78%;
        top: 22%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 50%);
    }

    .workshop_menu_building_not_purchased_container {
        position: relative;
        width: 90%;
        height: 90%;
        /* top: 5%; */
        left: 5%;
        border-radius: 5px;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 2%;
    }

    .workshop_menu_building_not_purchased {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .workshop_menu_building_not_purchased_top {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 93% 7%;
    }

    .workshop_menu_building_not_purchased_top_line {
        position: relative;
        width: 40%;
        height: 100%;
        background-color: #FBC412;
        border-radius: 5px;
    }

    .workshop_menu_building_not_purchased_bottom {
        position: relative;
        width: 70%;
        height: 90%;
        left: 15%;
        top: 5%;
        box-shadow: 0px 0px 20px 1px #D9D9D9;
        display: grid;
        grid-template-rows: 20% 10% 45% 25%;
        box-sizing: border-box;
        padding: 1% 2%;
        border-radius: 5px;
    }

    .workshop_menu_building_not_purchased_button {
        position: relative;
        width: 40%;
        height: 100%;
        left: 32.5%;
        display: grid;
        grid-template-rows: 30% 70%;
        box-sizing: border-box;
        padding: 1% 2%;
        border-radius: 5px;
        background-color: #6EE6A7;
    }

    .workshop_menu_building_not_purchased_button_bottom {
        position: relative;
        width: 100%;
        height: 90%;
        display: grid;
        grid-template-columns: 35% 65%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .workshop_menu_building_purchased {
        position: relative;
        width: 90%;
        height: 90%;
        /* top: 5%; */
        left: 5%;
        display: grid;
        grid-template-rows: 35% 10% 18% 27% 10%;
        border-radius: 5px;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 1%;
    }

    .workshop_menu_building_purchased_top {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 20% 80%;
        box-sizing: border-box;
        padding: 0% 1%;
    }

    .workshop_menu_building_purchased_top_right {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 45% 4% 51%;
        box-sizing: border-box;
        padding: 1% 2%;
    }

    .workshop_menu_building_purchased_top_right_line {
        position: relative;
        width: 75%;
        height: 100%;
        background-color: #FBC412;
        border-radius: 5px;
    }

    .workshop_menu_building_purchased_top_right_bottom {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 30% 27.5% 35% 7.5%;
        box-sizing: border-box;
        padding: 1% 1%;
    }

    .workshop_menu_building_purchased_top_right_bottom_population {
        position: relative;
        width: 100%;
        height: 80%;
        top: 10%;
        box-sizing: border-box;
        padding: 1%;
        background-color: #6EE6A7;
        border-radius: 5px;
    }

    .workshop_menu_building_purchased_top_right_bottom_stars {
        position: relative;
        width: 100%;
        height: 90%;
        display: grid;
        grid-template-columns: repeat(5, 20%);
    }

    .workshop_menu_building_purchased_bar_background {
        position: relative;
        height: 80%;
        width: 96%;
        top: 10%;
        left: 2%;
        background-color: #D9D9D9;
        border-radius: 25px;
    }

    .workshop_menu_building_purchased_bar_background_text {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
    }

    .workshop_menu_building_purchased_bar {
        position: absolute;
        height: 100%;
        background-color: #292F4C;
        border-radius: 25px;
    }

    .workshop_menu_building_purchased_upgrade_background {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .workshop_menu_building_purchased_upgrade {
        position: relative;
        height: 100%;
        width: 96%;
        /* top: 5%; */
        left: 2%;
        border-radius: 5px;
        box-shadow: 0px 0px 20px 1px #D9D9D9;
        box-sizing: border-box;
        padding: 0% 1%;
        display: grid;
        grid-template-columns: 10% 65% 25%;
    }

    .workshop_menu_building_purchased_upgrade_center {
        position: relative;
        height: 100%;
        width: 100%;
        left: 2%;
        box-sizing: border-box;
        padding: 1%;
        display: flex;
        flex-direction: column;
    }

    .workshop_menu_building_purchased_upgrade_center_text {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .workshop_menu_building_purchased_upgrade_button {
        position: relative;
        height: 90%;
        width: 94%;
        left: 3%;
        top: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 1% 2%;
        border-radius: 5px;
        background-color: #6EE6A7;
    }

    .workshop_menu_building_purchased_upgrade_max {
        position: relative;
        height: 90%;
        width: 96%;
        top: 5%;
        left: 2%;
        border-radius: 5px;
        box-shadow: 0px 0px 20px 1px #D9D9D9;
        box-sizing: border-box;
        padding: 1%;
        display: grid;
        grid-template-columns: 10% 90%;
    }

    .workshop_menu_building_purchased_power {
        position: relative;
        height: 60%;
        width: 40%;
        top: 40%;
        left: 30%;
        border-radius: 10px;
        background-color: #292F4C;
        box-sizing: border-box;
        padding: 1%;
    }

    .workshop_video {
        position: absolute;
        aspect-ratio: 16 / 9;
        width: 100%;
        z-index: 3000;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .workshop {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        z-index: 5;
    }

    .workshop_banner_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .workshop_banner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .workshop_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
    }

    .workshop_close_button {
        position: absolute;
        width: 3%;
        height: 6%;
        right: 4%;
        top: 10%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .workshop_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .workshop_menu {
        position: absolute;
        width: 100%;
        height: 73%;
        top: 19%;
        display: grid;
        grid-template-rows: repeat(3, 50%);
        grid-template-columns: repeat(2, 50%);
        overflow-y: scroll;
    }

    .workshop_menu_building_not_purchased_container {
        position: relative;
        width: 80%;
        height: 90%;
        top: 5%;
        left: 10%;
        border-radius: 5px;
        /* background-color: #ffffff; */
        box-sizing: border-box;
        padding: 2%;
    }

    .workshop_menu_building_not_purchased {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .workshop_menu_building_not_purchased_top {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 93% 7%;
    }

    .workshop_menu_building_not_purchased_top_line {
        position: relative;
        width: 40%;
        height: 100%;
        background-color: #FBC412;
        border-radius: 5px;
    }

    .workshop_menu_building_not_purchased_bottom {
        position: relative;
        width: 70%;
        height: 90%;
        left: 15%;
        top: 5%;
        box-shadow: 0px 0px 20px 1px #D9D9D9;
        display: grid;
        grid-template-rows: 20% 10% 40% 30%;
        box-sizing: border-box;
        padding: 1% 2%;
        border-radius: 5px;
    }

    .workshop_menu_building_not_purchased_button {
        position: relative;
        width: 35%;
        height: 100%;
        left: 32.5%;
        display: grid;
        grid-template-rows: 35% 65%;
        box-sizing: border-box;
        padding: 1% 2%;
        border-radius: 5px;
        background-color: #6EE6A7;
    }

    .workshop_menu_building_not_purchased_button_bottom {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
    }

    .workshop_menu_building_purchased {
        position: relative;
        width: 80%;
        height: 90%;
        top: 5%;
        left: 10%;
        display: grid;
        grid-template-rows: 35% 10% 17.5% 27.5% 10%;
        border-radius: 5px;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 1%;
    }

    .workshop_menu_building_purchased_top {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 20% 80%;
        box-sizing: border-box;
        padding: 0% 1%;
    }

    .workshop_menu_building_purchased_top_right {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 45% 4% 51%;
        box-sizing: border-box;
        padding: 1% 2%;
    }

    .workshop_menu_building_purchased_top_right_line {
        position: relative;
        width: 40%;
        height: 100%;
        background-color: #FBC412;
        border-radius: 5px;
    }

    .workshop_menu_building_purchased_top_right_bottom {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 35% 22.5% 35% 7.5%;
        box-sizing: border-box;
        padding: 1% 1%;
    }

    .workshop_menu_building_purchased_top_right_bottom_population {
        position: relative;
        width: 100%;
        height: 80%;
        top: 10%;
        box-sizing: border-box;
        padding: 1%;
        background-color: #6EE6A7;
        border-radius: 5px;
    }

    .workshop_menu_building_purchased_top_right_bottom_stars {
        position: relative;
        width: 100%;
        height: 90%;
        display: grid;
        grid-template-columns: repeat(5, 20%);
    }

    .workshop_menu_building_purchased_bar_background {
        position: relative;
        height: 80%;
        width: 96%;
        top: 10%;
        left: 2%;
        background-color: #D9D9D9;
        border-radius: 25px;
    }

    .workshop_menu_building_purchased_bar_background_text {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
    }

    .workshop_menu_building_purchased_bar {
        position: absolute;
        height: 100%;
        background-color: #292F4C;
        border-radius: 25px;
    }

    .workshop_menu_building_purchased_upgrade_background {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .workshop_menu_building_purchased_upgrade {
        position: relative;
        height: 100%;
        width: 96%;
        /* top: 5%; */
        left: 2%;
        border-radius: 5px;
        box-shadow: 0px 0px 20px 1px #D9D9D9;
        box-sizing: border-box;
        padding-left: 1%;
        display: grid;
        grid-template-columns: 10% 65% 25%;
    }

    .workshop_menu_building_purchased_upgrade_center {
        position: relative;
        height: 100%;
        width: 96%;
        left: 4%;
        box-sizing: border-box;
        padding: 1%;
        display: grid;
        grid-template-rows: 60% 40%;
    }

    .workshop_menu_building_purchased_upgrade_button {
        position: relative;
        height: 90%;
        width: 94%;
        left: 3%;
        top: 5%;
        display: grid;
        grid-template-rows: 35% 65%;
        box-sizing: border-box;
        padding: 1% 2%;
        border-radius: 5px;
        background-color: #6EE6A7;
    }

    .workshop_menu_building_purchased_upgrade_max {
        position: relative;
        height: 90%;
        width: 96%;
        top: 5%;
        left: 2%;
        border-radius: 5px;
        box-shadow: 0px 0px 20px 1px #D9D9D9;
        box-sizing: border-box;
        padding: 1%;
        display: grid;
        grid-template-columns: 10% 90%;
    }

    .workshop_menu_building_purchased_power {
        position: relative;
        height: 80%;
        width: 40%;
        top: 20%;
        left: 30%;
        border-radius: 10px;
        background-color: #292F4C;
        box-sizing: border-box;
        padding: 1%;
    }
}