/* Ordenador */


@media only screen and (min-width: 992px) {
    .myhome {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        z-index: 5;
    }

    .myhome_banner_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .myhome_banner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .myhome_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
    }

    .myhome_close_button {
        position: absolute;
        width: 3%;
        height: 6%;
        right: 4%;
        top: 12.5%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .myhome_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .myhome_menu {
        position: absolute;
        width: 90%;
        height: 73%;
        top: 19%;
        left: 5%;
        display: grid;
        grid-template-columns: 55% 45%;
        border-radius: 5px;
        background-image: url("../../assets/student/Background_casa_alumno.png");
        background-color: #212A37;
    }

    .myhome_menu_left {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .myhome_menu_right {
        position: relative;
        width: 95%;
        height: 90%;
        left: 2.5%;
        top: 5%;
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        overflow-y: scroll;
    }

    .myhome_menu_role {
        position: relative;
        width: 98%;
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 22.5% 77.5%;
        border-radius: 5px;
    }

    .myhome_menu_image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: fill;
        left: 5%;
    }

    .myhome_menu_text {
        position: relative;
        width: 95%;
        height: 95%;
        left: 3%;
        top: 2.5%;
        background-color: #ffffff;
        display: grid;
        grid-template-rows: 1fr 2.5fr;
        font-style: italic;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .myhome {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        z-index: 5;
    }

    .myhome_banner_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .myhome_banner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .myhome_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
    }

    .myhome_close_button {
        position: absolute;
        width: 3%;
        height: 6%;
        right: 4%;
        top: 10%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .myhome_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .myhome_menu {
        position: absolute;
        width: 100%;
        height: 73%;
        top: 19%;
    }

    .myhome_menu_box {
        position: relative;
        width: 90%;
        height: 100%;
        left: 5%;
        display: grid;
        grid-template-columns: 30% 70%;
        background-color: #ffffff;
        border-radius: 5px;
    }

    .myhome_menu_box_left {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .myhome_menu_box_left_professor {
        position: absolute;
        height: 90%;
        width: 90%;
        bottom: 0;
        z-index: 1;
    }

    .myhome_menu_box_right {
        position: relative;
        width: 90%;
        height: 100%;
        left: 5%;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
    }
}