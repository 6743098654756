.shuttle {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 20% 80%;
    z-index: 5;
}

.shuttle_unlock_video {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.shuttle_banner_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: hidden;
    margin: 0;
}

.shuttle_background {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
}

.shuttle_close_button {
    position: absolute;
    width: 3%;
    height: 6%;
    right: 4%;
    top: 12.5%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.shuttle_title {
    position: absolute;
    width: 50%;
    height: 7%;
    left: 5%;
    top: 11.5%;
}

.shuttle_main {
    position: relative;
    width: 70%;
    left: 15%;
    height: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
}

.shuttle_biomes {
    position: relative;
    width: 70%;
    height: 95%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

}

.shuttle_biome {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 10%;
    padding: 0 5%;
    box-sizing: border-box;
    margin-bottom: 2%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.shuttle_biome_icon_container {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
}

.shuttle_biome_badge {
    position: absolute;
    top: -10px;
    right: -10px;
    width: fit-content;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: #F0684F;
    color: white;
    box-sizing: border-box;
    padding: 0px 5px;
    font-family: Causten;
    font-size: 10px;
}

.shuttle_eutons {
    position: relative;
    width: 100%;
    height: 96%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    overflow-y: scroll;
    row-gap: 2vh;
}

.shuttle_euton {
    position: relative;
    width: 99%;
    height: 15vh;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #FFFFFF;
    border-radius: 5px;
    /* padding: 10px; */
    /* box-sizing: border-box; */
}

.shuttle_euton_name_description {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    /* gap: 0.5vh; */
    padding: 0vh 0vw 0.5vh 1.5vw;

}

.shuttle_euton_name_text {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width: fit-content;
    font-size: calc(5px + 1vw);
    font-family: "Causten";

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.shuttle_euton_button {
    position: relative;
    height: 2.5vh;
    width: 20vh;
    border-radius: 5px;

}

.shuttle_euton_item {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 2%;
}

.shuttle_euton_item_text {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vh;
}

.shuttle_euton_info {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.shuttle_euton_back_button {
    position: absolute;
    width: 15vh;
    height: 5vh;
    border-radius: 5px;
    bottom: 5%;
    left: 7%;
    background-color: #FECF3C;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}

.shuttle_euton_items_button {
    position: absolute;
    width: 15vh;
    height: 5vh;
    border-radius: 5px;
    bottom: 5%;
    right: 7%;
    background-color: #50F0C0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}

.shuttle_euton_info_card {
    position: absolute;
    width: 20vw;
    height: 61vh;
    border-radius: 5px;
    bottom: -5vh;
    left: 41vw;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.shuttle_euton_info_card_front {
    position: absolute;
    width: 130%;
    height: 130%;
    /* background-color: #FFDDDD
    ; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 5%;
    bottom: 2.5%;
}

.shuttle_euton_info_card_front_level {
    position: relative;
    width: fit-content;

    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: "border-box";
    gap: 0.5vw;
}

.shuttle_euton_info_card_front_desc {
    position: absolute;
    width: 75%;
    height: 30%;
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; */
    /* background-color: red; */
    top: 60%;
    box-sizing: border-box;
    padding: 2%;
}

.shuttle_euton_info_card_back {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Eutons/Cartas/card_back.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 10px;

}

.shuttle_item_trade {
    position: absolute;
    width: 30%;
    height: 60%;
    top: 20%;
    left: 15%;
    background-color: #FECF3C;
    box-shadow: 0px 0px 3px 0px #FECF3C;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.shuttle_item_restore {
    position: absolute;
    width: 30%;
    height: 60%;
    top: 20%;
    right: 35%;
    background-color: #50F0C0;
    box-shadow: 0px 0px 3px 0px #50F0C0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* new stuff */
.shuttle_trade_menu_background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.shuttle_trade_menu {
    position: absolute;
    height: 75%;
    width: 75%;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    box-sizing: border-box;
    padding: 2%;
    left: 12.5%;
    top: 12.5%;
}

.shuttle_trade_menu_biomes {
    position: absolute;
    height: 90%;
    width: 80%;
    left: 18%;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
}

.shuttle_trade_menu_biome {
    display: grid;
    grid-template-rows: 5% 95%;
    height: 100%;
    width: 90%;
    margin-left: 5%;
    gap: 1.5vh;
}

.shuttle_trade_menu_students {
    position: absolute;
    height: 70%;
    top: 26%;
    width: 95.5%;

}

.shuttle_trade_menu_item {
    display: contents;
}

.shuttle_trade_menu_item_name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    grid-column: span 1;
}

.shuttle_trade_menu_item_items {
    display: contents;
}

.shuttle_trade_menu_item_background {
    height: 100%;
    width: fit-content;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 4%;
    margin-left: 5%;
}

.shuttle_trade_menu_item_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.shuttle_trade_menu_students_container {
    position: relative;
    height: 8vh;
    width: 100%;
    border-bottom: 1px solid #B8B8B8;
    display: grid;
    /* grid-template-columns: 16% 84%; */

    grid-template-columns: 15.5% 84.5%;
    align-items: center;

}

.shuttle_trade_menu_students_names {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 1vh;
    align-items: center;
    white-space: nowrap;
}

.shuttle_trade_menu_students_buttons {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    /* gap: 1vw; */
}

.shuttle_menu_student_icon {
    position: relative;
    height: 4vh;
    width: 4vh;
    border-radius: 50%;
    background-color: #E6C9FF;
}

.shuttle_trade_menu_confirm_container {
    background-color: rgb(41, 47, 76, 75%);
    position: fixed;
    height: 50%;
    width: 35%;
    border-radius: 10px;
    z-index: 99;
    top: 25%;
    left: 35%;
}

.shuttle_trade_menu_confirm_container_grid {
    position: relative;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 35% 45% 20%;
}

.shuttle_trade_menu_confirm_header_text {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shuttle_trade_menu_confirm_buttons {
    position: relative;
    height: 100%;
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 42.5%;
}

.shuttle_trade_menu_confirm_button_yes {
    position: relative;
    width: 4vh;
    height: 4vh;
    background-color: #6EE6A7;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.shuttle_trade_menu_confirm_button_no {
    position: relative;
    width: 4vh;
    height: 4vh;
    background-color: #F06D50;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.shuttle_trade_menu_confirm_items_grid {
    position: relative;
    height: 100%;
    width: 90%;
    margin-left: 5%;
    display: grid;
    grid-template-columns: 40% 20% 40%;
}

.shuttle_trade_menu_confirm_items_offer {
    position: relative;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 15% 60% 30%;
}

.shuttle_trade_menu_confirm_items_offer_badge {
    position: relative;
    height: 9vh;
    width: 100%;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 1.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.shuttle_euton_video_container {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 25%;
    /* top: 5%; */
}