/* Ordenador */

@media only screen and (min-width: 992px) {
    .radar_chart {
        position: relative;
        height: 90%;
        width: 90%;
        left: 5%;
        top: 4%;
        display: block;
    }

    .radar_chart_full {
        position: relative;
        height: 100%;
        width: 100%;
        left: 5%;
        top: 0;
        display: block;
    }
}

/* Laptop */

@media only screen and (max-width: 1400px) {
    .radar_chart {
        position: relative;
        height: 90%;
        width: 90%;
        left: 5%;
        top: 4%;
        display: block;
    }

    .radar_chart_full {
        position: relative;
        height: 100%;
        width: 100%;
        left: 5%;
        top: 0;
        display: block;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .radar_chart {
        position: relative;
        height: 47%;
        width: 47%;
        left: 30%;
        top: -20%;
    }

    .radar_chart_full {
        position: relative;
        height: 80%;
        width: 80%;
        left: 10%;
        top: 4%;
    }
}