/* Ordenador */

@media only screen and (min-width: 992px) {
    .edit_drag_drop_arrows {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .edit_drag_drop_arrows_professor {
        position: absolute;
        height: 20%;
        width: 20%;
        bottom: 0;
        left: 5%;
    }

    .edit_drag_drop_arrows_text_box {
        position: absolute;
        height: 48%;
        width: 28%;
        top: 15%;
        left: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 6;
    }

    .edit_drag_drop_arrows_arrows {
        position: absolute;
        height: 10%;
        width: 10%;
        bottom: 1.5%;
        left: 40%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 6;
    }

    .edit_drag_drop_arrows_confirm {
        position: absolute;
        height: 9%;
        width: 12%;
        bottom: 2%;
        left: 55%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 6;
    }

    .edit_drag_drop_arrows_help_button {
        position: absolute;
        height: 5vh;
        width: 18vw;
        display: grid;
        grid-template-columns: 5fr 1fr;
        bottom: 1vh;
        right: 5vw;
        z-index: 6;
    }

    .write_help_text_button {
        position: relative;
        height: 90%;
        width: 90%;
        background-color: white;
        color: #575757;
        font-weight: bold;
        border-radius: 5px;
    }

    .edit_drag_drop_arrows_help_menu {
        position: absolute;
        height: 50%;
        width: 30%;
        top: 20%;
        left: 35%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 7;
    }

    .edit_drag_drop_arrows_background_edit {
        position: absolute;
        height: 70%;
        width: 60%;
        top: 15%;
        right: 2%;
        z-index: 6;
    }

    .edit_drag_drop_arrows_top {
        position: relative;
        margin-top: 0%;
        height: 100%;
        width: 100%;
    }

    .edit_drag_drop_arrows_droppable_0 {
        position: absolute;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .edit_drag_drop_arrows_middle {
        position: absolute;
        height: 100%;
        width: 10%;
        left: 46%;
        top: 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .edit_drag_drop_arrows_label {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .edit_drag_drop_arrows_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .edit_drag_drop_arrows_draggable_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: repeat(5, 1fr);
    }

    .edit_drag_drop_arrows_draggable_container_layout {
        position: relative;
        width: 100%;
        height: 90%;
        display: grid;
        grid-template-columns: 42.5% 5% 42.5% 10%;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 10px;
        align-items: center;
        /* margin-bottom: 3%; */
    }

    .edit_drag_drop_arrows_draggable {
        position: relative;
        width: 95%;
        height: 90%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        /* margin-left: 3%; */
    }

    .edit_drag_drop_arrows_add_option {
        position: relative;
        height: 70%;
        width: 7.5%;
        left: 41.25%;
        top: 10%;
        background-color: #FFFFFF;
        border-radius: 5px;
    }

    .edit_drag_drop_arrows_draggable_bottom {
        position: relative;
        width: 95%;
        height: 30%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }

    .edit_drag_drop_arrows_option_delete {
        position: absolute;
        right: 0;
        height: 5vh;
        width: 5vh;
        margin-right: 3%;
        background-color: #F0684F;
        border-radius: 50%;
        cursor: pointer;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .edit_drag_drop_arrows {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .edit_drag_drop_arrows_text_box {
        position: absolute;
        height: 20%;
        width: 90%;
        top: 7%;
        left: 5%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 6;
    }

    .edit_drag_drop_arrows_arrows {
        position: absolute;
        height: 12%;
        width: 20%;
        bottom: 0;
        left: 50%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 6;
    }

    .edit_drag_drop_arrows_confirm {
        position: absolute;
        height: 7%;
        width: 20%;
        bottom: 2.5%;
        left: 5%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 6;
    }

    .edit_drag_drop_arrows_help_button {
        position: absolute;
        height: 12%;
        width: 10%;
        bottom: 0;
        right: 2%;
        z-index: 6;
    }

    .edit_drag_drop_arrows_help_menu {
        position: absolute;
        height: 50%;
        width: 60%;
        top: 20%;
        left: 20%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 7;
    }

    .edit_drag_drop_arrows_background {
        position: absolute;
        height: 58%;
        width: 88.5%;
        top: 29%;
        left: 5%;
        display: grid;
        grid-template-rows: 70% 30%;
        z-index: 6;
    }

    .edit_drag_drop_arrows_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .edit_drag_drop_arrows_droppable_0 {
        position: absolute;
        height: 100%;
        width: 48%;
        background-color: #6EE6A7;
        border-radius: 10px;
    }

    .edit_drag_drop_arrows_droppable_1 {
        position: absolute;
        height: 100%;
        width: 48%;
        left: 54%;
        background-color: #F0684F;
        border-radius: 10px;
    }

    .edit_drag_drop_arrows_label {
        position: relative;
        height: 12%;
        width: 100%;
    }

    .edit_drag_drop_arrows_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
    }

    .edit_drag_drop_arrows_draggable {
        position: relative;
        width: 90%;
        height: 12.5%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }

    .edit_drag_drop_arrows_draggable_bottom {
        position: relative;
        width: 95%;
        height: 30%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }
}