@font-face {
    font-family: 'Causten';
    src: local('Causten'), url(../../fonts/Causten/Causten-Regular.otf) format('opentype');
}

.loading_screen {
    position: relative;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(80, 144, 240, 1), rgba(178, 255, 232, 1));
    user-select: none;
    z-index: 100;
}

.loading_text_welcome {
    position: fixed;
    height: 6.5%;
    width: 64%;
    left: 18%;
    top: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    gap: 1vw;
}

.loading_text_container {
    position: fixed;
    height: 6.5%;
    width: 64%;
    left: 18%;
    top: 62.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.loading_text {
    font-size: calc(5px + 2.2vw);
    font-weight: bold;
    color: #ffffff;
    font-family: 'Causten';
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.loading_bar {
    position: fixed;
    height: 3.61%;
    width: 50%;
    left: 25%;
    top: 78.5%;
}

.loading_bar_right {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
}

.loading_bar_center {
    height: 100%;
    border-radius: 5px;
    background-color: #6EE6A7;
}

.loading_subtext_container {
    position: fixed;
    height: 6.5%;
    width: 64%;
    left: 18%;
    top: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.loading_subtext {
    font-size: calc(5px + 1.8vw);
    color: #ffffff;
    font-family: 'Causten';
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.loading_image_container {
    position: fixed;
    width: 30.4%;
    height: 30%;
    top: 30%;
    left: 34.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.loading_image {
    position: absolute;
    height: 100%;
    max-width: 100%;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.white_bar_long {
    position: fixed;
    width: 0.365%;
    height: 20%;
    background-color: #ffffff;
}

.white_bar_short {
    position: fixed;
    width: 0.365%;
    height: 10.3%;
    background-color: #ffffff;
}

.white_mini_bar {
    position: fixed;
    width: 0.208333%;
    height: 1.66666%;
    background-color: #ffffff;
}

.white_circle {
    position: fixed;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ffffff;
}