/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_class_map_menu  {
        position: relative;
        height: 98%;
        width: 98%;
        top: 1%;
        left: 1%;
        overflow-y: scroll;
        display:inline-block;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_class_map_menu  {
        position: relative;
        height: 94%;
        width: 94%;
        top: 3%;
        left: 3%;
        overflow-y: scroll;
        display:inline-block;
    }
}