/* Ordenador */

@media only screen and (min-width: 992px) {
    .definition {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .definition_exit_button {
        position: absolute;
        height: 6%;
        width: 60%;
        bottom: 15%;
        right: 5%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
        background-color: #5090F0;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 1%;
        z-index: 1;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .definition {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;

    }
}