body {
  height: 100vh;
  width: 100vw;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.loading-floater {
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  margin: 0.25em;
  transform: translatey(-50px);
  animation: upDown 1.5s alternate infinite ease-in-out;
}

@keyframes upDown {
  50% {
    transform: translatey(50px);
  }

  100% {
    transform: translatey(-50px);
  }
}

@font-face {
  font-family: "Causten";
  src: local("Causten"),
    url(./fonts/Causten/Causten-Regular.otf) format("opentype");
}

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: #e1edff;
  border-radius: 5px;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #5090f0;
}

* {
  margin: 0;
  padding: 0px;
}

body {
  box-sizing: border-box;
  font-family: "Causten";
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

/*@media screen and (min-width: 700px){
  .app {
    display: grid;
    grid-template-columns: 5.2% 94.8%;
    grid-template-areas: 
    "sidebar main"
    ;
  }
}

@media screen and (max-width: 700px){
  .app {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: 
    "main";
  }
}*/

.app {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
}

.settings_button {
  padding: 0.8vh;
  box-sizing: border-box;
  position: absolute;
  bottom: 13vh;
  right: 3vw;
  background-color: rgb(41, 47, 76, 75%);
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
  border-radius: 5px;
  z-index: 1000;
}

.center_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top_center_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.left_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.right_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.top_left_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
}

.top_right_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-start;
}

.bottom_center_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.bottom_left_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-end;
}

.bottom_right_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

/* Ordenador */

@media only screen and (min-width: 992px) {
  .image {
    position: relative;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .icon {
    position: relative;
    max-height: 70%;
    max-width: 70%;
  }

  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    background-color: #ffffff;
  }

  .foreground {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 100;
    object-fit: cover;
  }

  .text_black_mega_small {
    font-size: calc(5px + 0.25vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_hiper_small {
    font-size: calc(5px + 0.35vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_super_small {
    font-size: calc(5px + 0.45vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_really_small {
    font-size: calc(5px + 0.6vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_very_small {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_small {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_medium {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_big {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_ultra_small_bold {
    font-size: calc(5px + 0.1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_mega_small_bold {
    font-size: calc(5px + 0.2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_hiper_small_bold {
    font-size: calc(5px + 0.3vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_really_small_bold {
    font-size: calc(5px + 0.6vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_very_small_bold {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_mediumish_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_medium_bold {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_big_bold {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_very_big_bold {
    font-size: calc(5px + 2.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_ultra_small {
    font-size: calc(5px + 0.2vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_hiper_small {
    font-size: calc(5px + 0.3vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_super_small {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_very_small {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_small {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_medium_small {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_medium_small_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_medium {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_big {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_ultra_small_bold {
    font-size: calc(5px + 0.3vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_mega_small_bold {
    font-size: calc(5px + 0.4vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_really_small_bold {
    font-size: calc(5px + 0.6vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_very_small_bold {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_mediumish_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_medium_bold {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_big_bold {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_very_big_bold {
    font-size: calc(5px + 2.7vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_small {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_medium {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_big {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_super_small_bold {
    font-size: calc(5px + 0.45vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_really_small_bold {
    font-size: calc(5px + 0.6vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_very_small_bold {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_medium_bold {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_big_bold {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_hiper_small_bold {
    font-size: calc(5px + 0.35vw);
    font-family: "Causten";
    font-weight: bold;
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_very_small_bold {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    font-weight: bold;
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }



  .text_yellow_medium_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_small {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_super_small {
    font-size: calc(5px + 0.45vw);
    font-family: "Causten";
    color: #6ee6a7;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_very_small {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    font-weight: 550;
    color: #6ee6a7;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_normal_green_small {
    font-size: calc(5px + 0.7vw);
    font-family: "Causten";
    color: #22c36f;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_green_big_bold {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #22c36f;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_green_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #6ee6a7;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_green_very_small_bold {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    color: #59ba47;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_green_small {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #59ba47;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_small {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #6ee6a7;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_super_small_bold {
    font-size: calc(5px + 0.45vw);
    font-family: "Causten";
    color: #6ee6a7;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_very_small_bold {
    font-size: calc(5px + 0.8vw);
    font-family: "Causten";
    color: #6ee6a7;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #6ee6a7;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_medium_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    color: #6ee6a7;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_big_bold {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    color: #6ee6a7;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_blue_small_bold {
    font-size: calc(5px + 1.2vw);
    font-family: "Causten";
    color: #99c2ff;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_blue_very_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #99c2ff;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_blue_really_small_bold {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    color: #99c2ff;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_blue_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    color: #99c2ff;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_blue_hiper_small_bold {
    font-size: calc(5px + 0.35vw);
    font-family: "Causten";
    color: #99c2ff;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_orange_very_small_bold {
    font-size: calc(5px + 0.75vw);
    font-family: "Causten";
    color: #f16e25;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_orange_small {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #f16e25;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_super_small {
    font-size: calc(5px + 0.38vw);
    font-family: "Causten";
    color: #717579;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_really_small {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    color: #717579;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_very_small {
    font-size: calc(5px + 0.7vw);
    font-family: "Causten";
    color: #717579;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_small {
    font-size: calc(5px + 0.8vw);
    font-family: "Causten";
    color: #717579;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_medium {
    font-size: calc(5px + 1.2vw);
    font-family: "Causten";
    color: #717579;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    color: #717579;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_dark_grey_super_small {
    font-size: calc(5px + 0.38vw);
    font-family: "Causten";
    color: #3d4043;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_dark_grey_small {
    font-size: calc(5px + 0.8vw);
    font-family: "Causten";
    color: #3d4043;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_red_small {
    font-size: calc(5px + 0.8vw);
    font-family: "Causten";
    color: #f0684f;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_red_very_small_bold {
    font-size: calc(5px + 0.6vw);
    font-family: "Causten";
    color: #f0684f;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_red_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #f0684f;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_red_medium_bold {
    font-size: calc(5px + 1.2vw);
    font-family: "Causten";
    color: #f0684f;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_hiper_small_bold {
    font-size: calc(5px + 0.35vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_very_small_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_medium_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }


}

/* Tablet */

@media only screen and (max-width: 991px) {
  .image {
    position: relative;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .icon {
    position: relative;
    max-height: 60%;
    max-width: 60%;
  }

  .text_black_mega_small {
    font-size: calc(5px + 0.25vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_hiper_small {
    font-size: calc(5px + 0.35vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_super_small {
    font-size: calc(5px + 0.55vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_very_small {
    font-size: calc(5px + 0.8vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_small {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_medium {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_big {
    font-size: calc(5px + 2.5vw);
    font-family: "Causten";
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_ultra_small_bold {
    font-size: calc(5px + 0.1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_mega_small_bold {
    font-size: calc(5px + 0.3vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_hiper_small_bold {
    font-size: calc(5px + 0.7vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_super_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_very_small_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_small_bold {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_medium_bold {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_big_bold {
    font-size: calc(5px + 2.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_black_very_big_bold {
    font-size: calc(5px + 3vw);
    font-family: "Causten";
    font-weight: bold;
    color: #000000;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_very_small {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_small {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_medium {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_big {
    font-size: calc(5px + 2.5vw);
    font-family: "Causten";
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_ultra_small_bold {
    font-size: calc(5px + 0.3vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_mega_small_bold {
    font-size: calc(5px + 0.4vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_very_small_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_small_bold {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_medium_bold {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_big_bold {
    font-size: calc(5px + 2.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_white_very_big_bold {
    font-size: calc(5px + 3.2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #ffffff;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_small {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_medium {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_big {
    font-size: calc(5px + 2.5vw);
    font-family: "Causten";
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_super_small_bold {
    font-size: calc(5px + 0.45vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_small_bold {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_medium_bold {
    font-size: calc(5px + 2vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_big_bold {
    font-size: calc(5px + 2.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_very_small_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_yellow_small {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #fbc412;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_super_small {
    font-size: calc(5px + 0.45vw);
    font-family: "Causten";
    color: #6ee6a7;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_normal_green_small {
    font-size: calc(5px + 0.7vw);
    font-family: "Causten";
    color: #22c36f;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_green_small {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #59ba47;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_super_small_bold {
    font-size: calc(5px + 0.45vw);
    font-family: "Causten";
    color: #6ee6a7;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_green_very_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #6ee6a7;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_blue_small_bold {
    font-size: calc(5px + 1.2vw);
    font-family: "Causten";
    color: #99c2ff;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_blue_very_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    color: #99c2ff;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_orange_small {
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";
    color: #f16e25;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_small {
    font-size: calc(5px + 0.8vw);
    font-family: "Causten";
    color: #717579;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_medium {
    font-size: calc(5px + 1.2vw);
    font-family: "Causten";
    color: #717579;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_super_small {
    font-size: calc(5px + 0.38vw);
    font-family: "Causten";
    color: #717579;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_light_grey_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    color: #717579;
    font-weight: bold;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_dark_grey_super_small {
    font-size: calc(5px + 0.38vw);
    font-family: "Causten";
    color: #3d4043;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_dark_grey_small {
    font-size: calc(5px + 0.8vw);
    font-family: "Causten";
    color: #3d4043;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_super_small_bold {
    font-size: calc(5px + 0.5vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_very_small_bold {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_very_small {
    font-size: calc(5px + 1.25vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_grey_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #b9b9b9;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .text_blue_very_small_bold {
    font-size: calc(5px + 1vw);
    font-family: "Causten";
    font-weight: bold;
    color: #5090f0;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }
}