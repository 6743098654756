/* Ordenador */

@media only screen and (min-width: 992px) {
    .drag_drop_options {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .drag_drop_options_background {
        position: absolute;
        height: 17.5%;
        width: 60%;
        top: 70%;
        right: 4%;
        display: grid;
        z-index: 1;
    }

    .drag_drop_options_background_grid {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .drag_drop_options_background_column {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 90% 10%;
    }

    .drag_drop_options_background_column_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 50% 50%;
    }

    .drag_drop_options_background_column_right {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .drag_drop_options_background_column_right_line {
        position: relative;
        height: 100%;
        width: 50%;
        border-right: 2px dashed rgb(255, 255, 255);
    }

    .drag_drop_options_droppable {
        background-color: rgba(255,255,255,0.5);
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-items: center;
        gap: 1em;
        height: 50%;
        width: 100%;
        top: 50%;
        border-radius: 5px;
    }

    .drag_drop_options_draggable {
display: inline-block;
    min-width: 2em;
    min-height: 1.5em;
    font-size: 1em;
    color: black;
    background: rgba(244, 239, 227, 0.7);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 1px 5px;
    margin: 0px 2px;
    position: relative;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .drag_drop_options {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .drag_drop_options_background {
        position: absolute;
        height: 15%;
        width: 90%;
        top: 70%;
        left: 5%;
        display: grid;
        z-index: 1;
    }

    .drag_drop_options_background_grid {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .drag_drop_options_background_column {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 90% 10%;
    }

    .drag_drop_options_background_column_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 50% 50%;
    }

    .drag_drop_options_background_column_right {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .drag_drop_options_background_column_right_line {
        position: relative;
        height: 100%;
        width: 50%;
        border-right: 2px dashed rgb(255, 255, 255);
    }

    .drag_drop_options_droppable {
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50%;
        width: 100%;
        top: 50%;
        border-radius: 5px;
    }

    .drag_drop_options_draggable {
        display: inline-block;
        min-width: 45px;
        min-height: 25px;
        font-size: 1em;
        color: black;
        background: rgba(244,239,227,0.7);
        box-shadow: 2px 2px rgba(0,0,0,0.7);
        border-radius: 5px 5px 5px 5px;
        padding: 1px 5px 1px 5px;
        margin: 0px 2px 0px 2px;
        position: relative;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}