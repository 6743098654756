/* Ordenador */

@media only screen and (min-width: 992px) {

    .professor_marketplace {
        position: relative;
        height: 100vh;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;

    }

    .professor_marketplace_body {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 12% 8% 80%;
        box-sizing: border-box;
        padding: 2%;
    }

    .professor_marketplace_top {
        position: relative;
        display: flex;
        box-sizing: border-box;
        padding: 2%;

        background-image: url('https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Evolutivo+crear+situaciones/banner_libreria.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;

    }

    .professor_marketplace_title {
        position: relative;
        display: flex;
        align-items: center;
    }

    .professor_marketplace_search_filters_input {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 20% 60% 20%;
    }

    .professor_marketplace_search_filters_input_dropdowns {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_marketplace_container {
        position: relative;
        height: 98%;
        width: 100%;
        top: 2%;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        overflow-y: scroll;
    }

    .professor_marketplace_add_content {
        position: relative;
        height: 90%;
        width: 90%;
        top: 5%;
        left: 5%;
        background-color: #E2EEFF;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        box-sizing: border-box;
        padding: 2%;
    }

    .professor_marketplace_content {
        position: relative;
        height: 90%;
        width: 90%;
        top: 5%;
        left: 5%;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        display: grid;
        grid-template-rows: 40% 20% 20% 20%;
        box-sizing: border-box;
        padding-bottom: 2%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_marketplace_content_mid_name {
        position: relative;
        /* height: 100%; */
        width: 90%;
        left: 5%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;

        /* display: grid;
        grid-template-columns: 60% 40%; */
    }

    .professor_marketplace_content_mid_rest {
        position: relative;
        /* height: 100%; */
        width: 90%;
        left: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* padding-right: 5px; */
    }

    .professor_marketplace_content_mid_subject {
        position: relative;
        height: fit-content;
        width: auto;
        border-radius: 50px;
        padding-left: 1vw;
        padding-right: 1vw;
        box-sizing: border-box;
        white-space: nowrap;
    }

    .text_white_super_small_marketplace {
        font-size: calc(5px + 0.5vw);
        font-family: 'Causten';
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
    }

    .professor_marketplace_content_bot {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 2vh;
    }

    .professor_marketplace_content_bot_regulation {
        position: relative;
        width: fit-content;
        border-radius: 50px;
        padding-left: 1vh;
        padding-right: 1vh;
        white-space: nowrap;
    }

}

/* Tablet */

@media only screen and (max-width: 991px) {

    .professor_marketplace {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 5% 85%;
        box-sizing: border-box;
        padding: 2%;
    }

}