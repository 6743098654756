.professor_user_button {
    position: fixed;
    height: 5vh;
    top: 3vh;
    right: 2vw;
    z-index: 99;
}

.user_menu_background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 998;

}

.professor_user_menu {
    position: absolute;
    width: 36.5%;
    height: 55%;
    top: 22.5%;
    left: 31.75%;
    background-color: #ffffff;
    border: 1px solid #8DBCFF;
    border-radius: 10px;
}

.user_menu_close {
    position: absolute;
    width: 10%;
    height: 10%;
    top: 2%;
    right: 1%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.user_fullname {
    position: absolute;
    width: 50%;
    height: 10%;
    bottom: 40%;
    left: 25%;
}

.user_icon {
    position: absolute;
    height: 17vh;
    width: 17vh;
    border-radius: 50%;
    background-color: #E6C9FF;
}

.logout_button {
    position: absolute;
    padding: 4px;
    height: 12%;
    width: 60%;
    bottom: 8%;
    left: 20%;
    background-color: #F06D50;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    border-radius: 10px;
}

.user_menu_settings_button {
    position: absolute;
    padding: 4px;
    height: 12%;
    width: 60%;
    bottom: 24%;
    left: 20%;
    background-color: #D9D9D9;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    border-radius: 10px;
}