/* Ordenador */

@media only screen and (min-width: 992px) {
    .choose_many {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .choose_many_options {
        position: absolute;
        height: 9%;
        width: 60%;
        top: 75%;
        left: 35%;
        display: grid;
        z-index: 1;
    }

    .choose_many_option {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        z-index: 1;
    }

    .choose_many_option_pressed {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #2F80ED;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        z-index: 1;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .choose_many {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .choose_many_options {
        position: absolute;
        height: 6%;
        width: 90%;
        top: 78%;
        left: 5%;
        display: grid;
        z-index: 6;
    }

    .choose_many_option {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        z-index: 6;
    }

    .choose_many_option_pressed {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #2F80ED;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        z-index: 6;
    }
}