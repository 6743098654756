/* Ordenador */

@media only screen and (min-width: 992px) {
    .edit_definition {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .edit_definition_professor {
        position: absolute;
        height: 20%;
        width: 20%;
        bottom: 0;
        left: 5%;
    }

    .edit_definition_text_box {
        position: absolute;
        height: 48%;
        width: 28%;
        top: 15%;
        left: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 1;
    }

    .edit_definition_arrows {
        position: absolute;
        height: 10%;
        width: 10%;
        bottom: 1.5%;
        left: 40%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_definition_edit_definition_box {
        position: absolute;
        height: 60%;
        width: 60%;
        top: 15%;
        right: 2%;
        background-color: #FFF5DB;
        border-radius: 10px;
        z-index: 1;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .definition {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;

    }

    .edit_definition_text_box {
        position: absolute;
        height: 20%;
        width: 90%;
        top: 7%;
        left: 5%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 1;
    }

    .edit_definition_arrows {
        position: absolute;
        height: 12%;
        width: 20%;
        bottom: 0;
        left: 50%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_definition_edit_definition_box {
        position: absolute;
        height: 60%;
        width: 90%;
        top: 30%;
        left: 5%;
        background-color: #FFF5DB;
        border-radius: 10px;
        z-index: 1;
    }
}