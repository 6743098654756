.challenge_1_bubble {
  padding:2%;
  position: absolute;
  z-index: 9;
  display: grid;
  grid-template-rows: 85% 15%;
  background-color: white;
  bottom: 8%;
  left: 20%;
  height:20%;
  width: 50%;
  border-radius: 5px;
}

.challenge_1_professor {
  position: absolute;
  z-index: 9;
  bottom: 0;
  right: 0;
}

.challenge_1_button {
    position: absolute;
    z-index: 9;
    bottom: 5%;
    left: 40%;
    height: 5%;
    width: 15%;
    background-color: #F9D45F;
    border-radius: 30px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    box-shadow: 0px 0px 50px 0px #F9D45F;
    
}

p {
  margin-top:4%;
}

.challenge_1_mains_background {
  position: absolute;
  z-index: 5;
  top: 5vh;
  left: 15vw;
  height: 65vh;
  width: 70vw;
  display: grid;
  grid-template-rows: 1fr 4fr;
  background-color: #292F4C;
  border-radius: 10px;
}

.challenge_1_mains_header {
  position: relative;
  height: 100%;
  width: 94%;
  margin-left: 3%;
}

.challenge_1_mains_body {
  position: relative;
  height: 100%;
  width: 94%;
  margin-left: 3%;
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.challenge_1_mains_body_left {
  position: relative;
  height: 85%;
  width: 95%;
  background-color: #C9C8C8;
  border-radius: 5px;
}

.challenge_1_mains_body_left_box {
  position: relative;
  top: 7%;
  height: 86%;
  width: 100%;
  background-color: #D9D9D9;
}

.challenge_1_mains_body_left_all_switches {
  position: relative;
  top: 20%;
  left: 10%;
  height: 60%;
  width: 80%;
  background-color: #C9C8C8;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.challenge_1_mains_body_left_general_switch {
  position: relative;
  top: 4%;
  left: 4%;
  height: 92%;
  width: 97%;
  background-color: #D9D9D9;
  display: grid;
  grid-template-rows: 1fr 2fr;
}

.challenge_1_mains_body_left_room_switches {
  position: relative;
  top: 4%;
  left: 1.8%;
  height: 92%;
  width: 96.8%;
  background-color: #D9D9D9;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: 1fr 2fr;
}

.challenge_1_switch_general {
  position: relative;
  top: 4%;
  left: 1.8%;
  height: 92%;
  width: 96.8%;
}

.challenge_1_switch_orange_general {
  position: relative;
  top: 10%;
  left: 30%;
  height: 20%;
  width: 40%;
  background-color: #F0684F;
  z-index: 1;
}

.challenge_1_switch_rail_general {
  position: relative;
  top: 0%;
  left: 45%;
  height: 60%;
  width: 10%;
  background-color: #4F4F4F;
}

.challenge_1_switch {
  position: relative;
  top: 4%;
  left: 1.8%;
  height: 92%;
  width: 96.8%;
}

.challenge_1_switch_orange {
  position: relative;
  top: 10%;
  left: 25%;
  height: 20%;
  width: 50%;
  background-color: #F0684F;
  z-index: 1;
}

.challenge_1_switch_rail {
  position: relative;
  top: 0%;
  left: 43%;
  height: 60%;
  width: 14%;
  background-color: #4F4F4F;
}

.challenge_1_mains_body_right {
  position: relative;
  height: 85%;
  width: 95%;
  left: 5%;
  display: grid;
  grid-template-rows: 4fr 2fr;
}

.challenge_1_mains_body_right_watts {
  position: relative;
  height: 90%;
  width: 100%;
  background-color: #D9D9D9;
  border-radius: 5px;
}

.challenge_1_mains_body_right_watts_background {
  position: relative;
  height: 85%;
  width: 94%;
  top: 7.5%;
  left: 3%;
  background-color: #4F4F4F;
}

.challenge_1_mains_body_right_watts_measure {
  position: relative;
  top: 45%;
  left: 50%;
  transform-origin: bottom center;
  height: 75%;
  width: 70%;
  border-radius: 200vh 200vh 0 0;
  background-color: #E7E7E7;
  overflow: hidden;
}

.challenge_1_mains_body_right_watts_moving {
  position: absolute;
  bottom: 0%;
  right: -5%;
  height: 110%;
  width: 110%;
  transform-origin: bottom center;
  border-radius: 200vh 200vh 0 0;
}

.challenge_1_mains_body_right_watts_semi {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 46.67%;
  border-radius: 200vh 200vh 0 0;
  background-color: #4F4F4F;
}

.challenge_1_mains_body_right_watts_0 {
  position: absolute;
  bottom: 5%;
  left: 19.5%;
}

.challenge_1_mains_body_right_watts_3000 {
  position: absolute;
  bottom: 5%;
  right: 15%;
}

.challenge_1_mains_body_right_watts_units {
  position: absolute;
  bottom: 15%;
  right: 45%;
}

.challenge_1_mains_body_right_watts_number {
  position: absolute;
  bottom: 25%;
  right: 35%;
  height: 30%;
  width: 30%;
}

.challenge_1_mains_body_right_plate {
  position: relative;
  height: 90%;
  width: 100%;
  top: 10%;
  background-color: #D9D9D9;
  border-radius: 5px;
}

.challenge_1_mains_body_right_plate_screws {
  position: absolute;
  height: 1.5vh;
  width: 1.5vh;
  background-color: #4F4F4F;
  border-radius: 50%;
}