/* Ordenador */

@media only screen and (min-width: 992px) {
    .theory {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index:5;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .theory {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index:5;
    }
}