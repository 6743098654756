@font-face {
    font-family: 'Causten';
    src: local('Causten'), url(../../fonts/Causten/Causten-Regular.otf) format('opentype');
}

.professor_sidebar_background {
    grid-column: 1;
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #292F4C;
}

.professor_sidebar {

    position: relative;
    width: 99.5%;
    height: 100%;
    background-color: #E1EDFF;
    border-right: 1px solid #5090F0;
}

.professor_sidebar_top {
    width: 100%;
    height: 100%;
    display: grid;
}

.professor_sidebar_top>* {
    margin-left: 8%;
}

.professor_sidebar_create_class {
    position: relative;
    height: 100%;
    width: 84%;
    border-bottom: 1px solid #5090F0;
}

.professor_sidebar_create_class_button {
    width: 90%;
    border-radius: 5px;
    margin-top: 4%;
    padding: 3%;
    color: #5090F0;
    display: flex;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    user-select: none;

}

.professor_sidebar_create_class_icon {
    position: relative;
    max-height: 55%;
    max-width: 100%;
}

.professor_sidebar_create_class_text {
    position: relative;
    height: 100%;
    width: 95%;
    margin-left: 5%;
}

.professor_sidebar_bottom {
    position: relative;
    height: 96%;
    width: 100%;
    margin-top: 3%;
    margin-left: 0%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* overflow: visible; */
}

.professor_sidebar_class_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 4%;
    box-sizing: border-box;
    padding: 3%;
    user-select: none;
}

.professor_sidebar_class {
    border: solid 1px #5090F0;
    width: 90%;
    border-radius: 5px;
    padding: 3%;
    color: #5090F0;
    font-weight: 400;
    font-family: 'Causten';
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    user-select: none;
    margin-left: 2%;
}

.professor_sidebar_class_delete {
    position: relative;
    height: 3vh;
    margin-left: 1%;
    aspect-ratio: 1/1;
    /* background-color: #F0684F; */
    color: #5090F0;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.professor_sidebar_class_selected {
    position: absolute;
    background: #5090F0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.professor_sidebar_delete_menu_background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
}

.professor_sidebar_delete_menu_container {
    position: relative;
    height: 50%;
    width: 60%;
    border-radius: 10px;
    background-color: white;
    border: 2px solid #8DBCFF;
}

.professor_sidebar_delete_menu {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.professor_sidebar_delete_menu_text {
    position: relative;
    height: 70%;
    width: 100%;
    box-sizing: border-box;
    padding: 5%;
}

.professor_sidebar_delete_menu_close {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 2%;
    right: 1%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.professor_sidebar_delete_menu_buttons {
    position: absolute;
    width: 100%;
    height: 20%;
    bottom: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.professor_sidebar_delete_menu_button_yes {
    position: relative;
    height: 100%;
    width: 70%;
    left: 20%;
    background-color: #6EE6A7;
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}

.professor_sidebar_delete_menu_button_no {
    position: relative;
    height: 100%;
    width: 70%;
    left: 10%;
    background-color: #F06D50;
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}