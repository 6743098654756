.teacher_tutorial_4_1_background_black_bot {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 71%;
    top: 29%;
    left: 0;
}


.teacher_tutorial_4_1_background_black_top {

    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 17%;
    top: 0;
    left: 0;

}

.teacher_tutorial_4_1_background_black_variable_left {
    background-color: #00000088;
    position: absolute;
    height: 12%;
    top: 17%;
    width: 17.5%;
    left: 0;
}

.teacher_tutorial_4_1_background_black_variable_right {
    background-color: #00000088;
    position: absolute;
    height: 12%;
    top: 17%;
    width: 66%;
    right: 0;
}


.teacher_tutorial_4_2_background_black_bot {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 71%;
    top: 29%;
    left: 0;
}


.teacher_tutorial_4_2_background_black_top {

    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 17%;
    top: 0;
    left: 0;

}

.teacher_tutorial_4_2_background_black_variable_left {
    background-color: #00000088;
    position: absolute;
    height: 12%;
    top: 17%;
    width: 34%;
    left: 0;
}

.teacher_tutorial_4_2_background_black_variable_right {
    background-color: #00000088;
    position: absolute;
    height: 12%;
    top: 17%;
    width: 2%;
    right: 0;
}



.teacher_tutorial_4_3_background_black_bot {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 61%;
    top: 39%;
    left: 0;
}


.teacher_tutorial_4_3_background_black_top {

    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 29.5%;
    top: 0;
    left: 0;

}

.teacher_tutorial_4_3_background_black_variable_left {
    background-color: #00000088;
    position: absolute;
    height: 9.5%;
    top: 29.5%;
    width: 17.5%;
    left: 0;
}

.teacher_tutorial_4_3_background_black_variable_right {
    background-color: #00000088;
    position: absolute;
    height: 9.5%;
    top: 29.5%;
    width: 2%;
    right: 0;
}

.teacher_tutorial_4_1_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 38%;
    margin-left: 10%;
    margin-bottom: 5%;
    height: fit-content;
}

.teacher_tutorial_4_2_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 38%;
    margin-left: 15%;
    margin-bottom: 5%;
    height: fit-content;
}

.teacher_tutorial_4_3_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 38%;
    margin-top: 5%;
    height: fit-content;
}