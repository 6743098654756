.teacher_tutorial_2_2_background_black_bot {
    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 30%;
    top: 70%;
    left: 0;
}

.teacher_tutorial_2_2_background_black_top {

    background-color: #00000088;
    position: absolute;
    width: 100%;
    height: 20%;
    top: 0;
    left: 0;

}

.teacher_tutorial_2_2_background_black_variable_left {
    background-color: #00000088;
    position: absolute;
    height: 50%;
    top: 20%;
    left: 0;
}

.teacher_tutorial_2_2_background_black_variable_right {
    background-color: #00000088;
    position: absolute;
    height: 50%;
    top: 20%;
    right: 0;
}

.teacher_tutorial_2_2_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 40%;
    margin-right: 28%;
    margin-bottom: 5%;
    height: fit-content;
}

.teacher_tutorial_2_1_container {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 38%;
    margin-left: 55%;
    margin-bottom: 5%;
    height: fit-content;
}