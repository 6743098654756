.stats_general_container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.stats_top_buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 15%;
    width: 60%;
    margin-left: 20%;
    justify-content: center;
    align-items: center;
}

.stats_button {
    padding: 10px 20px;
    border: 2px solid #459bdc;
    background-color: #ebeff7;
    border-radius: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.stats_middle_container {
    position: relative;
    height: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10vh;
}

.stats_dropdown_left {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;

}

.stats_dropdown_right {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.stats_middle_dates_pickers {
    position: relative;
    display: flex;
    flex-direction: row;
}

.stats_graph_container {
    position: relative;
    height: 80%;
    width: 100%;
}

.no_data_text {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 2em;
    color: #459bdc;
}

.stats_completion_rate {
    position: absolute;
    top: 4em;
    right: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
}