.mup_back_image{
  height:100%;
  width:100%;
  z-index: 0;
  background-image: url('https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Background.png');
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mup_canvas {
  aspect-ratio: 16 / 9;
  position: absolute;
  z-index: 0;
  object-fit: contain;
  object-position: center;
}

.mup_image_container {
  width: 100%;
  height: 100%;
  position: absolute;

  overflow: hidden;
}

.mup_image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  object-position: center;
  z-index: 1;
}

.mup_buttons_container {
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mup_campus_button {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  position: absolute;
  left: 38%;
  top: 38%;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_energy_lab_button {
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  position: absolute;
  left: 48%;
  bottom: 10%;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_workshop_button {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  position: absolute;
  left: 10%;
  bottom: 14%;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_balloon_button {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  position: absolute;
  right: 19%;
  top: 14%;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_my_home_button {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  position: absolute;
  right: 32%;
  top: 5%;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_grassland_button {
  width: 4.5vw;
  height: 5vw;
  top: 18%;
  right: 48.5%;
  z-index: 99;
  position: absolute;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}

.mup_desert_button {
  width: 4.5vw;
  height: 5vw;
  right: 63.5%;
  top: 32%;
  z-index: 99;
  position: absolute;
  /* background-color: red; */
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_forest_button {
  width: 4.5vw;
  height: 5vw;
  right: 34%;
  top: 47.5%;
  position: absolute;
  z-index: 99;

  /* background-color: blue; */
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_jungle_button {
  width: 4.5vw;
  height: 5vw;
  top: 70%;
  right: 42.5%;
  position: absolute;
  z-index: 99;

  /* background-color: green; */
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_savannah_button {
  width: 4.5vw;
  height: 5vw;
  top: 24.5%;
  right: 34%;
  position: absolute;
  /* background-color: yellow; */
  z-index: 99;

  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_mountain_button {
  width: 4.5vw;
  height: 5vw;
  right: 46%;
  top: 40%;
  z-index: 99;
  position: absolute;
  /* background-color: gray; */
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_beach_button {
  width: 4.5vw;
  height: 5vw;
  right: 46.5%;
  top: 56.5%;
  z-index: 99;
  position: absolute;
  /* background-color: orange; */
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_swamp_button {
  width: 5vw;
  height: 4.5vw;
  right: 85.5%;
  top: 50%;
  z-index: 99;
  position: absolute;
  /* background-color: purple; */
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_snow_button {
  width: 4.5vw;
  height: 5vw;
  top: 37%;
  right: 55%;
  z-index: 99;
  position: absolute;
  /* background-color: pink; */
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_volcano_button {
  width: 5vw;
  height: 4.75vw;
  right: 21%;
  top: 28.5%;
  position: absolute;
  z-index: 99;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.mup_list_container {
  position: absolute;
  width: 9vw;
  height: auto;
  top: 17.5%;
  right: 3%;
  z-index: 99;
}

.mup_list_names {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 50% 5%;
  box-sizing: border-box;
  z-index: 99;
}

.mup_list_names_container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow-y: scroll;
}