.alert_menu_background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
}

.alert_menu_container {
    position: relative;
    height: 50%;
    width: 60%;
    border-radius: 10px;
    background-color: white;

}

.alert_menu {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.alert_menu_text {
    position: relative;
    height: 70%;
    width: 100%;
    box-sizing: border-box;
    padding: 5%;
}

.alert_menu_close {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 2%;
    right: 1%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.alert_menu_buttons {
    position: absolute;
    width: 100%;
    height: 20%;
    bottom: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.alert_menu_button_ok {
    position: relative;
    height: 100%;
    width: 70%;
    left: 50%;
    background-color: #6EE6A7;
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}