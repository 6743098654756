/* Ordenador */

@media only screen and (min-width: 992px) {
    .drag_drop_arrows {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
    }

    .drag_drop_arrows_background {
        position: absolute;
        height: 78%;
        width: 56%;
        top: 7%;
        right: 7%;
        display: grid;
        grid-template-rows: 70% 30%;
        z-index: 1;
    }

    .drag_drop_arrows_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 45% 10% 45%;
    }

    .drag_drop_arrows_middle {
        position: absolute;
        height: 100%;
        width: 10%;
        left: 45%;
        top: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .drag_drop_arrows_middle_containers {
        position: relative;
        width: 90%;
        height: 16.5%;
    }

    .drag_drop_arrows_droppable_0 {
        position: absolute;
        height: 100%;
        width: 45%;
        border-radius: 10px;
    }


    .draggable_background_color {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 12%;
        z-index: -1;
    }

    .draggable_color {
        position: relative;
        width: 90%;
        height: 12.5%;
        margin-left: 5%;
        top: 2%;
        box-shadow: 0px 0px 3px 1px #e8e8e8;
        border-radius: 5px;
    }

    .drag_drop_arrows_droppable_1 {
        position: absolute;
        height: 100%;
        width: 45%;
        left: 55%;
        border-radius: 10px;
    }

    .drag_drop_arrows_label {
        position: relative;
        height: 15%;
        width: 100%;
    }

    .drag_drop_arrows_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
    }

    .drag_drop_arrows_draggable {
        position: relative;
        width: 90%;
        height: 12.5%;
        left: 5%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

    }

    .drag_drop_arrows_draggable_bottom {
        position: relative;
        width: 95%;
        height: 30%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        margin-left: 5%;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .drag_drop_arrows {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .drag_drop_arrows_background {
        position: absolute;
        height: 58%;
        width: 88.5%;
        top: 29%;
        left: 5%;
        display: grid;
        grid-template-rows: 70% 30%;
        z-index: 1;
    }

    .drag_drop_arrows_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .drag_drop_arrows_droppable_0 {
        position: absolute;
        height: 100%;
        width: 48%;
        background-color: #6EE6A7;
        border-radius: 10px;
    }

    .drag_drop_arrows_droppable_1 {
        position: absolute;
        height: 100%;
        width: 48%;
        left: 54%;
        background-color: #F0684F;
        border-radius: 10px;
    }

    .drag_drop_arrows_label {
        position: relative;
        height: 12%;
        width: 100%;
    }

    .drag_drop_arrows_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
    }

    .drag_drop_arrows_draggable {
        position: relative;
        width: 90%;
        height: 12.5%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        margin-left: 5%;
    }

    .drag_drop_arrows_draggable_bottom {
        position: relative;
        width: 95%;
        height: 30%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        margin-left: 5%;
    }
}