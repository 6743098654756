/* Ordenador */

@media only screen and (min-width: 992px) {

    .professor_activities_scenario_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 10% 80%;
    }

    .professor_activities_scenario_menu_activity {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 90%;
    }

    .professor_activities_scenario_menu_top_calendar {
        position: relative;
        height: 70%;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 5px;
        background: #E2EEFF;
        text-decoration: underline solid #5090F0 1px;
    }

    .professor_activities_scenario_menu_main {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 1%;
        overflow-y: scroll;
    }

    .professor_activities_scenario_menu_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        box-sizing: border-box;
        left: 0%;
        background: #FFF;
        grid-template-columns: 55% 45%;
        border-bottom: #5090F0 solid 1px;
    }

    .professor_activities_scenario_menu_mid {
        position: relative;
        height: 100%;
        width: 100%;
        left: 0%;
        display: flex;
        box-sizing: border-box;
        justify-content: start;
        border-bottom: #5090F0 solid 1px;
        align-items: center;
    }

    .professor_activities_scenario_menu_top_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_activities_scenario_menu_top_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_activities_scenario_menu_top_left_name {
        position: relative;
        display: flex;
        align-items: center;
    }

    .professor_activities_scenario_menu_top_left_name_dates {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .professor_activities_scenario_menu_top_left_activities {
        position: relative;
        margin-left: 2%;
    }

    .professor_activities_scenario_menu_top_left_course {
        position: relative;
        border-radius: 50px;
        padding-left: 1%;
        padding-right: 1%;
        margin-left: 2%;
    }

    .professor_activities_scenario_menu_top_left_age {
        position: relative;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 50px;
        background: #5090F0;
        margin-left: 2%;
    }

    .professor_activities_scenario_menu_top_left_lang {
        position: relative;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 50px;
        background: black;
        margin-left: 2%;
    }

    .professor_activities_scenario_menu_top_right_type {
        position: relative;
        margin-right: 2%;
    }

    .professor_activities_scenario_menu_top_right_dates {
        position: relative;
        margin-right: 2%;
    }

    .professor_activities_scenario_menu_top_right_owner {
        position: relative;
        margin-right: 4%;
        display: flex;
    }

    .professor_activities_scenario_menu_mid_group_item {
        display: "flex";
        width: "100%";
        flex-direction: "row";
        justify-content: "space-between";
        gap: "1vh";
        align-items: "center";
        white-space: "nowrap";
    }

    .professor_activities_scenario_menu_main_scenarios {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .professor_activities_scenario_menu_main_scenario {
        position: relative;
        height: auto;
        width: 100%;
        border-radius: 10px;
        background: #FAFAFA;
    }

    .professor_activities_scenario_menu_main_scenario_closed {
        height: auto;
        width: auto;
        display: flex;
    }

    .professor_activities_scenario_menu_main_scenario_info {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_activities_scenario_menu_main_scenario_info_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_activities_scenario_menu_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        padding-top: 2%;
    }

    .professor_activities_scenario_menu_bottom_right_background {
        position: relative;
        height: fit-content;
        width: 50%;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 10px;
        background: #E2EEFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .professor_activities_scenario_menu_bottom_right_both_numbers {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .professor_activities_scenario_menu_bottom_right_number {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .professor_activities_scenario_menu_main_scenario_open {
        height: auto;
        width: auto;
        box-sizing: border-box;
        padding: 1%;
        display: grid;
        grid-template-rows: 1fr 2fr;
        grid-template-columns: 50% 50%;
    }

    .professor_activities_scenario_menu_main_scenario_open_top_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_activities_scenario_menu_main_scenario_open_top_left_info {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_activities_scenario_menu_main_scenario_open_top_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        padding-right: 2%;
        box-sizing: border-box;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_left_image_container {
        position: relative;
        width: fit-content;
        max-width: 96%;
        height: fit-content;
        box-sizing: border-box;
        border-radius: 5px;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_left_image_container_hover {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 10;
        border-radius: 5px;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_right_top {
        position: relative;
        height: 100%;
        max-height: 15vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        box-sizing: border-box;
        padding: 0% 0% 1% 0%;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_right_top_eval {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_right_bottom {
        position: relative;
        height: inherit;
        width: 100%;
        flex: 1;
        display: grid;
        grid-template-rows: 1fr 1fr;
        box-sizing: border-box;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_right_bottom_spec {
        position: relative;
        height: inherit;
        width: 100%;
        display: grid;
        box-sizing: border-box;
        margin-bottom: 0.8em;
    }

    .professor_activities_scenario_menu_main_scenario_open_bottom_right_bottom_key {
        position: relative;
        height: inherit;
        width: 100%;
        display: grid;
        box-sizing: border-box;
    }

    .professor_scenario_menu_buttons_container {
        position: fixed;
        height: fit-content;
        width: 81%;
        bottom: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        z-index: 5;
        gap: 1vw;
    }


    .professor_scenario_menu_add_bundle_button {
        position: relative;
        height: fit-content;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 50px;
        background: #6EE6A7;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_scenario_menu_edit_change_bundle_button {
        position: relative;
        height: fit-content;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 50px;
        background: #5090F0;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_scenario_menu_change_bundle_button {
        position: relative;
        height: fit-content;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 50px;
        /* background: #6EE6A7; */
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_scenario_menu_delete_bundle_button {
        position: relative;
        height: fit-content;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 50px;
        background: #F06D50;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .scenario_edit_date_picker {
        height: 4vh;
        width: 80%;
        background-color: #EEEEEE;
        border-radius: 5px;
        font-family: 'Causten';
        text-align: center;
        color: black;
        margin-bottom: "2%";
    }

    .scenario_back_pop_up_container {
        position: relative;
        height: 37.5%;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        background: #E2EEFF;
        border-radius: 10px;
    }

    .scenario_back_duplicate_menu_container {
        position: relative;
        height: 35%;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        background: #E2EEFF;
        border-radius: 10px;
    }

    .scenario_duplicate_menu_main_text {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .scenario_duplicate_menu_button_cancel {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #D9D9D9;
        border-radius: 25px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .scenario_duplicate_menu_button_duplicate {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #6EE6A7;
        border-radius: 25px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .scenario_duplicate_menu_buttons {
        position: absolute;
        width: 65%;
        height: 20%;
        bottom: 5%;
        left: 17.5%;
        display: grid;
        gap: 5vh;
        grid-template-columns: 1fr 1fr;
    }

    .scenario_pop_up_main_container_grid {
        position: relative;
        height: 90%;
        width: 100%;
        box-sizing: border-box;
        padding: 2.5%;
    }

    .scenario_pop_up_main_content {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 1vh;
    }

    .scenario_pop_up_button {
        position: relative;
        height: 90%;
        width: 50%;
        background-color: #5090F0;
        border-radius: 25px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {

    .professor_activities_scenario_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
        box-sizing: border-box;
        padding: 2%;
    }

}