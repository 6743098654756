.explorers_hut {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 20% 80%;
    z-index: 5;
}

.explorers_hut_banner_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: hidden;
    margin: 0;
}

.explorers_hut_background {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
}

.explorers_hut_background_image {
    position: absolute;
    width: 97%;
    height: 75%;
    left: 1.5%;
    bottom: 2.5%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.explorers_hut_background_image_filter {
    position: absolute;
    width: 97%;
    height: 75%;
    left: 1.5%;
    bottom: 2.5%;
    background-color: white;
    opacity: 0.5;
    border-radius: 5px;
}

.explorers_hut_close_button {
    position: absolute;
    width: 3%;
    height: 6%;
    right: 4%;
    top: 12.5%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.explorers_hut_title {
    position: absolute;
    width: 50%;
    height: 7%;
    left: 5%;
    top: 11.5%;
}

.explorers_hut_biome_swamp {
    position: absolute;
    width: 22.6vw;
    height: 4.1vh;
    left: 6.56vw;
    top: 31.76vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_forest {
    position: absolute;
    width: 21.56vw;
    height: 4.1vh;
    left: 48.44vw;
    top: 71.57vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_desert {
    position: absolute;
    width: 23vw;
    height: 4.1vh;
    left: 26.56vw;
    top: 40.37vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_snow {
    position: absolute;
    width: 21.5vw;
    height: 4.1vh;
    left: 23.75vw;
    top: 55.74vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_volcano {
    position: absolute;
    width: 23.65vw;
    height: 4.1vh;
    left: 69.95vw;
    top: 63.61vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_mountain {
    position: absolute;
    width: 23.2vw;
    height: 4.1vh;
    left: 47.45vw;
    top: 55.74vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_beach {
    position: absolute;
    width: 21.3vw;
    height: 4.1vh;
    left: 25.42vw;
    top: 69.63vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_savannah {
    position: absolute;
    width: 23vw;
    height: 4.1vh;
    left: 45.21vw;
    top: 46.11vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_jungle {
    position: absolute;
    width: 21.46vw;
    height: 4.1vh;
    left: 9vw;
    top: 78vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_grassland {
    position: absolute;
    width: 24vw;
    height: 4.1vh;
    left: 49.74vw;
    top: 31.76vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(41, 47, 76, 0.75);
    border-radius: 10px;
}

.explorers_hut_biome_icon_name {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width: fit-content;
}

.explorers_hut_biome_health {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width: fit-content;
}

.explorers_hut_biome_points {
    display: flex;
    align-items: center;
    width: fit-content;
}

.explorers_hut_biome_info {
    position: absolute;
    width: 97%;
    height: 75%;
    left: 1.5%;
    bottom: 2.5%;
    /* background-color: red; */
}

.explorers_hut_biome_info_name {
    position: absolute;
    width: fit-content;
    height: 7vh;
    top: 2vh;
    left: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
    background-color: rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    padding-right: 1.5vw;
    border-radius: 5px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}

.explorers_hut_biome_info_items {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 35% 65%;
}

.explorers_hut_biome_info_graph_button {
    position: relative;
    width: 100%;
    height: 88%;
    top: 12%;
    display: grid;
    grid-template-rows: 70% 30%;
    box-sizing: border-box;
    padding: 1%;
}

.explorers_hut_biome_info_button {
    position: relative;
    width: 70%;
    height: 40%;
    top: 10%;
    left: 15%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6EE6A7;
    box-shadow: 0px 0px 20px 0px #6EE6A7;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.explorers_hut_biome_info_items_list {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 1%;
}

.explorers_hut_biome_info_item {
    position: relative;
    width: 85%;
    height: 18%;
    display: flex;
    gap: 1%;
    align-items: center;
    box-sizing: border-box;
    padding: 1%;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
}

.explorers_hut_biome_info_item_text {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width: fit-content;
    font-size: calc(5px + 1.5vw);
    font-family: "Causten";

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.explorers_hut_biome_info_item_chance {
    position: absolute;
    width: 17%;
    height: 60%;
    top: 20%;
    right: 3%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.explorers_hut_explorers {
    position: absolute;
    width: 97%;
    height: 75%;
    left: 1.5%;
    bottom: 2.5%;
}

.explorers_hut_explorers_items {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.explorers_hut_explorers_container {
    position: relative;
    height: 55vh;
    width: 90%;
    margin-top: 17.5%;
    display: grid;
    grid-template-rows: 50% 15% 15% 20%;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 3%;
}

.explorers_hut_explorers_send_button {
    width: 50%;
    height: 55%;
    border-radius: 5px;
    background-color: white;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}


.explorers_hut_explorer_info_menu {
    position: absolute;
    width: 97%;
    height: 75%;
    left: 1.5%;
    bottom: 2.5%;
}

.explorers_hut_explorer_info_menu_title {
    position: relative;
    width: 100%;
    height: 12%;
    margin-top: 1vh;
}

.explorers_hut_explorer_info_menu_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.explorers_hut_explorer_info_menu_middle {
    position: relative;
    width: 100%;
    height: 70%;
    display: flex;
    /* background-color: red; */
}

.explorers_hut_explorer_info_menu_middle_items {
    position: relative;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 1%;
}

.explorers_hut_explorer_info_menu_middle_extra_icon {
    position: absolute;
    top: 67.5%;
    left: 40%;
}

.explorers_hut_explorer_info_menu_middle_hearts {
    position: relative;
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.explorers_hut_explorer_info_menu_middle_image {
    position: relative;
    width: 30%;
    height: 100%;
    box-sizing: border-box;
    padding: 1%;
    margin-right: 3%;
}

.explorers_hut_explorer_info_menu_bottom {
    position: relative;
    width: 100%;
    height: 15%;
    /* background-color: red; */
}

.explorers_hut_explorer_info_menu_item_text {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width: fit-content;
    font-size: calc(5px + 1vw);
    font-family: "Causten";

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.explorers_hut_biome_info_menu_button {
    position: relative;
    width: 20%;
    height: 55%;
    top: 10%;
    left: 40%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6EE6A7;
    box-shadow: 0px 0px 20px 0px #6EE6A7;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.explorers_hut_item_obtained_background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.explorers_hut_item_obtained_container {
    position: relative;
    height: 60%;
    /* width: 30%; */
    width: 40%;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    border: 2px solid #6EE6A7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1vh;
    align-items: center;
    box-sizing: border-box;
    padding-top: 5%;
    padding-bottom: 10%;

}


.explorers_hut_item_obtained_close {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 2%;
    right: 1%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.explorers_hut_item_obtained_buttons {
    position: absolute;
    width: 60%;
    height: 15%;
    bottom: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.explorers_hut_item_obtained_button_ok {
    position: relative;
    height: 100%;
    width: 100%;
    left: 50%;
    background-color: #6EE6A7;
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}