@font-face {
    font-family: 'Causten';
    src: local('Causten'), url(../../fonts/Causten/Causten-Regular.otf) format('opentype');
}

.main_container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 20fr 1fr;
    grid-template-areas:
        "main"
        "footer"
    ;
}

.main_area_container {
    position: relative;
    width: 100%;
    height: 100%;
    grid-area: main;
    display: grid;
    background: linear-gradient(90deg, rgba(80, 144, 240, 1), rgba(178, 255, 232, 1));
    grid-template-columns: 1fr 1fr;
}

.landing_page_logo_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.detail_box {
    position: relative;
    width: 90%;
    height: 40%;
    justify-content: center;
    align-items: center;
}

.landing_page_image {
    position: relative;
    max-height: 100%;
    max-width: 80%;
    object-fit: cover;
}

.footer_container {
    position: relative;
    width: 50%;
    top: 10%;
    height: 90%;
    grid-area: footer;
    background: white;
    left: 25%;
}

.information_detail {
    position: relative;
    top: 5%;
    width: 100%;
    font-size: calc(10px + 1.6vw);
    font-family: 'Causten';
    font-weight: bold;
    text-align: center;
    color: white;
}

.try_eutopia_container {
    position: absolute;
    width: 60%;
    bottom: 5vh;
    left: 20%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.option_box {
    position: relative;
    /* top:30%; */
    border-radius: 10px;
    background-color: white;
    width: 70%;
    /* left: 15%; */
    height: fit-content;
    z-index: 1;
}

.initial_container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5%;
}

.teacher_container {
    height: 100%;
    width: 100%;
    grid-area: professor;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.seperator_container {
    position: relative;
    height: 50%;
    top: 50%;
    width: 90%;
    left: 5%;
    text-align: center;
    grid-area: seperator;
    border-top: solid 2px rgba(188, 209, 239, 1);

}

.student_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-area: student;
}

.teacher_main_container {
    width: 100%;
    display: grid;
    grid-template-rows: 15% 85%;
    grid-template-areas:
        "title_login"
        "form_login"
    ;
    box-sizing: border-box;
    padding: 2%;
}

.title_teacher {
    position: relative;
    width: fit-content;
    height: 100%;
    left: 3.5%;
    grid-area: title_login;
    display: flex;
    align-items: center;
}

.form_box_teacher {
    grid-area: form_login;
    position: relative;
    width: 90%;
    height: 100%;
    left: 5%;
}

.register_button_teacher {
    position: relative;
    grid-area: register_login;
    width: 100%;
    text-align: center;
    top: 40%;
}

.register_button_student {
    position: relative;
    grid-area: register_login;
    width: 100%;
    text-align: center;
    top: 45%;
}

.student_register_main_container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 3%;
    box-sizing: border-box;
}

.student_register_title {
    position: relative;
    width: 100%;
    height: 100%;
}

.student_register_title h1 {
    margin-top: -4%;
    display: block;
    font-size: 50px;
    font-family: 'Causten';
    font-weight: bold;
}

.student_register_title h2 {
    display: block;
    font-size: 20px;
    font-family: 'Causten';
    font-weight: 100;
    margin-top: 2%;
    color: rgba(188, 209, 239, 1);
}

.seperator_container_student_register {
    position: relative;
    width: 98%;
    top: 15%;
    text-align: center;
    border-top: solid 2px rgba(188, 209, 239, 1);
}

.student_register_box {
    top: 12%;
    position: relative;
    grid-area: box_student;
    width: 100%;
    height: 90%;
}

.student_register_box h2 {
    display: block;
    font-size: 14px;
    font-family: 'Causten';
    font-weight: 100;
    padding: 1.5%;
    color: black;
    font-weight: 300;
}

.button_register {
    text-align: center;
    margin-top: 2%;
}

.student-password-container {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
}

.student-password-element {
    border-radius: 5px;
    padding: 5px;
    box-shadow: 3px 3px 5px gray;
    font-size: 3em;
    margin-left: 1%;
    margin-right: 1%;

    width: 3vw;
    height: 3vw;
}

.password_container {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: larger;
    font-weight: bolder;
}

.close_container {
    position: absolute;
    top: 1%;
    right: 5%;
    width: 5%;
    height: 5%;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.maintenance_warning_container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.maintenance_warning_box {
    position: relative;
    width: 60%;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}