.settings_background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    top: 0;
    left: 0;
}

.settings_main {
    position: fixed;
    height: 50vh;
    width: 40vw;
    top: 25vh;
    left: 30vw;
    display: grid;
    grid-template-rows: 1fr 3fr;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #8DBCFF;
}

.settings_header {
    position: relative;
    border-bottom: 1px solid #5090F0;
    margin-left: 1vw;
    margin-right: 1vw;
}

.settings_body {
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
}

.settings_close {
    position: absolute;
    width: 15%;
    height: 15%;
    top: 4%;
    right: 1%;
    z-index: 1;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}

.settings_graphics {
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1.5fr;
}

.settings_cursor {
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1.5fr;
}

.settings_music {
    position: relative;
    display: grid;
    width: 85%;
    height: 100%;
    grid-template-rows: 1fr 1.5fr;
}

.settings_sounds {
    position: relative;
    display: grid;
    width: 85%;
    height: 100%;
    grid-template-rows: 1fr 1.5fr;
}