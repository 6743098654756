.global_bar_background {
    position: absolute;
    bottom: 3vh;
    width: 87.5vw;
    height: 4.6vh;
    left: 3vw;
    background-color: rgb(41, 47, 76, 75%);
    border-radius: 5px;
    z-index: 99;

}

.global_bar_progress {
    position: absolute;
    height: 100%;
    border-radius: 5px;
    background-color: rgb(216, 216, 216, 75%);

}

.global_bar_text {
    position: absolute;
    width: 80%;
    height: 100%;
    left: 10%;
}

.global_bar_right {
    grid-area: right_info;
    color: white;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.global_bar_center {
    grid-area: center_info;
    color: white;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}