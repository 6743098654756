/* HelpMenu */

.help_menu {
    position: absolute;
    height: 100%;
    width: 100%;
}

.exercise_help_button {
    position: absolute;
    height: 5vh;
    width: 18vw;
    display: grid;
    grid-template-columns: 5fr 1fr;
    bottom: 1vh;
    right: 5vw;
    z-index: 6;
}

.exercise_help_button_text {
    position: relative;
    height: 90%;
    width: 90%;
    background-color: white;
    color: #575757;
    font-weight: bold;
    border-radius: 5px;
}

.exercise_help_background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.exercise_help_menu {
    position: absolute;
    height: 50%;
    width: 30%;
    top: 20%;
    left: 35%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
    z-index: 2;
}

/* TextBox */

.exercise_text_box {
    position: absolute;
    height: 48%;
    width: 28%;
    top: 10%;
    left: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 1%;
    z-index: 1;
}

/* Arrows */

.exercise_arrows {
    position: absolute;
    height: 10%;
    width: 10%;
    bottom: 1.5%;
    left: 40%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    z-index: 1;
}

/* Confirm */

.exercise_confirm {
    position: absolute;
    height: 9%;
    width: 12%;
    bottom: 2%;
    left: 55%;
    background-color: #2F80ED;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    border-radius: 10px;
    z-index: 1;
}

/* Professor */

.exercise_professor {
    position: absolute;
    height: 45%;
    width: 25%;
    bottom: 0;
    z-index: 1;
}

/* Image */

.exercise_image {
    position: absolute;
    height: 55%;
    width: 50%;
    top: 10%;
    right: 10%;
    z-index: 1;
}

/* MainTextBox */

.exercise_main_text_box {
    position: absolute;
    height: 60%;
    width: 60%;
    top: 7%;
    right: 5%;
    background-color: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 1%;
    z-index: 1;
}

.exercise_definition_text_box {
    position: absolute;
    height: 63%;
    width: 60%;
    top: 7%;
    right: 5%;
    background-color: #FFF5DB;
    border-radius: 10px;
    z-index: 1;
}

/* ExerciseName */

.exercise_name {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 69.5%;
    right: 0;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_choosemany {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 62%;
    right: 12.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_choosemany_w_image {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 66%;
    right: 12.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_chooseone {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 62%;
    right: 12.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_chooseone_w_image {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 66%;
    right: 12.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_dragdropoptions {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 66%;
    right: 2.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_truefalse {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 62%;
    right: 12.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_truefalse_w_image {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 66%;
    right: 2.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_fillinthegaps {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 66%;
    right: 2%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_writeanswers {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 60%;
    right: 7.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_writeanswers_w_image {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 60%;
    right: 7.5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_dragdroparrows {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 52.5%;
    right: 5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_dragdroplist {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 69.5%;
    right: 2%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_dragdroptwolists {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 62%;
    right: 5%;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_definition {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 69.5%;
    right: 0;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_media {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 69.5%;
    right: 0;
    /* left: 2%; */
    z-index: 1;
}

.exercise_name_theory {
    position: absolute;
    height: 10%;
    width: 40%;
    top: 66%;
    right: 10%;
    /* left: 2%; */
    z-index: 1;
}