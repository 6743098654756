.tutorial_bubble {
  padding: 2%;
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
  top: 12%;
  left: 25%;
  min-height: 50%;
  height: fit-content;
  width: 60%;
  border-radius: 5px;
}

.tutorial_professor {
  position: absolute;
  z-index: 99;
  height: 60%;
  bottom: 0;
  left: 0;
}

.tutorial_sanny {
  position: absolute;
  z-index: 99;
  height: 50%;
  bottom: 0;
  right: 0;
}

.tutorial_button {
    position: relative;
    margin-top: 2%;
    bottom: 0;
    left: 15%;
    height: 5vh;
    width: 70%;
    background-color: #F9D45F;
    border-radius: 30px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    box-shadow: 0px 0px 50px 0px #F9D45F; 
}

.tutorial_confirm_button {
  position: relative;
  height: 100%;
  width: 70%;
  background-color: #6EE6A7;
  border-radius: 10px;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
  box-shadow: 0px 0px 50px 0px #6EE6A7; 
}

.tutorial_reject_button {
  position: relative;
  height: 100%;
  width: 70%;
  background-color: #F0684F;
  border-radius: 10px;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
  box-shadow: 0px 0px 50px 0px #F0684F; 
}

.tutorial_confirm_buttons {
  position: relative;
  bottom: 10%;
  height: 5vh;
  margin-left: 15%;
  width: 70%;
  display: grid;
  grid-template-columns: 50% 50%;
}