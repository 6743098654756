/* Ordenador */

@media only screen and (min-width: 992px) {
    .exercises_research_bar {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .exercises_research_bar_levels {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .exercises_research_bar {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .exercises_research_bar_levels {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
    }
}