/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_evaluation_specific_competences  {
        position: relative;
        height: 97%;
        width: 100%;
        top: 3%;
        display: grid;
        grid-template-columns: 50% 50%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
    }

    .professor_evaluation_specific_competences_left  {
        position: relative;
        height: 100%;
        width: 98%;
        display: grid;
        grid-template-rows: 8% 92%;
    }

    .professor_evaluation_specific_competences_title  {
        position: relative;
        height: 100%;
        width: fit-content;
        left: 2%;
        display: flex;
        align-items: center;
    }

    .professor_evaluation_specific_competences_left_octagon {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .professor_evaluation_specific_competences_right  {
        position: relative;
        height: 96%;
        width: 96%;
        left: 2%;
        top: 2%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 1% 2%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_evaluation_specific_competences_right_title  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .professor_evaluation_specific_competences_right_title_back  {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
    }

    .professor_evaluation_specific_competences_right_content_body  {
        position: relative;
        height: 95%;
        width: 100%;
        /* display: flex;
        flex-direction: column; */
        display: grid;
        grid-template-rows: 35% 65%;
    }
    
    .professor_evaluation_specific_competences_right_content_subcompetences  {
        position: relative;
        max-height: 22vh;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid #000000;
    }

    .professor_evaluation_specific_competences_right_content_subcompetence_list  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        padding: 2%;

    }

    .professor_evaluation_specific_competences_right_content_100  {
        position: relative;
        height: 96%;
        top: 4%;
        width: 96%;
        left: 2%;
        border-bottom: 1px solid #5090F0;
    }

    .professor_evaluation_specific_competences_right_content_66_33  {
        position: relative;
        height: 96%;
        top: 4%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-rows: 66.666% 33.333%;
        border-bottom: 1px solid #5090F0;
    }

    .professor_evaluation_specific_competences_right_content_33_66  {
        position: relative;
        height: 96%;
        top: 4%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-rows: 33.333% 66.666%;
        border-bottom: 1px solid #5090F0;
    }

    .professor_evaluation_specific_competences_right_content  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_evaluation_specific_competences_right_content_list  {
        position: relative;
        max-height: 15.5vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }

    .professor_evaluation_specific_competences_right_content_list_element  {
        position: relative;
        height: fit-content;
        width: 98%;
        margin-top: 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .professor_evaluation_specific_competences_right_content_list_element_green  {
        position: relative;
        height: 12px;
        min-width: 12px;
        border-radius: 6px;
        margin-right: 0.5em;
        background-color: #50F0C0;
    }

    .professor_evaluation_specific_competences_right_content_list_element_red  {
        position: relative;
        height: 12px;
        min-width: 12px;
        border-radius: 6px;
        margin-right: 0.5em;
        background-color: #FF8787;
    }

    .professor_evaluation_specific_competences_right_element_line_container  {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
    }

    .professor_evaluation_specific_competences_right_element_line  {
        position: absolute;
        height: 15%;
        width: 100%;
        top: 40%;
        border-radius: 25px;
        background-color: #5090F025;
    }

    .professor_evaluation_specific_competences_right_element_student {
        position: absolute;
        height: 15%;
        top: 40%;
        border-radius: 25px;
        background-color: #5090F0;
    }

    .professor_evaluation_specific_competences_right_element_school {
        position: absolute;
        height: 15%;
        width: 1.5%;
        top: 40%;
        background-color: #50F0C0;
    }

    .professor_evaluation_specific_competences_right_content  {
        position: relative;
        height: 38vh;
        width: 100%;
        display: grid;
        margin-top: 2%;
        grid-template-rows: 10% 90%;
    }

    .professor_evaluation_specific_competences_right_content_list_2  {
        position: relative;
        height: 100%;
        width: 96%;
        left: 2%;
        display: flex;
        flex-direction: column;
        /* display: grid;
        grid-template-rows: repeat(auto-fill, minmax(20%, 1fr)); */
        overflow-y: scroll;
    }

    .professor_evaluation_specific_competences_right_content_scenario  {
            height: fit-content;
            width: auto;
            display: flex;
            margin-top: 1vh;
            background-color: #ffffff;
            border-radius: 10px;
    }

    .professor_evaluation_specific_competences_right_content_scenario_info {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_evaluation_specific_competences_right_content_scenario_info_top {
        position: relative;
        height: auto;
        width: 100%;
        white-space: nowrap;
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }

    .professor_evaluation_specific_competences_right_content_scenario_info_bottom {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }

    .professor_evaluation_specific_competences_right_content_scenario_info_bottom_course {
        position: relative;
        border-radius: 50px;
        padding-left: 1%;
        padding-right: 1%;
        margin-left: 2%;
    }

    .professor_evaluation_specific_competences_right_content_subject_left  {
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    .professor_evaluation_specific_competences_right_content_subject_right  {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        column-span: all;
        align-items: center;
    }

    .professor_evaluation_specific_competences_right_content_subject_topic {
        position: relative;
        height: 100%;
        width: 80%;
        display: grid;
    }

    .professor_evaluation_specific_competences_right_content_subject_subtopic {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 50%;
    }

    .professor_evaluation_specific_competences_right_chart  {
        position: relative;
        height: 96%;
        width: 100%;
        top: 2%;
        display: grid;
        grid-template-rows: 10% 58%;
    }

    .professor_evaluation_specific_competences_right_chart_title  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .professor_evaluation_specific_competences_right_chart_left  {
        position: absolute;
        height: 40%;
        width: 20%;
        top: 55%;
        left: 1%;
        display: grid;
        grid-template-rows: 40% 60%;
    }

    .professor_evaluation_specific_competences_right_chart_left_line  {
        position: relative;
        height: 80%;
        width: 100%;
        top: 20%;
        border-left: 1px solid #5090F0;
    }

    .professor_evaluation_specific_competences_right_chart_box  {
        position: relative;
        height: 100%;
        width: 100%;
        border: 1px solid #5090F0;
        display: grid;
        grid-template-rows: 50% 50%;
    }

    .professor_evaluation_specific_competences_right_chart_right  {
        position: absolute;
        height: 40%;
        width: 20%;
        top: 55%;
        right: 1%;
        display: grid;
        grid-template-rows: 40% 60%;
    }

    .professor_evaluation_specific_competences_right_chart_right_line  {
        position: relative;
        height: 80%;
        width: 100%;
        top: 20%;
        border-right: 1px solid #5090F0;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_evaluation_specific_competences  {
        position: relative;
        height: 97%;
        width: 100%;
        top: 3%;
        display: grid;
        grid-template-columns: 50% 50%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
    }

    .professor_evaluation_specific_competences_left  {
        position: relative;
        height: 100%;
        width: 98%;
        display: grid;
        grid-template-rows: 8% 92%;
    }

    .professor_evaluation_specific_competences_title  {
        position: relative;
        height: 100%;
        width: 98%;
        left: 2%;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .professor_evaluation_specific_competences_left_octagon {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .professor_evaluation_specific_competences_right  {
        position: relative;
        height: 96%;
        width: 96%;
        left: 2%;
        top: 2%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 1% 2%;
        display: grid;
        grid-template-rows: 10% 50% 40%;
    }

    .professor_evaluation_specific_competences_right_title  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 5% 50% 45%;
    }

    .professor_evaluation_specific_competences_right_content_100  {
        position: relative;
        height: 96%;
        top: 4%;
        width: 96%;
        left: 2%;
        border-bottom: 1px solid #5090F0;
    }

    .professor_evaluation_specific_competences_right_content_66_33  {
        position: relative;
        height: 96%;
        top: 4%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-rows: 66.666% 33.333%;
        border-bottom: 1px solid #5090F0;
    }

    .professor_evaluation_specific_competences_right_content_33_66  {
        position: relative;
        height: 96%;
        top: 4%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-rows: 33.333% 66.666%;
        border-bottom: 1px solid #5090F0;
    }

    .professor_evaluation_specific_competences_right_content  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_evaluation_specific_competences_right_content_list  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .professor_evaluation_specific_competences_right_content_list_element  {
        position: relative;
        height: 90%;
        width: 100%;
        top: 10%;
        display: grid;
        grid-template-columns: 3% 97%;
    }

    .professor_evaluation_specific_competences_right_content_list_element_green  {
        position: relative;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: #50F0C0;
    }

    .professor_evaluation_specific_competences_right_content_list_element_red  {
        position: relative;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: #FF8787;
    }

    .professor_evaluation_specific_competences_right_element_line_container  {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
    }

    .professor_evaluation_specific_competences_right_element_line  {
        position: absolute;
        height: 20%;
        width: 100%;
        top: 40%;
        border-radius: 25px;
        background-color: rgb(251, 196, 18, 25%);
    }

    .professor_evaluation_specific_competences_right_element_student {
        position: absolute;
        height: 50%;
        width: 5%;
        top: 25%;
        border-radius: 25px;
        background-color: #5090F0;
    }

    .professor_evaluation_specific_competences_right_element_school {
        position: absolute;
        height: 50%;
        width: 5%;
        top: 25%;
        border-radius: 25px;
        background-color: #6EE6A7;
    }

    .professor_evaluation_specific_competences_right_chart  {
        position: relative;
        height: 96%;
        width: 100%;
        top: 2%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_evaluation_specific_competences_right_chart_title  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .professor_evaluation_specific_competences_right_chart_left  {
        position: absolute;
        height: 40%;
        width: 20%;
        top: 55%;
        left: 1%;
        display: grid;
        grid-template-rows: 40% 60%;
    }

    .professor_evaluation_specific_competences_right_chart_left_line  {
        position: relative;
        height: 80%;
        width: 100%;
        top: 20%;
        border-left: 1px solid #5090F0;
    }

    .professor_evaluation_specific_competences_right_chart_box  {
        position: relative;
        height: 100%;
        width: 100%;
        border: 1px solid #5090F0;
        display: grid;
        grid-template-rows: 50% 50%;
    }

    .professor_evaluation_specific_competences_right_chart_right  {
        position: absolute;
        height: 40%;
        width: 20%;
        top: 55%;
        right: 1%;
        display: grid;
        grid-template-rows: 40% 60%;
    }

    .professor_evaluation_specific_competences_right_chart_right_line  {
        position: relative;
        height: 80%;
        width: 100%;
        top: 20%;
        border-right: 1px solid #5090F0;
    }
}