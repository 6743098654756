@font-face {
  font-family: 'Causten';
  src: local('Causten'), url(../../../../../fonts/Causten/Causten-Regular.otf) format('opentype');
}

body {
  background-color: rgba(255, 255, 255, 0.75);
  user-select: none;
}

.image_select_container {
  height: fit-content;
  width: 70%;
  position: relative;
  background-color: rgba(255, 255, 255, 0.75);
  background: 4px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  padding: 2%;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

}

.image_select_icon {
  /* position: relative; */
  margin: auto;
  width: 100%;
  height: 10vh;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../../../../assets/professor/image_pic.svg);

}

.image_select_text {
  font-size: calc(5px + 1.25vw);
  font-family: 'Causten';
  font-weight: bold;
  color: rgba(87, 87, 87, 1);
}

.image_select_subtext {
  font-size: calc(5px + 1vw);
  font-family: 'Causten';
  font-weight: bold;
  color: rgba(87, 87, 87, 1);
}

.image_select_button {
  position: relative;
  margin: auto;
  width: 100%;
  height: 10vh;
  background-repeat: no-repeat;
  background-position: center;
  scale: 0.8;
  background-image: url(../../../../../assets/exercises/plus_grey.svg);
}

.image_select_button_container {
  width: 100%;
  height: 100%;
  display: flex;
}

.image_image_container {
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.exercise_image_editor_background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #00000050;
  z-index: 1;
}

.exercise_image_editor_main {
  position: relative;
  height: 70vh;
  width: 70vw;
  top: 15vh;
  left: 15vw;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #5090F0;
  display: grid;
  grid-template-rows: 1fr 9fr 1fr;
  padding: 1%;
}

.exercise_image_editor_library_selector {
  position: relative;
  height: 100%;
  width: 88%;
  padding-left: 8%;
  margin-left: 2%;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  border-bottom: 1px solid #5090F050;
}

.exercise_image_editor_library_selector_zynergic {
  position: relative;
  height: 100%;
  width: 80%;
  margin-left: 10%;
  background-color: #E1EDFF;
  border-bottom: 1px solid #5090F0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.exercise_image_editor_library_selector_upload_button {
  position: relative;
  margin-top: 1%;
  height: 80%;
  width: 80%;
  cursor: pointer;
  background-color: #E1EDFF;
  border: 1px solid #5090F0;
  border-radius: 5px;
}

.exercise_image_editor_library_body {
  position: relative;
  margin-top: 1%;
  margin-left: 1%;
  height: 95%;
  width: 98%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(8, 1fr);
  overflow-y: scroll;
}

.exercise_image_editor_library_arrows {
  position: relative;
  height: 100%;
  width: 30%;
  margin-left: 35%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.exercise_image_editor_back_button {
  position: absolute;
  top: 3%;
  right: 2%;
  z-index: 300;
}