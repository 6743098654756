/* Ordenador */

@media only screen and (min-width: 992px) {
    .student {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ivory;
    }

    .student_dna {
        position: absolute;
        width: 12%;
        height: 4%;
        top: 2%;
        left: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        grid-template-columns: 15% 25% 60%;
        z-index: 99;
    }

    .student_ecodestruction {
        position: absolute;
        width: 12%;
        height: 4%;
        top: 7%;
        left: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        grid-template-columns: 15% 25% 60%;
        z-index: 99;
    }

    .student_biodiversity {
        position: absolute;
        height: 4%;
        top: 2%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        z-index: 99;

    }

    .student_points {
        position: absolute;
        height: 4%;
        width: 12%;
        top: 7%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        z-index: 99;
        grid-template-columns: 15% 25% 60%;
    }

    .student_biodiversity_loss {
        position: absolute;
        height: 8%;
        width: 23%;
        top: 7%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        grid-template-rows: 1fr 1fr;
        z-index: 99;
    }

    .student_biodiversity_loss_student {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 85% 15%;
    }

    .student_biodiversity_loss_class {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 85% 15%;
    }

    .plot_details {
        padding: 10px;
        position: absolute;
        width: 12%;
        top: 8%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        /*grid-template-columns: 15% 25% 60%; */
        grid-template-rows: 1fr 1fr 1fr;
        z-index: 5;
    }

    .logout_button {
        position: absolute;
        padding: 4px;
        height: 12%;
        width: 60%;
        bottom: 8%;
        left: 20%;
        background-color: #F06D50;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        border-radius: 10px;
    }

    .user_menu_background_student {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;
        background-color: rgb(0, 0, 0, 30%);
        z-index: 999;
    }

    .user_menu {
        position: absolute;
        width: 36.5%;
        height: 39.35%;
        top: 30.325%;
        left: 31.75%;
        background-color: #ffffff;
        border: 1px solid #8DBCFF;
        border-radius: 10px;
    }

    .user_menu_close {
        position: absolute;
        width: 10%;
        height: 10%;
        top: 2%;
        right: 1%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .user_menu_username {
        position: absolute;
        width: 50%;
        height: 10%;
        bottom: 30%;
        left: 25%;
    }

    .user_menu_icon {
        position: absolute;
        height: 110px;
        width: 110px;
        border-radius: 50%;
        background-color: #E6C9FF;
    }

    .user_menu_button {
        padding: 0.8vh;
        box-sizing: border-box;
        position: absolute;
        height: 9vh;
        aspect-ratio: 1/1;
        bottom: 3vh;
        right: 3vw;
        background-color: rgb(251, 196, 18, 90%);
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        border-radius: 5px;
        z-index: 99;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .student {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .student_dna {
        position: absolute;
        width: 20%;
        height: 4%;
        top: 2%;
        left: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        grid-template-columns: 15% 25% 60%;
        z-index: 5;
    }

    .student_biodiversity {
        position: absolute;
        width: 20%;
        height: 4%;
        top: 2%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        grid-template-columns: 15% 25% 60%;
        z-index: 5;
    }

    .canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ivory;
    }

    /* .student_dna {
        position: absolute;
        width: 12%;
        height: 4%;
        top: 2%;
        left: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        grid-template-columns: 15% 25% 60%;
        z-index: 99;
    } */

    .student_ecodestruction {
        position: absolute;
        width: 12%;
        height: 4%;
        top: 7%;
        left: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        grid-template-columns: 15% 25% 60%;
        z-index: 99;
    }

    /* .student_biodiversity {
        position: absolute;
        height: 4%;
        top: 2%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        z-index: 99;
    } */

    .student_points {
        position: absolute;
        height: 4%;
        width: 12%;
        top: 7%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        z-index: 99;
        grid-template-columns: 15% 25% 60%;
    }

    .student_biodiversity_loss {
        position: absolute;
        height: 8%;
        width: 23%;
        top: 7%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        grid-template-rows: 1fr 1fr;
        z-index: 99;
    }

    .student_biodiversity_loss_student {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 85% 15%;
    }

    .student_biodiversity_loss_class {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 85% 15%;
    }

    .plot_details {
        padding: 10px;
        position: absolute;
        width: 12%;
        top: 8%;
        right: 2%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 10px;
        display: grid;
        /*grid-template-columns: 15% 25% 60%; */
        grid-template-rows: 1fr 1fr 1fr;
        z-index: 5;
    }

    .logout_button {
        position: absolute;
        padding: 4px;
        height: 12%;
        width: 60%;
        bottom: 8%;
        left: 20%;
        background-color: #F06D50;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        border-radius: 10px;
    }

    .user_menu_background_student {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;
        background-color: rgb(0, 0, 0, 30%);
        z-index: 999;
    }

    .user_menu {
        position: absolute;
        width: 36.5%;
        height: 39.35%;
        top: 30.325%;
        left: 31.75%;
        background-color: #ffffff;
        border: 1px solid #8DBCFF;
        border-radius: 10px;
    }

    .user_menu_close {
        position: absolute;
        width: 10%;
        height: 10%;
        top: 2%;
        right: 1%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .user_menu_username {
        position: absolute;
        width: 50%;
        height: 10%;
        bottom: 30%;
        left: 25%;
    }

    .user_menu_icon {
        position: absolute;
        height: 110px;
        width: 110px;
        border-radius: 50%;
        background-color: #E6C9FF;
    }

    .user_menu_button {
        padding: 0.8vh;
        box-sizing: border-box;
        position: absolute;
        bottom: 3vh;
        left: 2vw;
        background-color: rgb(41, 47, 76, 75%);
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        border-radius: 5px;
        z-index: 99;
    }
}



























/* lmao */

.global_map_workshop_button {
    position: absolute;
    height: 10%;
    width: 15%;
    top: 20%;
    left: 30%;
    background-color: rgb(98, 221, 104);
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}

.global_map_lab_button {
    position: absolute;
    height: 10%;
    width: 15%;
    top: 45%;
    left: 30%;
    background-color: cornflowerblue;
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}

.global_map_town_hall_button {
    position: absolute;
    height: 10%;
    width: 15%;
    top: 70%;
    left: 30%;
    background-color: rgb(221, 98, 98);
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}

.global_map_challenges_button {
    position: absolute;
    height: 10%;
    width: 15%;
    top: 20%;
    right: 30%;
    background-color: rgb(216, 158, 34);
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}

.global_map_home_button {
    position: absolute;
    height: 10%;
    width: 15%;
    top: 45%;
    right: 30%;
    background-color: rgb(216, 34, 192);
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}

.global_map_local_button {
    position: absolute;
    height: 10%;
    width: 20%;
    top: 45%;
    right: 40%;
    background-color: rgb(20, 34, 192);
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}

.local_map_global_button {
    position: absolute;
    height: 10%;
    width: 15%;
    top: 70%;
    right: 30%;
    background-color: rgb(20, 34, 192);
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}

.global_map_building_button {
    position: absolute;
    height: 10%;
    width: 15%;
    bottom: 2%;
    right: 2%;
    background-color: rgb(216, 234, 52);
    border-radius: 10px;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    z-index: 5;
}