/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_evaluation_global_competences  {
        position: relative;
        height: 96%;
        width: 100%;
        margin-top: 2%;
        display: grid;
        grid-template-columns: 50% 50%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
    }

    .professor_evaluation_global_competences_left  {
        position: relative;
        height: 100%;
        width: 98%;
        display: grid;
        grid-template-rows: 8% 92%;
    }

    .professor_evaluation_global_competences_title  {
        position: relative;
        height: 100%;
        width: 50%;
        left: 2%;
        margin-top: 1%;
        display: flex;
        align-items: center;
    }

    .professor_evaluation_global_competences_left_octagon {
        position: relative;
        height: 100%;
        width: 100%;
        /* left: 10%; */
        /* top: 2%; */
    }

    .professor_evaluation_global_competences_right  {
        position: relative;
        height: 100%;
        width: 98%;
        left: 2%;
        display: grid;
    }

    .professor_evaluation_global_competences_right_element  {
        position: relative;
        height: 80%;
        width: 90%;
        top: 10%;
        left: 5%;
        padding-left: 3%;
        display: grid;
        grid-template-columns: 50% 50%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        border-radius: 5px;
    }

    .professor_evaluation_global_competences_right_element_line_container  {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%; 
    }

    .professor_evaluation_global_competences_right_element_line  {
        position: absolute;
        height: 15%;
        width: 100%;
        top: 40%;
        border-radius: 25px;
        background-color: #5090F025;
    }

    .professor_evaluation_global_competences_right_element_student {
        position: absolute;
        height: 15%;
        top: 40%;
        border-radius: 25px;
        background-color: #5090F0;
    }

    .professor_evaluation_global_competences_right_element_school {
        position: absolute;
        height: 15%;
        width: 1.5%;
        top: 40%;
        background-color: #6EE6A7;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_evaluation_global_competences  {
        position: relative;
        height: 94%;
        width: 96%;
        top: 3%;
        left: 2%;
        display: grid;
        grid-template-columns: 50% 50%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
    }

    .professor_evaluation_global_competences_left  {
        position: relative;
        height: 100%;
        width: 98%;
        display: grid;
        grid-template-rows: 8% 92%;
    }

    .professor_evaluation_global_competences_title  {
        position: relative;
        height: 100%;
        width: 98%;
        left: 2%;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .professor_evaluation_global_competences_left_octagon {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .professor_evaluation_global_competences_right  {
        position: relative;
        height: 100%;
        width: 98%;
        left: 2%;
        display: grid;
    }

    .professor_evaluation_global_competences_right_element  {
        position: relative;
        height: 80%;
        width: 90%;
        top: 10%;
        left: 5%;
        display: grid;
        grid-template-columns: 50% 50%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        border-radius: 5px;
    }

    .professor_evaluation_global_competences_right_element_line_container  {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
    }

    .professor_evaluation_global_competences_right_element_line  {
        position: absolute;
        height: 20%;
        width: 100%;
        top: 40%;
        border-radius: 25px;
        background-color: rgb(251, 196, 18, 25%);
    }

    .professor_evaluation_global_competences_right_element_student {
        position: absolute;
        height: 50%;
        width: 5%;
        top: 25%;
        border-radius: 25px;
        background-color: #5090F0;
    }

    .professor_evaluation_global_competences_right_element_school {
        position: absolute;
        height: 50%;
        width: 5%;
        top: 25%;
        border-radius: 25px;
        background-color: #6EE6A7;
    }
}