/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_calendar {
        position: relative;
        height: 100%;
        width: 94%;
        left: 3%;
        box-sizing: border-box;
        padding: 0.5% 0%;
    }

    .professor_calendar_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_calendar_menu_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        padding-top: 0.5%;
        align-items: center;
        justify-content: space-between;
    }

    .professor_calendar_menu_top_center {
        position: relative;
        height: 100%;
        width: 40%;
        display: grid;
        grid-template-columns: 25% 8% 34% 8% 25%;
    }

    .professor_calendar_menu_top_today {
        position: relative;
        height: 65%;
        width: 75%;
        top: 17.5%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
        border-radius: 5px;
        box-shadow:0px 0px 10px 1px #B9B9B9;
    }
    
    .professor_calendar_menu_main  {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        grid-template-rows: 60% 40%;
        padding: 1% 0%;
    }

    .professor_calendar_menu_calendar {
        position: relative;
        height: 100%;
        width: 98%;
        left: 1%;
        border-radius: 5px;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
    }

    .professor_calendar_menu_bottom  {
        position: relative;
        height: 94%;
        width: 98%;
        top: 6%;
        left: 1%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_calendar_menu_bottom_content  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        box-sizing: border-box;
        padding: 2%;
    }

    .professor_calendar_menu_bottom_right  {
        position: relative;
        height: 30%;
        width: 98%;
        left: 2%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        background-color: #6EE6A7;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .professor_calendar_menu_subjects {
        position: fixed;
        height: 70%;
        width: 60%;
        top: 15%;
        left: 25%;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
        z-index: 1;
    }

    .professor_calendar_menu_subjects_content {
        position: relative;
        height: 92%;
        width:92%;
        top: 4%;
        left:4%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_calendar_menu_subject_tabs{
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(7,1fr);
    }

    .professor_calendar_menu_subject_tab_0{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #F6B2A5;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_1{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #F9DFC0;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_2{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #FCEAAF;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_3{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #DDF9C0;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_4{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #C0DBF9;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_5{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #E3C0F9;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_6{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #F9C0F0;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_units {
        position: relative;
        width: 100%;
        height: 100%;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
    }

    .professor_calendar_menu_subject_content_0{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #F6B2A5;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_1{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #F9DFC0;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_2{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #FCEAAF;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_3{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #DDF9C0;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_4{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #C0DBF9;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_5{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #E3C0F9;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_6{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #F9C0F0;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_unit_container{
        position: relative;
        height: 86%;
        width: 90%;
        margin-top: 4%;
        margin-left: 5%;
    }

    .professor_calendar_menu_subject_unit{
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
        background-color: #ffffff;
        display: grid;
        grid-template-rows: 30% 55% 15%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_unit_image {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #292F4C;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .professor_calendar_menu_subject_unit_center {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 20% 65% 15%;
    }

    .professor_calendar_menu_subject_unit_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .professor_calendar_menu_subject_unit_green_button {
        position: relative;
        height: 70%;
        width: 80%;
        margin-top: 2%;
        margin-left: 15%;
        background-color: #6EE6A7;
        border-radius: 5px;
    }

    .professor_calendar_menu_subject_unit_blue_button {
        position: relative;
        height: 70%;
        width: 80%;
        margin-top: 2%;
        margin-left: 5%;
        background-color: #5090F0;
        border-radius: 5px;
    }

    .professor_calendar_menu_add_unit_menu {
        position: fixed;
        height: 70%;
        width: 60%;
        top: 15%;
        left: 25%;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
        z-index: 1;
        display: grid;
        grid-template-rows: 20% 10% 70%;
    }

    .professor_calendar_menu_see_content_menu {
        position: fixed;
        height: 70%;
        width: 60%;
        top: 15%;
        left: 25%;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
        z-index: 1;
        display: grid;
        grid-template-rows: 15% 75% 10%;
    }

    .professor_calendar_menu_bottom_topic {
        position: relative;
        height: 90%;
        width: 90%;
        top: 5%;
        left: 5%;
        border-radius: 5px;
        box-shadow:0px 0px 10px 1px #B9B9B9;
        display: grid;
        grid-template-columns: 15% 85%;
    }

    .professor_calendar_menu_select {
        position: relative;
        left: 10%;
        border-radius: 5px;
        box-shadow:0px 0px 10px 1px #B9B9B9;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;

        font-size:calc(5px + 0.75vw);
        font-family: 'Causten';
        font-weight: bold;
        color: #000000;
        
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    .professor_calendar_back {
        position: relative;
        display: flex;
        align-items: center;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_calendar_menu  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 12% 55% 33%;
        box-sizing: border-box;
        padding: 2%;
    }

    .professor_calendar_menu_top {
        position: relative;
        height: 100%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-columns: 35% 28% 10% 2.5% 12% 2.5% 10%;
    }

    .professor_calendar_menu_top_today {
        position: relative;
        height: 100%;
        width: 50%;
        top: 20%;
        border-radius: 5px;
        box-shadow:0px 0px 10px 1px #B9B9B9;
    }
    
    .professor_calendar_menu_calendar {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        border-radius: 5px;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
    }

    .professor_calendar_menu_bottom  {
        position: relative;
        height: 94%;
        width: 98%;
        top: 6%;
        left: 1%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_calendar_menu_bottom_content  {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        box-sizing: border-box;
        padding: 2%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_bottom_right  {
        position: relative;
        height: 30%;
        width: 98%;
        left: 2%;
        box-shadow:0px 0px 10px 1px rgb(80, 144, 240,0.5);
        background-color: #6EE6A7;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .professor_calendar_menu_subjects {
        position: fixed;
        height: 70%;
        width: 60%;
        top: 15%;
        left: 25%;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
        z-index: 1;
    }

    .professor_calendar_menu_subjects_content {
        position: relative;
        height: 92%;
        width:92%;
        top: 4%;
        left:4%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_calendar_menu_subject_tabs{
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(7,1fr);
    }

    .professor_calendar_menu_subject_tab_0{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #F6B2A5;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_1{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #F9DFC0;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_2{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #FCEAAF;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_3{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #DDF9C0;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_4{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #C0DBF9;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_5{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #E3C0F9;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_tab_6{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #F9C0F0;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_units {
        position: relative;
        width: 100%;
        height: 100%;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
    }

    .professor_calendar_menu_subject_content_0{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #F6B2A5;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_1{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #F9DFC0;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_2{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #FCEAAF;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_3{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #DDF9C0;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_4{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #C0DBF9;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_5{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #E3C0F9;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_content_6{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        background-color: #F9C0F0;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-auto-rows: 50%;
        overflow-y: scroll;
    }

    .professor_calendar_menu_subject_unit_container{
        position: relative;
        height: 86%;
        width: 90%;
        margin-top: 4%;
        margin-left: 5%;
    }

    .professor_calendar_menu_subject_unit{
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
        background-color: #ffffff;
        display: grid;
        grid-template-rows: 30% 55% 15%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_menu_subject_unit_image {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #292F4C;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .professor_calendar_menu_subject_unit_center {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 20% 65% 15%;
    }

    .professor_calendar_menu_subject_unit_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .professor_calendar_menu_subject_unit_green_button {
        position: relative;
        height: 70%;
        width: 80%;
        margin-top: 2%;
        margin-left: 15%;
        background-color: #6EE6A7;
        border-radius: 5px;
    }

    .professor_calendar_menu_subject_unit_blue_button {
        position: relative;
        height: 70%;
        width: 80%;
        margin-top: 2%;
        margin-left: 5%;
        background-color: #5090F0;
        border-radius: 5px;
    }

    .professor_calendar_menu_add_unit_menu {
        position: fixed;
        height: 70%;
        width: 60%;
        top: 15%;
        left: 25%;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
        z-index: 1;
        display: grid;
        grid-template-rows: 20% 10% 70%;
    }

    .professor_calendar_menu_see_content_menu {
        position: fixed;
        height: 70%;
        width: 60%;
        top: 15%;
        left: 25%;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgb(80, 144, 240,0.5);
        z-index: 1;
        display: grid;
        grid-template-rows: 15% 75% 10%;
    }

    .professor_calendar_menu_bottom_topic {
        position: relative;
        height: 90%;
        width: 90%;
        top: 5%;
        left: 5%;
        border-radius: 5px;
        box-shadow:0px 0px 10px 1px #B9B9B9;
        display: grid;
        grid-template-columns: 15% 85%;
    }

    .professor_calendar_menu_select {
        position: relative;
        height: 75%;
        max-height: 75%;
        width: 75%;
        max-width: 75%;
        border-radius: 5px;
        box-shadow:0px 0px 10px 1px #B9B9B9;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .professor_calendar_back {
        position: relative;
        display: flex;
        align-items: center;
    }
}