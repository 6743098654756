/* Ordenador */

@media only screen and (min-width: 992px) {
    .edit_drag_drop_list {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .edit_drag_drop_list_professor {
        position: absolute;
        height: 20%;
        width: 20%;
        bottom: 0;
        left: 5%;
    }

    .edit_drag_drop_list_text_box {
        position: absolute;
        height: 48%;
        width: 28%;
        top: 15%;
        left: 2%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0%;
        z-index: 1;
    }

    .edit_drag_drop_list_arrows {
        position: absolute;
        height: 10%;
        width: 10%;
        bottom: 1.5%;
        left: 40%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_drag_drop_list_confirm {
        position: absolute;
        height: 9%;
        width: 12%;
        bottom: 2%;
        left: 55%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1;
    }

    .edit_drag_drop_list_help_button {
        position: absolute;
        height: 5vh;
        width: 18vw;
        display: grid;
        grid-template-columns: 5fr 1fr;
        bottom: 1vh;
        right: 5vw;
        z-index: 6;
    }

    .write_help_text_button {
        position: relative;
        height: 90%;
        width: 90%;
        background-color: white;
        color: #575757;
        font-weight: bold;
        border-radius: 5px;
    }

    .edit_drag_drop_list_help_menu {
        position: absolute;
        height: 50%;
        width: 30%;
        top: 20%;
        left: 35%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 2;
    }

    .edit_drag_drop_list_background {
        position: absolute;
        height: 80%;
        width: 60%;
        top: 12%;
        right: 2%;
        z-index: 1;
    }

    .edit_drag_drop_list_background_components {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 80% 10%;
    }

    .edit_drag_drop_list_top {
        position: relative;
        height: 100%;
        width: 24.3%;
        left: 10.5%;
        border-radius: 10px;
        background-color: #f0684f;
        z-index: 1;
    }

    .edit_drag_drop_list_bottom {
        position: relative;
        height: 100%;
        width: 24.3%;
        left: 63.8%;
        bottom: 90%;
        border-radius: 10px;
        background-color: #6ee6a7;
    }

    .edit_drag_drop_list_center {
        position: relative;
        height: 100%;
        width: 100%;
        bottom: 5%;
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(240, 104, 79, 1) 0%, rgba(240, 104, 79, 1) 5%, rgba(255, 157, 41, 1) 25%, rgba(255, 209, 62, 1) 50%, rgba(186, 243, 129, 1) 75%, rgba(110, 230, 167, 1) 95%, rgba(110, 230, 167, 1) 100%);
    }

    .edit_drag_drop_list_droppable {
        position: absolute;
        height: inherit;
        width: 100%;
        display: grid;
        grid-template-rows: repeat(5, 20%);
        box-sizing: border-box;
        padding: 2%;
        align-items: center;
    }

    .edit_drag_drop_list_draggable_edit {
        position: relative;
        width: 90%;
        height: 90%;
        margin-left: 5%;
        background-color: "none";
        border-radius: 5px;
        cursor: pointer;
    }

    .edit_drag_drop_list_add_option {
        position: relative;
        width: 90%;
        height: 90%;
        margin-left: 5%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
    }

    .edit_drag_drop_list_option_delete {
        position: absolute;
        right: 0;
        top: 15%;
        height: 5vh;
        width: 5vh;
        margin-right: 1%;
        background-color: #F0684F;
        border-radius: 50%;
        cursor: pointer;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .edit_drag_drop_list {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .edit_drag_drop_list_text_box {
        position: absolute;
        height: 20%;
        width: 90%;
        top: 7%;
        left: 5%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5%;
        z-index: 1;
    }

    .edit_drag_drop_list_arrows {
        position: absolute;
        height: 12%;
        width: 20%;
        bottom: 0;
        left: 50%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        z-index: 1;
    }

    .edit_drag_drop_list_confirm {
        position: absolute;
        height: 7%;
        width: 20%;
        bottom: 2.5%;
        left: 5%;
        background-color: #2F80ED;
        cursor: pointer;
        border-radius: 10px;
        z-index: 1;
    }

    .edit_drag_drop_list_help_button {
        position: absolute;
        height: 12%;
        width: 10%;
        bottom: 0;
        right: 2%;
        z-index: 1;
    }

    .edit_drag_drop_list_help_menu {
        position: absolute;
        height: 50%;
        width: 60%;
        top: 20%;
        left: 20%;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
        z-index: 2;
    }

    .edit_drag_drop_list_background {
        position: absolute;
        height: 60%;
        width: 90%;
        top: 30%;
        left: 5%;
        z-index: 1;
    }

    .edit_drag_drop_list_background_components {
        position: relative;
        height: 90%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 80% 10%;
    }

    .edit_drag_drop_list_top {
        position: relative;
        height: 100%;
        width: 24.3%;
        left: 10.5%;
        border-radius: 10px;
        background-color: #f0684f;
        z-index: 1;
    }

    .edit_drag_drop_list_bottom {
        position: relative;
        height: 100%;
        width: 24.3%;
        left: 63.8%;
        top: 110%;
        border-radius: 10px;
        background-color: #6ee6a7;
    }

    .edit_drag_drop_list_center {
        position: relative;
        height: 124%;
        width: 100%;
        bottom: 5%;
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(240, 104, 79, 1) 0%, rgba(240, 104, 79, 1) 5%, rgba(255, 157, 41, 1) 25%, rgba(255, 209, 62, 1) 50%, rgba(186, 243, 129, 1) 75%, rgba(110, 230, 167, 1) 95%, rgba(110, 230, 167, 1) 100%);
    }

    .edit_drag_drop_list_droppable {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .edit_drag_drop_list_draggable {
        position: relative;
        width: 90%;
        height: 12.5%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5%;
    }
}