@font-face {
    font-family: 'Causten';
    src: local('Causten'), url(../../../fonts/Causten/Causten-Regular.otf) format('opentype');
}

/* Ordenador */

@media only screen and (min-width: 992px) {
    .write_answers {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .write_answers_write_boxes {
        position: absolute;
        height: 6%;
        width: 65%;
        top: 70%;
        right: 5%;
        display: grid;
        z-index: 1;
    }

    .write_answers_write_box {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 5px;
        display: grid;
    }

    .write_answers_write_box_wrong {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #FF8787;
        border-radius: 5px;
        display: grid;
    }

    .write_answers_write_box_input {
        position: absolute;
        height: 90%;
        width: 93%;
        left: 0.5%;
        right: 5%;
        padding-left: 5%;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        border: 0;
        border-bottom: 2px dashed;
        resize: none;
        font-size: calc(5px + 1.5vw);
        font-family: 'Causten';
        color: #000000;
        /* border-color: transparent;  in case we don't want the black border*/

        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .write_answers {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .write_answers_write_boxes {
        position: absolute;
        height: 6%;
        width: 90%;
        top: 80%;
        right: 5%;
        display: grid;
        z-index: 1;
    }

    .write_answers_write_box {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #E8E8E8;
        border-radius: 5px;
        display: grid;
    }

    .write_answers_write_box_wrong {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        background-color: #FF8787;
        border-radius: 5px;
        display: grid;
    }

    .write_answers_write_box_input {
        position: relative;
        height: 90%;
        width: 93%;
        left: 1%;
        right: 5%;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        border: 0;
        border-bottom: 2px dashed;
        resize: none;
        font-size: calc(5px + 1.5vw);
        font-family: 'Causten';
        color: #000000;

        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
    }
}