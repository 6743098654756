@media only screen and (min-width: 992px) {

    .marketplace_selector_menu {
        position: relative;
        height: 100vh;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
        box-sizing: border-box;
        padding: 2%;
    }

    .marketplace_selector_menu_top {
        position: relative;
        display: flex;
    }

    .marketplace_selector_menu_title {
        position: relative;
        width: fit-content;
        display: flex;
        align-items: center;
    }

    .marketplace_selector_menu_body {
        position: relative;
        height: 100%;
        width: 100%;
        /* margin-left: 17.5%; */
        display: flex;
        flex-direction: column;
    }

    .marketplace_selector_menu_body_title {
        position: relative;
        width: 60%;
        margin-left: 17.5%;
        height: 20%;
        display: flex;
    }

    .marketplace_selector_menu_body_buttons {
        position: relative;
        width: 100%;
        height: 80%;
        display: flex;
        /* margin-left: 17.5%; */
        justify-content: space-between;
        gap: 2.5%;
        margin-left: 0;
    }

    .marketplace_selector_menu_body_button {
        position: relative;
        width: 60%;
        height: 90%;
        border-radius: 5px;
        background-color: blue;
        display: grid;
        grid-template-rows: 50% 15% 35%;
        box-sizing: border-box;
        padding: 2%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .marketplace_selector_menu_body_button:nth-child(1) {
        background-color: #6ee6a7;
    }

    .marketplace_selector_menu_body_button:nth-child(2) {
        background-color: #FEFFC2;
    }

    .marketplace_selector_menu_body_button:nth-child(3) {
        background-color: #99c2ff;
    }
}