/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_evaluation_scenario_background  {
        position: relative;
        height: 98%;
        width: 100%;
        top: 2%;
        display: grid;
        grid-template-rows: 10% 25% 32.5% 32.5%;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 1%;
    }

    .professor_evaluation_scenario_top  {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-right: 1%;
    }

    .professor_evaluation_scenario_top_left {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

    .professor_evaluation_scenario_top_right {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
    }

    .professor_evaluation_scenario_points_comps {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 2% 1%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .professor_evaluation_scenario_points {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-right: 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .professor_evaluation_scenario_points_total {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: start;
    }

    .professor_evaluation_scenario_points_research {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: start;
    }

    .professor_evaluation_scenario_points_experiment {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: start;
    }

    .professor_evaluation_scenario_comps {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-left: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }

    .professor_evaluation_scenario_comps_criteria {
        position: relative;
        height: 1.6vh;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1vh;
    }

    .professor_evaluation_scenario_comps_spec {
        position: relative;
        height: 1.6vh;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1vh;
    }

    .professor_evaluation_scenario_comps_key {
        position: relative;
        height: 1.6vh;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1vh;
    }

    .professor_evaluation_scenario_research_container {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 1%;
    }

    .professor_evaluation_scenario_research {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 2fr;
        background-color: #FAFAFA;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 1%;
    }

    .professor_evaluation_scenario_research_points {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .professor_evaluation_scenario_research_points_element {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .professor_evaluation_scenario_research_exercises_container {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    .professor_evaluation_scenario_research_exercises {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        align-items: center;
    }

    .professor_evaluation_scenario_research_exercises_element {
        position: relative;
        height: 4vh;
        width: 100%;
        margin: 0 2vh;
        background-color: #FFFFFF;
        border-radius: 3px;
    }
    .professor_evaluation_scenario_research_exercises_bottom {
        position: relative;
        height: 4vh;
        width: 100%;
        margin: 0 2vh;
    }

    .professor_evaluation_scenario_experiment_container {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 1%;
    }

    .professor_evaluation_scenario_experiment {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        background-color: #FAFAFA;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 1% 1.5%;
    }

    .professor_evaluation_scenario_experiment_points {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .professor_evaluation_scenario_experiment_points_map {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .professor_evaluation_scenario_experiment_points_map_element {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .professor_evaluation_scenario_experiment_exercises {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .professor_evaluation_scenario_experiment_exercises_element {
        position: relative;
        height: 4vh;
        width: 100%;
        margin: 0 2vh;
        background-color: #FFFFFF;
        border-radius: 3px;
    }

    .professor_evaluation_scenario_experiment_exercises_element_circle {
        position: relative;
        height: 2.5vh;
        width: 2.5vh;
        border-radius: 50%;
        background-color: #FFFFFF;
    }

}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .professor_evaluation_scenario_background  {
        position: relative;
        height: 98%;
        width: 100%;
        top: 2%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 2%;
    }
}