/* Ordenador */

@media only screen and (min-width: 992px) {
    .true_false {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .true_false_buttons {
        position: absolute;
        height: 9%;
        width: 30%;
        top: 75%;
        display: grid;
        grid-template-columns: 50% 50%;
        z-index: 1;
    }

    .true_false_left_button {
        position: relative;
        height: 100%;
        width: 90%;
        background-color: #b8b8b8;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .true_false_left_button_pressed {
        position: relative;
        height: 100%;
        width: 90%;
        background-color: rgb(195, 255, 135, 100%);
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .true_false_right_button {
        position: relative;
        height: 100%;
        width: 90%;
        margin-left: 10%;
        background-color: #b8b8b8;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .true_false_right_button_pressed {
        position: relative;
        height: 100%;
        width: 90%;
        margin-left: 10%;
        background-color: rgb(255, 135, 135, 100%);
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .true_false {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;

    }

    .true_false_buttons {
        position: absolute;
        height: 6%;
        width: 90%;
        top: 82%;
        left: 5%;
        display: grid;
        grid-template-columns: 50% 50%;
        z-index: 1;
    }

    .true_false_left_button {
        position: relative;
        height: 100%;
        width: 90%;
        background-color: #b8b8b8;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .true_false_left_button_pressed {
        position: relative;
        height: 100%;
        width: 90%;
        background-color: rgb(195, 255, 135, 100%);
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .true_false_right_button {
        position: relative;
        height: 100%;
        width: 90%;
        margin-left: 10%;
        background-color: #b8b8b8;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .true_false_right_button_pressed {
        position: relative;
        height: 100%;
        width: 90%;
        margin-left: 10%;
        background-color: rgb(255, 135, 135, 100%);
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}