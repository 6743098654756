.plot_details_main{
    position: absolute;
    top: 2%;
    width: 30%;
    margin-left: 35%;
    z-index: 150;
}

.plot_details_background{
    position: relative;
    width:100%;
    height:100%;
    background: rgb(41, 47, 76, 75%);
    display: grid;
    padding: 1em;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr  ;
    grid-template-areas: 
    " title title title "
    "left_info center_info right_info"
    ;
    border-radius: 10px;
}

.plot_details_text_wrapper{
    position: relative;
    width:100%;
    height:100%;
    background: rgb(41, 47, 76, 75%);
    display: grid;
    padding: 1em;
    grid-template-rows: 1fr 2fr  ;
    border-radius: 10px;
}

.plot_details_title {
    grid-area: title;
    color: #F9D45F;
    position: relative;
    width:100%;
    height:100%;
    text-align: center;
    font-size: 1em;
    font-weight: bolder;
}

.plot_details_left {
    grid-area: left_info;
    color: white;
    position: relative;
    width:100%;
    height:100%;
    text-align: center;
}
.plot_details_right {
    grid-area: right_info;
    color: white;
    position: relative;
    width:100%;
    height:100%;
    text-align: center;
}

.plot_details_center {
    grid-area: center_info;
    color: white;
    position: relative;
    width:100%;
    height:100%;
    text-align: center;
}


