/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_evaluation_student_info {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_evaluation_student_info_top {
        position: relative;
        height: 98%;
        width: 100%;
        bottom: 5%;
        display: grid;
        grid-template-columns: 55% 15% 15% 15%;
    }

    .professor_evaluation_student_info_top_left {
        position: relative;
        height: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
    }

    .professor_evaluation_student_info_pdf {
        position: relative;
        height: 70%;
        width: 80%;
        top: 15%;
        left: 10%;
        display: grid;
        grid-template-columns: 80% 20%;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 1px #F0684F;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_evaluation_student_info_pdf_button {
        position: relative;
        height: 70%;
        width: 80%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        text-decoration: none;
        display: grid;
        grid-template-columns: 1fr 4fr;
        box-shadow: 0px 0px 5px 1px #F0684F;
        border-radius: 5px;
        top: 8%;
        left: 10%;
    }

    .professor_evaluation_student_info_top_right {
        position: relative;
        height: 90%;
        width: 100%;
        display: grid;
        grid-template-rows: 50% 50%;
        /* bottom: 10%; */
    }

    .professor_evaluation_student_info_top_right_element {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .professor_evaluation_student_info_top_right_element_circle_blue {
        position: relative;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: #5090F0;
    }

    .professor_evaluation_student_info_top_right_element_circle_green {
        position: relative;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: #6EE6A7;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_evaluation_student_info {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 90%;
    }

    .professor_evaluation_student_info_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 25% 25%;
    }

    .professor_evaluation_student_info_top_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .professor_evaluation_student_info_pdf {
        position: relative;
        height: 60%;
        width: 80%;
        top: 20%;
        left: 10%;
        display: grid;
        grid-template-columns: 20% 80%;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 1px #F0684F;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_evaluation_student_info_top_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 50% 50%;
    }

    .professor_evaluation_student_info_top_right_element {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .professor_evaluation_student_info_top_right_element_circle_blue {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: #5090F0;
    }

    .professor_evaluation_student_info_top_right_element_circle_green {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: #6EE6A7;
    }
}