.calendar_week {
  position: relative;
  height: 90%;
  width: 96%;
  top: 5%;
  left: 2%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar_week_day {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 20% 80%;
}

.calendar_week_title {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 40% 60%;
}

.calendar_week_units_container {
  position: relative;
  height: 94%;
  width: 100%;
}

.calendar_week_units {
  position: relative;
  height: 99%;
  width: 96%;
  left: 3%;
  display: grid;
  overflow-y: scroll;
}

.calendar_week_unit_container {
  position: relative;
  height: 100%;
  width: 100%;
}

.calendar_week_unit {
  position: relative;
  height: 80%;
  width: 100%;
  top: 10%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.calendar_week_unit_text {
  font-size:calc(5px + 0.8vw);
  font-family: 'Causten';
  color: #3D4043;
  margin: 5%;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.calendar_week_unit_text_container {
  display: flex;
  justify-content: center;
  align-items: center;
}