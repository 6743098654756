/* Ordenador */

@media only screen and (min-width: 992px) {
    .drag_drop_list {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .drag_drop_list_background {
        position: absolute;
        height: 70%;
        width: 52.5%;
        top: 7%;
        right: 9%;
        z-index: 1;
    }

    .drag_drop_list_background_components {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 80% 10%;
    }

    .drag_drop_list_top {
        position: relative;
        height: 100%;
        width: 24.3%;
        left: 10.5%;
        border-radius: 10px;
        background-color: #f0684f;
        z-index: 1;
    }

    .drag_drop_list_bottom {
        position: relative;
        height: 100%;
        width: 24.3%;
        left: 63.8%;
        bottom: 90%;
        border-radius: 10px;
        background-color: #6ee6a7;
    }

    .drag_drop_list_center {
        position: relative;
        height: 100%;
        width: 100%;
        bottom: 5%;
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(240, 104, 79, 1) 0%, rgba(240, 104, 79, 1) 5%, rgba(255, 157, 41, 1) 25%, rgba(255, 209, 62, 1) 50%, rgba(186, 243, 129, 1) 75%, rgba(110, 230, 167, 1) 95%, rgba(110, 230, 167, 1) 100%);
    }

    .drag_drop_list_droppable {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .drag_drop_list_draggable {
        position: relative;
        width: 90%;
        height: 12.5%;
        background-color: #FFFFFF;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        border-radius: 5px;
        margin-left: 5%;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .drag_drop_list {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index: 5;
    }

    .drag_drop_list_background {
        position: absolute;
        height: 65%;
        width: 90%;
        top: 30%;
        left: 5%;
        z-index: 1;
    }

    .drag_drop_list_background_components {
        position: relative;
        height: 90%;
        width: 100%;
        display: grid;
        grid-template-rows: 10% 80% 10%;
    }

    .drag_drop_list_top {
        position: relative;
        height: 100%;
        width: 24.3%;
        left: 10.5%;
        border-radius: 10px;
        background-color: #f0684f;
        z-index: 1;
    }

    .drag_drop_list_bottom {
        position: relative;
        height: 100%;
        width: 24.3%;
        left: 63.8%;
        top: 110%;
        border-radius: 10px;
        background-color: #6ee6a7;
    }

    .drag_drop_list_center {
        position: relative;
        height: 124%;
        width: 100%;
        bottom: 5%;
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(240, 104, 79, 1) 0%, rgba(240, 104, 79, 1) 5%, rgba(255, 157, 41, 1) 25%, rgba(255, 209, 62, 1) 50%, rgba(186, 243, 129, 1) 75%, rgba(110, 230, 167, 1) 95%, rgba(110, 230, 167, 1) 100%);
    }

    .drag_drop_list_droppable {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .drag_drop_list_draggable {
        position: relative;
        width: 90%;
        height: 12.5%;
        background-color: #FFFFFF;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        margin-left: 5%;
    }
}