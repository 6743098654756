/* Ordenador */

@media only screen and (min-width: 992px) {
    .exercises_experiment {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 7% 93%;
        z-index: 999;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .exercises_experiment {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 7% 93%;
        z-index: 999;
    }
}