/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_welcome {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #292F4C;
        display: grid;
        grid-template-rows: 25% 75%;
    }

    .professor_welcome_left {
        position: relative;
        height: 80%;
        width: 100%;
        margin-left: 12%;
        margin-top: 5%;
        display: grid;
        grid-template-rows: 45% 55%;
    }

    .professor_welcome_arrows_left {
        position: absolute;
        height: 100%;
        width: fit-content;
        left: 1%;
        display: grid;
    }

    .professor_welcome_arrow {
        position: relative;
        max-height: 100%;
        max-width: 100%;
        width: 70%;
        margin-left: 15%;
        margin-bottom: 40%;
    }

    .professor_welcome_top {
        position: relative;
        height: 100%;
        width: 100%;
        /* background-color: red; */
        display: grid;
        grid-template-columns: 30% 70%;
    }

    .professor_welcome_bar {
        position: relative;
        max-height: 100%;
        height: 95%;
    }

    .professor_welcome_right {
        position: relative;
        height: 80%;
        width: 85%;
        margin-left: 10%;
        margin-top: 3%;
        display: grid;
        grid-template-rows: 30% 70%;
    }

    .professor_welcome_bottom {
        position: relative;
        max-height: 90%;
        width: 97%;
        margin-left: 6%;
        display: grid;
    }

    .professor_welcome_video_container {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .professor_welcome_video {
        position: relative;
        height: 85%;
        aspect-ratio: 16 / 9;
        margin-left: 1%;
    }

    .professor_welcome_video_hover {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        border-radius: 5px;
    }

    .professor_welcome_video_grid_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 1fr;
        overflow-y: scroll;
        height: 70vh;
        width: 95%;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_welcome {
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 1080px;
        background-color: #292F4C;
        display: grid;
        grid-template-rows: 20% 80%;
    }

    .professor_welcome_left {
        position: relative;
        height: 85%;
        width: 30%;
        margin-top: 5%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .professor_welcome_arrow {
        position: relative;
        max-height: 100%;
        max-width: 100%;
        width: 70%;
        margin-left: 15%;
        margin-bottom: 40%;
    }

    .professor_welcome_right {
        position: relative;
        height: 20%;
        width: 65%;
        margin-left: 17.5%;
        margin-top: 5%;
        display: grid;
        grid-template-rows: 50% 50%;
    }
}