
  body {
    user-select: none;
  }

  .centered {
    margin: auto;
  }
  
  .exercise_preview_main {
    position: relative;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 15fr;
    background-image: url("../../../../assets/exercises/bluebackgroundexercise.svg");
  }

  .exercise_preview_navbar_container{
    position: relative;
    display: grid;
    height: auto;
    grid-template-columns: 5em auto;
    background: white;
  }

  .navbar_selected_level_background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
  }

  .exercise_preview_navbar_container_experiment{
    position: relative;
    display: grid;
    grid-template-columns: 5em auto;
    background: white;
  }

  .navbar_element {
    display: flex;
    height: inherit;
    border-right: 1px solid white;
    border-top: 1px solid white;
    cursor: pointer;
  }
  
  .navbar_level_container {
    display: grid;
    height: inherit;
    
  }

  .navbar_back_icon{
    background: white;
    text-align: center;
    height: inherit;
  }

  .navbar_pages_container {
    font-size: 0.5rem;
    display: grid;
    height: inherit;
  }

  .navbar_level {
    font-size: 1.5rem;
    height: inherit;
    font-family: "Causten";
  }

  .navbar_level_point_now{
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* background-color: #ffffff; */
  }
  
  .navbar_level_point{
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* background-color: #c7c7c7; */
    border: 1px solid #D9D9D9
  }

  .navbar_textbox {
    text-align: center;
    height: inherit;
    /* display: flex; */
  }

  .navbar_finish {
    text-align: center;
    display: flex;
    height: inherit;
  }

  .selected {
    color: white;
  }

  .selected_page{
    display:"grid";
    grid-template-columns:"auto 1em";
    color: white;
  }

  .add_level_button{
    position: absolute;
    height: 5%;
    width: 20%;
    left: 2%;
    top: 12%;
    border-radius: 5px;
    background-color:  #5090F0;
    box-shadow: 0px 0px 2px 1px #5090F0;
    cursor: pointer;
    z-index: 10;
  }

  .upload_image_button{
    position: absolute;
    display: flex;
    height: 5%;
    width: 25%;
    left: 24%;
    top: 12%;
    border-radius: 5px;
    background-color:  #FECF3C;
    box-shadow: 0px 0px 2px 1px #FECF3C;
    cursor: pointer;
    z-index: 10;
  }

  .delete_button{
    position: absolute;
    height: 5%;
    width: 20%;
    right: 2%;
    top: 12%;
    border-radius: 5px;
    background-color:  #F0684F;
    box-shadow: 0px 0px 2px 1px #F0684F;
    cursor: pointer;
    z-index: 10;
  }
  