.main_index_container{
    position: relative;
    height: 100%;
    width: 100%;
    /* background: linear-gradient(70.95deg, #5090F0 -14.84%, #5E9FEF 5%, #6AADEE 22.78%, #97E1EA 88.74%, #B2FFE8 127.28%); */
    background: white;
    color: white;
}

.exercise_template_thumbnails_container {
    position: relative;
    width: 100%;
    height: 98%;
    box-sizing: border-box;
    padding: 2%;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    border-top: white 2px solid;
    overflow-y: scroll;

}

.exercise_template_thumbnail {
    background-color: white;
    border-radius: 5px;
    width: 95%;
    height: auto;
    margin-right: 5%;
    box-sizing: border-box;
    margin: 2%;
    cursor: pointer;
    box-shadow: #5090F050 0px 0px 20px 0.5px;
    background-color: #FAFAFA;

}

.exercise_template_thumbnail_name {
    color:black;
}

.exercise_template_thumbnail_image img {
    width:100%;
    border-radius: 0px 0px 5px 5px;
}