/* Ordenador */

@media only screen and (min-width: 992px) {
    .media {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index:5;
    }

    .media_text_box {
        position: absolute;
        height: 75%;
        aspect-ratio: 16 / 9;
        top: 8%;
        left: 22%;
        background-color: #d9d9d9;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 1%;
        z-index: 1;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .media {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        z-index:5;
    }

    .media_text_box {
        position: absolute;
        height: 60%;
        width: 60%;
        top: 4%;
        right: 3%;
        background-color: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 1%;
        z-index: 1;
    }
}