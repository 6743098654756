/* Ordenador */

@media only screen and (min-width: 992px) {
    .theme_creation_main {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }

    .theme_creation_main_title {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding: 1% 4%;
    }

    .triad_1_general_container {
        position: relative;
        height: 28vh;
        width: 92%;
        margin-left: 4%;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 5px;
    }

    .triad_title_desc {
        position: relative;
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .triad_2_general_container {
        position: relative;
        height: fit-content;
        width: 92%;
        margin-left: 4%;
        display: flex;
        flex-direction: column;
        background-color: #FFF9E7;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 5px;
    }

    .theme_metrics_container {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 90%;
        height: 20%;
        margin-left: 5%;
        box-sizing: border-box;
        /* padding: 0.5%; */
    }

    .theme_text_container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }

    .theme_right_container {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .theme_metrics_item {
        box-sizing: border-box;
        height: 5vh;
        padding-top: 3%;
        padding-bottom: 3%;
        padding-right: 5%;
        padding-left: 5%;
        border-radius: 5px;
    }

    .theme_metrics_item:nth-child(1) {
        background-color: #6EE6A7;
    }

    .theme_metrics_item:nth-child(2) {
        background-color: #FBC412;
    }

    .theme_metrics_item:nth-child(3) {
        background-color: #5B5B5B;
    }

    .terms_title {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1%;
    }

    .theme_area_description {
        position: relative;
        width: 100%;
        height: 18vh;
    }

    .sustainable_goals_container {
        position: relative;
        width: 90%;
        margin-left: 5%;
        height: 18vh;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .datePicker {
        width: 75%;
        background-color: #5090F0;
        border-radius: 5px;
        font-family: 'Causten';
        text-align: center;
        color: white;
    }

    ._selected {
        position: relative;
        width: 60%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 2px solid #FBC412;
        background-color: #FBC412;
    }

    .theme_bottom_explanation {
        position: relative;
        height: 100%;
        width: 100%;
        font-size: 1.3vw;
        text-align: justify;
        top: 5%;
    }

    .theme_submit_class_button {
        position: relative;
        width: 20%;
        height: 7vh;
        border-radius: 98px;
        background-color: #6EE6A7;
        grid-row: 5;
    }

    .theme_explanation_area {
        position: relative;
        width: 92%;
        height: fit-content;
        margin-top: 1%;
        margin-bottom: 2%;
        margin-left: 4%;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .theme_metric_menu_area {
        position: relative;
        width: 92%;
        height: 100%;
        margin-left: 4%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .theme_objective_learning_container {
        position: relative;
        height: 70%;
        width: 97%;
        margin-top: 5%;
        box-shadow: 0px 0px 10px 2px rgba(80, 144, 240, 0.25);
        display: grid;
        grid-template-rows: 20% 80%;
        box-sizing: border-box;
        padding: 3%;
    }

    .theme_objective_learning_container ol {
        position: relative;
        left: 4%;
        font-size: 1.2vw;
        padding-top: 1%;
        font-weight: 100;
    }

    .theme_objective_learning_container li {
        margin-top: 1.1%;
    }

    .theme_metric_evaluation_container {
        position: relative;
        height: 70%;
        width: 97%;
        margin-top: 5%;
        margin-left: 3%;
        box-shadow: 0px 0px 10px 2px rgba(80, 144, 240, 0.25);
        display: grid;
        grid-template-rows: 20% 80%;
        box-sizing: border-box;
        padding: 3%;
    }

    .metric_item_container {
        position: relative;
        border-radius: 5px;
        width: 95%;
        height: 80%;
        top: 10%;
        left: 2.5%;
    }

    .theme_warning {
        position: relative;
        width: 92%;
        height: 60%;
        display: grid;
        grid-template-columns: 2% 97%;
        margin-left: 13%;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .theme_creation_main {
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 1080px;
        display: grid;
        grid-template-rows: 10% 35% 20% 22% 6% 7%;
    }

    .sustainable_goals_image {
        position: relative;
        max-height: 100%;
        max-width: 100%;
    }

    .theme_bottom_explanation {
        position: relative;
        height: 100%;
        width: 100%;
        font-size: 1.3vw;
        text-align: justify;
        top: 5%;
    }

    .theme_submit_class_button {
        position: relative;
        width: 30%;
        height: 80%;
        border-radius: 98px;
        background-color: #6EE6A7;
        grid-row: 5;
    }

    .theme_explanation_area {
        position: relative;
        width: 92%;
        height: 60%;
        margin-left: 4%;
        margin-top: 5%;
    }

    .theme_metric_menu_area {
        position: relative;
        width: 92%;
        height: 90%;
        margin-top: 3%;
        margin-left: 4%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .theme_objective_learning_container {
        position: relative;
        height: 70%;
        width: 97%;
        margin-top: 5%;
        box-shadow: 0px 0px 10px 2px rgba(80, 144, 240, 0.25);
        display: grid;
        grid-template-rows: 20% 80%;
        box-sizing: border-box;
        padding: 3%;
    }

    .theme_objective_learning_container ol {
        position: relative;
        left: 4%;
        font-size: 1.2vw;
        padding-top: 1%;
        font-weight: 100;
    }

    .theme_objective_learning_container li {
        margin-top: 1.1%;
    }

    .theme_metric_evaluation_container {
        position: relative;
        height: 70%;
        width: 97%;
        margin-top: 5%;
        margin-left: 3%;
        box-shadow: 0px 0px 10px 2px rgba(80, 144, 240, 0.25);
        display: grid;
        grid-template-rows: 20% 80%;
        box-sizing: border-box;
        padding: 3%;
    }

    .metric_item_container {
        position: relative;
        border-radius: 5px;
        width: 95%;
        height: 80%;
        top: 10%;
        left: 2.5%;
    }

    .theme_warning {
        position: relative;
        width: 92%;
        height: 60%;
        display: grid;
        grid-template-columns: 2% 97%;
        margin-left: 5%;
    }
}