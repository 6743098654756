/* Ordenador */

@media only screen and (min-width: 992px) {
    .class_creation_main {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 70% 15%;
    }

    .class_creation_main_title {
        position: relative;
        height: 100%;
        width: 92%;
        margin-left: 4%;
    }

    .class_creation_main_center {
        position: relative;
        height: 100%;
        width: 100%;
        /* width: 92%;
        margin-left: 4%; */
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .class_creation_main_center_container {
        position: relative;
        height: 100%;
        width: 92%;
        margin-left: 4%;
        display: grid;
        grid-template-rows: 75% 25%;
    }

    .class_creation_main_left {
        position: relative;
        height: 100%;
        width: 100%;
        box-shadow: 0px 0px 20px 1px rgb(80, 144, 240, 0.5);
    }

    .class_creation_main_subcontainer {
        position: relative;
        height: 90%;
        width: 94%;
        margin-left: 3%;
        display: grid;
        grid-template-rows: 1fr 2fr 2fr;
    }

    .class_creation_main_subcontainer_bottom {
        display: flex;
        flex-direction: column;
        width: 97%;
        height: 90%;
        margin-left: 1.5%;

    }


    .class_creation_main_subcontainer_right {
        position: relative;
        height: 100%;
        width: 94%;
        margin-left: 3%;
        display: grid;
        grid-template-rows: 10% 20% 5% 10% 45%;
    }

    .class_creation_main_left_component {
        position: relative;
        height: 60%;
        width: 100%;
        display: grid;
        grid-template-rows: 60% 40%;
    }

    .class_create_container_title {
        position: relative;
        padding-top: 3%;
        left: 3%;
        width: 90%;
        height: 5%;
    }

    .text_field_general_container {
        position: relative;
        width: 100%;
        height: 90%;
        padding-top: 3%;
    }

    .text_label_general_container {
        position: relative;
        width: 100%;
        padding-top: 2%;
    }

    .language_dropdown_container {
        position: relative;
        width: 100%;
        height: 90%;
        padding-top: 3%;
    }

    .class_creation_dropdown_box {
        position: relative;
        width: 95%;
        height: 90%;
    }

    .class_create_language_checkbox_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .class_create_language_checkbox_container_top {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 30% 30%;
    }

    .class_create_teachers_dropdown_container {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        gap: 1vh;
        align-items: center;
        white-space: nowrap;
    }

    .class_create_language_checkbox_container_bottom {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 30% 30%;
    }

    .grid_checkbox_item {
        margin-left: 30%;
        margin-top: 3%;
    }

    .submit_class_button {
        position: relative;
        width: 20%;
        height: 35%;
        border-radius: 98px;
        background-color: #6EE6A7;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}

.MuiFormHelperText-root.Mui-error {
    background: red;
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .class_creation_main {
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 1080px;
        display: grid;
        grid-template-rows: 10% 80% 10%;
    }

    .class_creation_main_title {
        position: relative;
        height: 100%;
        width: 92%;
        margin-left: 4%;
    }

    .class_creation_main_subcontainer {
        position: relative;
        height: 100%;
        width: 94%;
        margin-left: 3%;
        display: grid;
        grid-template-rows: 10% 20% 20% 20% 30%;

    }


    .class_creation_main_subcontainer_right {
        position: relative;
        height: 100%;
        width: 94%;
        margin-left: 3%;
        display: grid;
        grid-template-rows: 10% 20% 10% 7% 50%;
    }

    .class_creation_main_left_component {
        position: relative;
        height: 90%;
        width: 100%;
        display: grid;
        grid-template-rows: 60% 40%;
    }

    .class_creation_main_right_component {
        position: relative;
        height: 90%;
        width: 100%;
        margin-left: 3%;
        display: grid;
        grid-template-rows: 60% 40%;
    }

    .class_create_container_title {
        position: relative;
        padding-top: 3%;
        left: 3%;
        width: 90%;
        height: 5%;
    }

    .text_field_general_container {
        position: relative;
        width: 100%;
        padding-top: 1%;
    }

    .text_label_general_container {
        position: relative;
        width: 100%;
        padding-top: 1%;
    }

    .language_dropdown_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .class_creation_dropdown_box {
        position: relative;
        width: 100%;
        height: 60%;
    }

    .class_create_language_checkbox_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .class_create_language_checkbox_container_top {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 30% 30%;
    }

    .class_create_language_checkbox_container_bottom {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 30% 30%;
        overflow-y: scroll;
    }

    .grid_checkbox_item {
        margin-left: 30%;
        margin-top: 3%;
    }

    .submit_class_button {
        position: relative;
        width: 30%;
        height: 35%;
        border-radius: 98px;
        background-color: #6EE6A7;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}