.business_view_container {
    position: relative;
    height: 100%;
    width: 100%;
}

.business_view_toggle_switch {
    position: fixed;
    top: 1em;
    left: 1em;
    height: fit-content;
    width: 8%;
    display: grid;
    grid-template-columns: 40% 20% 40%;
    border-radius: 500px;
    background: #E2EEFF;
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
}
.business_view_toggle_switch_button {
    position: relative;
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 50%;
    background: #5090F0;
}