/* Ordenador */

@media only screen and (min-width: 992px) {
    .challenges {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        z-index:5;
    }

    .challenges_banner_container{
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .challenges_banner{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .challenges_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41,47,76,1) 0%, rgba(41,47,76,0.7) 100%);
    }

    .challenges_close_button {
        position: absolute;
        width: 3.5%;
        height: 7%;
        right: 3%;
        top: 12%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .challenge_1_temp_button {
        position: absolute;
        width: 15%;
        height: 7%;
        right: 3%;
        bottom: 10%;
        border-radius: 10px;
        background-color: aquamarine;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
        z-index: 100;
    }

    .challenges_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .challenges_menu {
        position: absolute;
        margin-top: 5vh;
        margin-left: 5vw;
        width: 90vw;
        height: 70vh;
        top: 20vh;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        overflow-y: scroll;
    }

    .challenges_menu_box {
        position: relative;
        width: 95%;
        height: 90%;
        /* margin-top: 5%; */
        /* margin: 5%; */
        display: grid;
        grid-template-columns: 15% 85%;
    }

    .challenges_menu_box_left {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .challenges_menu_box_challenge_content {
        position: relative;
        width: 95%;
        height: 80%;
        padding-left: 3%;
        border-radius: 5px;
        background-color: #ffffff;
        border-left: #6EE6A7 solid 9px;
        display: grid;
        grid-template-rows: 27.5% 47.5% 25%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .challenges_menu_box_challenge_content_locked {
        position: relative;
        width: 95%;
        height: 80%;
        padding-left: 3%;
        border-radius: 5px;
        background-color: #363636;
        border-left: #F0684F solid 9px;
        display: grid;
        grid-template-rows: 75% 25%;
    }

    .challenges_menu_box_challenge_content_top {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .challenges_menu_box_challenge_content_mid {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .challenges_menu_box_challenge_content_bot {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 45% 55%;
    }
    
    .challenges_menu_box_challenge_content_bot_req {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .challenges_menu_box_challenge_content_bot_reward {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 5% 95%;
    }

    .challenges_menu_box_left_professor {
        position: absolute;
        height: 90%;
        width: 90%;
        bottom: 0;
        z-index: 1;
    }

    .challenges_menu_box_right {
        position: relative;
        width: 90%;
        height: 100%;
        left: 5%;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .challenges {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        z-index:5;
    }

    .challenges_banner_container{
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .challenges_banner{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .challenges_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41,47,76,1) 0%, rgba(41,47,76,0.7) 100%);
    }

    .challenges_close_button {
        position: absolute;
        width: 3.5%;
        height: 7%;
        right: 3%;
        top: 10%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto!important;
    }

    .challenges_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .challenges_menu {
        position: absolute;
        width: 100%;
        height: 73%;
        top: 19%;
    }

    .challenges_menu_box {
        position: relative;
        width: 90%;
        height: 100%;
        left: 5%;
        display: grid;
        grid-template-columns: 30% 70%;
        background-color: #ffffff;
        border-radius: 5px;
    }

    .challenges_menu_box_left {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .challenges_menu_box_left_professor {
        position: absolute;
        height: 90%;
        width: 90%;
        bottom: 0;
        z-index: 1;
    }

    .challenges_menu_box_right {
        position: relative;
        width: 90%;
        height: 100%;
        left: 5%;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
    }
}