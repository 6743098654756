.tutorial_eutons_local_bubble {
  padding: 1.5%;
  position: absolute;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  bottom: 8%;
  left: 20%;
  height: fit-content;
  width: 50%;
  border-radius: 5px;
}

.upper_right_corner {
  top: 12%;
  right: 5%;
  bottom: auto;
  left: auto;
  width: 42.5%;
  height: auto;
}

.tutorial_eutons_local_professor {
  position: absolute;
  z-index: 9;
  bottom: 0;
  right: 0;
}

.tutorial_eutons_local_button {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
  padding: 0vh 3vw;
  z-index: 9;
  height: fit-content;
  width: fit-content;
  background-color: #F9D45F;
  border-radius: 200px;
  cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
  box-shadow: 0px 0px 50px 0px #F9D45F;

}

p {
  margin-top: 4%;
}

.tutorial_eutons_flecha_left {
  position: absolute;
  z-index: 9;
  left: 15%;
  top: 7.5%;
}

.tutorial_eutons_flecha_left_2 {
  position: absolute;
  z-index: 9;
  top: 2.5%;
  left: 15%;
}

.tutorial_eutons_flecha_right {
  position: absolute;
  z-index: 9;
  top: 2.5%;
  right: 15%;
}