/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_evaluation_student_list {
        /* position: relative;
        height: 100%;
        width: 100%; */
        position: relative;
        height: 100%;
        width: 80vw;
        display: flex;
        flex-direction: column;
        /* display: grid;
        grid-template-rows: 15% 85%; */
        box-sizing: border-box;
        padding-left: 1%;
        padding-right: 1%;
        padding-bottom: 1%;
        overflow-x: scroll;
    }

    .professor_evaluation_student_list_body {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .professor_evaluation_student_name_icon {
        position: relative;
        height: 100%;
        width: 18vw;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .professor_evaluation_list_body_student {
        position: relative;
        height: 10vh;
        width: fit-content;
        min-width: 100%;
        padding-bottom: 1.5vh;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-top: 1.5vh;
        border-bottom: 1px solid rgb(80, 144, 240, 0.3);
        cursor:
            url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;

    }

    .professor_evaluation_list_body_student_content {
        position: relative;
        height: auto;
        width: 100%;
    }

    .professor_evaluation_list_body_student_content_data {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;

    }

    .professor_evaluation_list_top {
        position: relative;
        min-height: 15%;
        width: fit-content;
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        border-bottom: 1px solid rgb(80, 144, 240, 0.8);
    }

    .professor_evaluation_list_top_content {
        position: sticky;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    .professor_evaluation_list_top_content_container {
        position: relative;
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_evaluation_list_top_selector {
        position: relative;
        height: 100%;
        width: 18vw;
        display: grid;
        /* grid-template-rows: 50% 50%;     */
        justify-content: center;
        align-items: center;
    }

    .professor_evaluation_list_top_selector_box {
        position: relative;
        /* height: 90%; */
        height: 70%;
        width: 90%;
        box-sizing: border-box;
        padding: 2%;
        left: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5%;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_evaluation_no_students_container {
        position: relative;
        height: 80%;
        width: 75%;
        margin-top: 5%;
        margin-left: 12.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .professor_evaluation_no_students_text {
        position: relative;
        height: 45%;
        width: 80%;
        display: grid;
        grid-template-rows: 30% 35% 35%;

    }

    .professor_evaluation_list_top_subject_name {
        position: relative;
        height: 100%;
        width: 18vw;
        display: flex;
        align-items: center;

    }

    .professor_evaluation_student_data {
        position: relative;
        height: 80%;
        width: 100%;
        display: flex;
        direction: row;
    }

    .professor_evaluation_student_icon {
        position: relative;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #E6C9FF;
        margin-left: 5%;
        margin-top: 2%;
        margin-bottom: 2%;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_evaluation_student_list {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .professor_evaluation_student_list {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .professor_evaluation_student_list_card_container {
        position: relative;
        height: 80%;
        width: 100%;
        top: 10%
    }

    .professor_evaluation_student_list_card {
        position: relative;
        height: 80%;
        width: 90%;
        left: 5%;
        top: 10%;
        box-shadow: 0px 0px 10px 1px rgb(80, 144, 240, 0.5);
        display: grid;
        grid-template-columns: 10% 90%;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}