/* Ordenador */

@media only screen and (min-width: 992px) {
    .professor_menus {
        position: relative;
        height: 100vh;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .professor_menus_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 80% 20%;
    }

    .professor_menus_titles {
        position: relative;
        height: 100%;
        width: 94%;
        left: 3%;
        display: grid;
        grid-template-columns: 20% 20% 55% 5%;
    }

    .professor_menus_theme {
        position: relative;
        height: 60%;
        width: 100%;
        top: 10%;
        display: grid;
        grid-template-columns: 25% 40% 35%;
    }

    .professor_menus_theme_goals {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .professor_menus_nav_menu {
        position: relative;
        height: 100%;
        width: 94%;
        left: 3%;
        border-bottom: 1px solid #B8B8B8;
        display: flex;
        justify-content: space-evenly;

    }

    .professor_menus_nav_menu_underline {
        position: absolute;
        bottom: -1px;
        left: 20%;
        right: 20%;
        height: 3px;
        background: #5090F0;
    }

    .professor_menus_nav_menu_button {
        position: relative;
        height: 100%;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_menus_nav_menu_separator {
        color: #B8B8B8;
    }

    .professor_menus_freemium_button {
        position: relative;
        width: 50%;
        /* display: flex;
        direction: column; */
        box-sizing: border-box;
        padding: 1.5%;
        border-radius: 5px;
        /* bottom: 90%; */
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {
    .professor_menus {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
    }

    .professor_menus_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 80% 20%;
    }

    .professor_menus_titles {
        position: relative;
        height: 100%;
        width: 94%;
        left: 3%;
        display: grid;
        grid-template-columns: 20% 20% 55% 5%;
    }

    .professor_menus_theme {
        position: relative;
        height: 70%;
        width: 100%;
        top: 15%;
        background-color: #FFF9E7;
        display: grid;
        grid-template-columns: 25% 40% 35%;
    }

    .professor_menus_theme_goals {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
    }

    .professor_menus_nav_menu {
        position: relative;
        height: 100%;
        width: 94%;
        left: 3%;
        border-bottom: 1px solid #B8B8B8;
        display: grid;
        grid-template-columns: 19.2% 1% 19.2% 1% 19.2% 1% 19.2% 1% 19.2%;
    }

    .professor_menus_nav_menu_button {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 10% 90%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        text-decoration-color: "#5090F0"
    }

    .professor_menus_nav_menu_separator {
        position: relative;
        height: 50%;
        width: 100%;
        top: 25%;
        border-left: 1px solid #B8B8B8;
    }
}