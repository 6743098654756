/* Ordenador */

@media only screen and (min-width: 992px) {
    .energy_lab {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        z-index: 5;
    }

    .energy_lab_banner_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .energy_lab_banner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .energy_lab_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
    }

    .energy_lab_close_button {
        position: absolute;
        width: 3%;
        height: 6%;
        right: 4%;
        top: 12.5%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .energy_lab_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .energy_lab_buildings {
        position: absolute;
        width: 94%;
        height: 73%;
        left: 3%;
        top: 22%;
        box-sizing: border-box;
        padding: 1% 2%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        overflow-y: scroll;
    }

    .energy_lab_building {
        position: relative;
        height: 94%;
        width: 90%;
        top: 3%;
        left: 5%;
        display: grid;
        grid-template-rows: 15% 40% 15% 15% 15%;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
        background-color: #ffffff;
        border-radius: 5px;
    }

    .energy_lab_building_bar_background {
        position: relative;
        height: 60%;
        width: 90%;
        top: 20%;
        left: 5%;
        background-color: #D9D9D9;
        border-radius: 25px;
    }

    .energy_lab_building_bar {
        position: absolute;
        height: 100%;
        background-color: #292F4C;
        border-radius: 25px;
    }

    .energy_lab_building_bar_background_text {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
    }

    .energy_lab_building_bonuses {
        position: relative;
        height: 65%;
        width: 90%;
        top: 22.5%;
        left: 5%;
        display: grid;
        grid-template-columns: 45% 55%;
    }

    .energy_lab_building_bonus_kilowatt {
        position: relative;
        height: 100%;
        width: 96%;
        display: grid;
        grid-template-columns: 20% 52% 26%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 5px;
    }

    .energy_lab_building_bonus_co2 {
        position: relative;
        height: 100%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-columns: 20% 40% 40%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 5px;
    }

    .energy_lab_building_research_points {
        position: relative;
        height: 60%;
        width: 90%;
        top: 20%;
        left: 5%;
        display: grid;
        grid-template-columns: 10% 20% 70%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 5px;
    }

    .energy_lab_building_bottom_points {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 48% 4% 48%;
    }

    .energy_lab_building_bottom_points_circle {
        position: relative;
        height: 30px;
        width: 30px;
        border-radius: 50%;
    }

    .energy_lab_confirm {
        position: fixed;
        height: 100%;
        width: 100%;
    }

    .energy_lab_confirm_line_through {
        position: absolute;
        height: 2px;
        width: 80%;
        top: 50%;
        left: 10%;
        z-index: 3;
        background-color: white;
    }

    .energy_lab_confirm_exterior {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgb(0, 0, 0, 25%);
    }

    .energy_lab_confirm_menu {
        position: absolute;
        height: 70%;
        width: 30%;
        top: 15%;
        left: 35%;
        background-color: #ffffff;
        border-radius: 10px;
        display: grid;
        grid-template-rows: 15% 40% 20% 15% 10%;
    }

    .energy_lab_confirm_menu_title {
        position: relative;
        height: 100%;
        width: 90%;
        left: 10%;
        display: grid;
        grid-template-columns: 90% 10%;
    }

    .energy_lab_confirm_menu_bonuses {
        position: relative;
        height: 90%;
        width: 90%;
        top: 5%;
        left: 5%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .energy_lab_confirm_menu_bonus {
        position: relative;
        height: 90%;
        width: 94%;
        left: 3%;
        display: grid;
        grid-template-columns: 23% 77%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 5px;
    }

    .energy_lab_confirm_menu_bonus_data {
        position: relative;
        height: 90%;
        display: grid;
        grid-template-rows: 50% 50%;
    }

    .energy_lab_confirm_menu_bonus_data_row {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .energy_lab_confirm_menu_purchase {
        position: relative;
        display: grid;
        height: 70%;
        width: 85%;
        top: 15%;
        left: 7.5%;
        grid-template-columns: 40% 60%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .energy_lab_confirm_menu_purchase_points {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 15% 15% 70%;

    }

    .energy_lab_confirm_menu_exit_button {
        position: absolute;
        height: 4vh;
        width: 4vh;
        top: 2vh;
        right: 2vh;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .energy_lab_building_research_points_blue_texts {
        font-size: calc(5px + 0.5vw);
        font-family: 'Causten';
        color: #99C2FF;
        font-weight: bold;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 2%;

        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
    }

    .energy_lab_video {
        position: absolute;
        aspect-ratio: 16 / 9;
        width: 100%;
        background-color: #ffffff;
        z-index: 5;
    }

}

/* Tablet */

@media only screen and (max-width: 991px) {
    .energy_lab {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 20% 80%;
        z-index: 5;
    }

    .energy_lab_banner_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .energy_lab_banner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
    }

    .energy_lab_background {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(315deg, rgba(41, 47, 76, 1) 0%, rgba(41, 47, 76, 0.7) 100%);
    }

    .energy_lab_close_button {
        position: absolute;
        width: 3%;
        height: 6%;
        right: 4%;
        top: 10%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .energy_lab_title {
        position: absolute;
        width: 50%;
        height: 7%;
        left: 5%;
        top: 11.5%;
    }

    .energy_lab_buildings {
        position: absolute;
        width: 94%;
        height: 73%;
        left: 3%;
        top: 19%;
        box-sizing: border-box;
        padding: 1% 2%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow-y: scroll;
        background-color: #ffffff;
        border-radius: 5px;
    }

    .energy_lab_building {
        position: relative;
        height: 94%;
        width: 90%;
        top: 3%;
        left: 5%;
        display: grid;
        grid-template-rows: 15% 40% 15% 15% 15%;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .energy_lab_building_bar_background {
        position: relative;
        height: 80%;
        width: 90%;
        top: 10%;
        left: 5%;
        background-color: #D9D9D9;
        border-radius: 25px;
    }

    .energy_lab_building_bar_background_text {
        position: relative;
        height: 100%;
        width: 90%;
        left: 5%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
    }

    .energy_lab_building_bar {
        position: absolute;
        height: 100%;
        background-color: #292F4C;
        border-radius: 25px;
    }

    .energy_lab_building_bonuses {
        position: relative;
        height: 94%;
        width: 90%;
        top: 3%;
        left: 5%;
        display: grid;
        grid-template-columns: 45% 55%;
    }

    .energy_lab_building_bonus_kilowatt {
        position: relative;
        height: 100%;
        width: 96%;
        display: grid;
        grid-template-columns: 20% 48% 32%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 5px;
    }

    .energy_lab_building_bonus_co2 {
        position: relative;
        height: 100%;
        width: 96%;
        left: 2%;
        display: grid;
        grid-template-columns: 20% 40% 40%;
        background-color: rgb(41, 47, 76, 75%);
        border-radius: 5px;
    }

    .energy_lab_building_bottom {
        position: relative;
        height: 80%;
        width: 100%;
        top: 10%;
        display: grid;
        grid-template-rows: 30% 70%;
    }

    .energy_lab_building_bottom_points {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 48% 4% 48%;
    }

    .energy_lab_building_bottom_points_circle {
        position: relative;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }

    .energy_lab_confirm {
        position: fixed;
        height: 100%;
        width: 100%;
    }

    .energy_lab_confirm_exterior {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgb(0, 0, 0, 25%);
    }

    .energy_lab_confirm_menu {
        position: absolute;
        height: 70%;
        width: 30%;
        top: 15%;
        left: 35%;
        background-color: #ffffff;
        border-radius: 10px;
        display: grid;
        grid-template-rows: 15% 40% 10% 10% 25%;
    }

    .energy_lab_confirm_menu_title {
        position: relative;
        height: 100%;
        width: 90%;
        left: 10%;
        display: grid;
        grid-template-columns: 90% 10%;
    }

    .energy_lab_confirm_menu_buttons {
        position: relative;
        height: 60%;
        width: 60%;
        top: 20%;
        left: 20%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .energy_lab_confirm_menu_bottom_points {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 48% 4% 48%;
    }

    .energy_lab_confirm_menu_bottom_points_circle {
        position: relative;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }

    .energy_lab_confirm_menu_button_green {
        position: relative;
        height: 100%;
        width: 98%;
        display: grid;
        grid-template-rows: 35% 65%;
        background-color: #6EE6A7;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .energy_lab_confirm_menu_button_red {
        position: relative;
        height: 100%;
        width: 98%;
        left: 2%;
        background-color: #F0684F;
        border-radius: 5px;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .energy_lab_confirm_menu_exit_button {
        position: absolute;
        height: 4vh;
        width: 4vh;
        top: 2vh;
        right: 2vh;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }
}