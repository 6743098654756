/* Ordenador */

@media only screen and (min-width: 992px) {

    .professor_edit_scenario_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
        box-sizing: border-box;
        padding: 4% 2% 2% 2%;
    }

    .professor_edit_scenario_menu_top_calendar {
        position: relative;
        height: 70%;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 2%;
        border-radius: 5px;
        background: #E2EEFF;
        text-decoration: underline solid #5090F0 1px;
    }

    .professor_edit_scenario_menu_main {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 1%;
        overflow-y: scroll;
    }

    .professor_edit_scenario_menu_top {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 1%;
        justify-content: start;
        border-bottom: #5090F0 solid 1px;
        align-items: center;
    }

    .professor_edit_scenario_menu_top_left {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_edit_scenario_menu_top_right {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_edit_scenario_menu_top_left_name {
        position: relative;
        display: flex;
        align-items: center;
    }

    .professor_edit_scenario_menu_top_left_activities {
        position: relative;
        margin-left: 2%;
    }

    .professor_edit_scenario_menu_top_left_course {
        position: relative;
        border-radius: 50px;
        padding-left: 1%;
        padding-right: 1%;
        margin-left: 2%;
    }

    .professor_edit_scenario_menu_top_left_age {
        position: relative;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 50px;
        /* background: #5090F0; */
        margin-left: 2%;
    }

    .professor_edit_scenario_menu_top_left_lang {
        position: relative;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 50px;
        /* background: black; */
        margin-left: 2%;
    }

    .professor_edit_scenario_menu_top_delete {
        position: relative;
        max-height: 60%;
        min-height: 25%;
        aspect-ratio: 1/1;
        background-color: #F0684F;
        border-radius: 50%;
        margin-left: 2%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_edit_scenario_menu_top_right_type {
        position: relative;
        margin-right: 2%;
    }

    .professor_edit_scenario_menu_top_right_dates {
        position: relative;
        margin-right: 2%;
    }

    .professor_edit_scenario_menu_top_right_owner {
        position: relative;
        margin-right: 4%;
        display: flex;
    }

    .professor_edit_scenario_menu_main_scenarios {
        position: relative;
        height: 95%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .professor_edit_scenario_menu_main_scenario_add {
        position: relative;
        height: fit-content;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin-top: 2%;
        margin-left: 2.5%;
        padding: 1%;
        border-radius: 50px;
        background: #FAFAFA;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_edit_scenario_menu_main_scenario {
        position: relative;
        margin-top: 2%;
        margin-left: 2.5%;
        height: 95%;
        width: 95%;
        border-radius: 10px;
        background: #FAFAFA;
    }

    .professor_edit_scenario_menu_main_scenario_closed {
        height: auto;
        width: auto;
        display: flex;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_edit_scenario_menu_main_scenario_delete {
        position: absolute;
        right: 1%;
        top: 10%;
        height: 2.5vh;
        width: 2.5vh;
        background-color: #F0684F;
        border-radius: 50%;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_edit_scenario_menu_main_scenario_info {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_edit_scenario_menu_main_scenario_info_top {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .professor_edit_scenario_menu_main_scenario_info_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
    }

    .professor_edit_scenario_menu_bottom {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        padding: 2%;
    }

    .professor_edit_scenario_menu_bottom_right_background {
        position: relative;
        height: 100%;
        width: 50%;
        box-sizing: border-box;
        padding: 1%;
        border-radius: 10px;
        background: #E2EEFF;
        display: grid;
        grid-template-rows: 1fr 4fr;
    }

    .professor_edit_scenario_menu_bottom_right_both_numbers {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .professor_edit_scenario_menu_bottom_right_number {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 3fr 1fr;
    }

    .professor_edit_scenario_menu_main_scenario_open {
        height: 90%;
        width: auto;
        box-sizing: border-box;
        padding: 1%;
        display: grid;
        grid-template-rows: 1fr 2fr;
        grid-template-columns: 50% 50%;
    }

    .professor_edit_scenario_menu_main_scenario_open_close_button {
        position: absolute;
        height: 3vw;
        width: 3vw;
        top: 0%;
        right: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .professor_edit_scenario_menu_main_scenario_open_top_left {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
    }

    .professor_edit_scenario_menu_main_scenario_open_top_left_info {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_edit_scenario_menu_main_scenario_open_top_right {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_edit_scenario_menu_main_scenario_open_top_right_knowhows {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;

        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_edit_scenario_menu_main_scenario_open_bottom_left {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-right: 2%;
        box-sizing: border-box;
    }

    .professor_edit_scenario_menu_main_scenario_open_bottom_left_image_container {
        position: relative;

        box-sizing: border-box;
        border-radius: 5px;
    }

    .professor_edit_scenario_menu_main_scenario_open_bottom_left_image_container_hover {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        border-radius: 5px;
    }

    .professor_edit_scenario_menu_main_scenario_open_bottom_right {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    .professor_edit_scenario_menu_main_scenario_open_bottom_right_top {
        position: relative;
        height: 20vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        padding-bottom: 1%;
    }

    .professor_edit_scenario_menu_main_scenario_open_bottom_right_bottom {
        position: relative;
        height: inherit;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        box-sizing: border-box;
    }

    .professor_edit_scenario_menu_main_scenario_open_bottom_right_bottom_spec {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        margin-bottom: 0.8vh;
        align-items: center;
        justify-content: space-around;
    }

    .professor_edit_scenario_menu_main_scenario_open_bottom_right_bottom_key {
        position: relative;
        height: inherit;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-around;
    }

    .image-container {
        position: relative;
        display: inline-block;
    }

    .professor_edit_scenario_menu_button {
        position: absolute;
        height: fit-content;
        width: fit-content;
        bottom: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1% 2%;
        z-index: 12;
        border-radius: 50px;
        background: #6EE6A7;
        cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    }

    .professor_edit_scenario_back_menu_container {
        position: relative;
        height: fit-content;
        width: fit-content;
        min-height: 30%;
        min-width: 30%;
        max-width: 50%;
        max-height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 1%;
        background: #E2EEFF;
        border-radius: 10px;
    }

    .professor_edit_scenario_back_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
    }
}

/* Tablet */

@media only screen and (max-width: 991px) {

    .professor_edit_scenario_menu {
        position: relative;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 15% 85%;
        box-sizing: border-box;
        padding: 2%;
    }

}