.leaderboard_button {
    padding: 0.8vh;
    box-sizing: border-box;
    position: absolute;
    bottom: 13vh;
    right: 5.4vw;
    background-color: rgb(41, 47, 76, 75%);
    cursor: url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto !important;
    border-radius: 5px;
    z-index: 99;
}

.leaderboard_button[data-isOpen="true"] {
    width: 35vw;
    height: 40vh;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 5px;
}

.leaderboard_students_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    overflow-y: scroll;
}

.student_leaderboard {
    position: relative;
    width: 100%;
    height: 6vh;
    display: grid;
    grid-template-columns: 1.75fr 1.5fr 1fr 1.5fr;
}